/** @format */
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Optional,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { CreditRange, CustomStudyDraft, LocalizedString, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../../plan/plan-actions-service/plan-actions.service';
import { CustomStudyDraftStateObject, PlanStateObject } from '../../service/plan-state.service';
import { Action } from '../../study-boxes/study-action-box/study-action-box.component';
import { StudyBoxType } from '../../study-boxes/study-box/study-box.component';

@Component({
    selector: 'sis-plan-structure-custom-study-draft',
    templateUrl: './plan-structure-custom-study-draft.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureCustomStudyDraftComponent implements OnChanges {
    @Input({ required: true }) customStudyDraft: CustomStudyDraft;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) headingLevel: number;
    @Input() selected?: boolean;
    @Input() action?: Action;
    @Output() actionChange? = new EventEmitter<OtmId>();

    customStudyDraftStateObject: CustomStudyDraftStateObject;
    customStudyDraftName: LocalizedString;
    customStudyDraftCredits: CreditRange;
    readonly studyBoxType = StudyBoxType.studyDraft;

    constructor(
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
        private localeService: LocaleService,
    ) {}

    ngOnChanges(): void {
        this.customStudyDraftStateObject = _.get(this.planStateObject?.customStudyDrafts, this.customStudyDraft.id);
        this.customStudyDraftName = this.studyDraftNameToLocalizedString();
        this.customStudyDraftCredits = this.studyDraftCreditsToCreditRange();
    }

    studyDraftNameToLocalizedString(): LocalizedString {
        return this.localeService.getOfficialLanguages().reduce((acc, lang) => ({ ...acc, [lang]: this.customStudyDraft.name }), {});
    }

    studyDraftCreditsToCreditRange(): CreditRange {
        return { min: this.customStudyDraft.credits, max: this.customStudyDraft.credits };
    }

    openCustomStudyDraftInfoModal() {
        this.planActionsService?.openCustomStudyDraftInfoModal?.(this.customStudyDraft);
    }
}
