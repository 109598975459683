<!-- @format -->

<form class="reactive" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row" *ngIf="priorLearnings.length === 0">
    <div class="col-12">
      <div class="form-group">
        <span class="guidance phase-2-guidance">{{ "PROFILE.APPLICATIONS.PRIOR_LEARNING.PRIOR_LEARNING_INFO" | translate }}</span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="externalAttainedStudies$ | async as data">
    <div *ngFor="let formGroup of priorLearnings.controls; let index = index" class="row">
      <div class="col-12">
        <div class="form-group">
          <app-prior-studies-edit
            *ngIf="isPriorStudiesFormGroup(formGroup)"
            [formGroup]="formGroup"
            [title]="getPriorLearningEditorTitle(formGroup, index)"
            [externalAttainedStudies]="data"
            (selectExternalAttainedStudy)="onSelectExternalAttainedStudy($event)"
            [emrexIntegrationEnabled]="emrexIntegrationEnabled"
            (delete)="onDeletePriorLearning(index)"
          >
          </app-prior-studies-edit>
          <app-prior-competence-edit
            *ngIf="isPriorCompetenceFormGroup(formGroup)"
            [formGroup]="formGroup"
            [title]="getPriorLearningEditorTitle(formGroup, index)"
            (delete)="onDeletePriorLearning(index)"
          >
          </app-prior-competence-edit>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <sis-add-button
          [label]="'PROFILE.APPLICATIONS.PRIOR_LEARNING.ADD' | translate"
          (clicked)="addPriorLearning()"
          dataCy="add-prior-learning"
        ></sis-add-button>
      </div>
    </div>
  </div>
  <div class="application-attachments" *ngIf="this.stateService.query.select() | async as wizardData">
    <app-application-attachments
      [initialAttachments]="wizardData.fileItems"
      [externalAttainedStudyAttachments]="getAttachedExternalAttainedStudyAttachments(wizardData)"
      (updateAttachments)="updateAttachments($event)"
    >
    </app-application-attachments>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="float-end">
        <sis-button (clicked)="exit.emit()" naked="true" data-cy="exit-button">
          <sis-icon icon="back"></sis-icon>
          {{ "SIS_COMPONENTS.BUTTON.EXIT" | translate }}
        </sis-button>
        <sis-button *ngIf="!hidePreviousButton" (clicked)="previousClicked()" hollow="true" data-cy="back-button">
          {{ "SIS_COMPONENTS.BUTTON.PREVIOUS" | translate }}
        </sis-button>
        <sis-button type="submit" data-cy="continue-button">
          {{ "SIS_COMPONENTS.BUTTON.CONTINUE" | translate }}
        </sis-button>
      </div>
    </div>
  </div>
</form>
