<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-heading [level]="2" fudisDialogTitle>{{ values.title | transloco}}</fudis-heading>
    <fudis-dialog-content [contentFocus]="true">
      <fudis-grid [classes]="'fudis-my-md'">
        <fudis-notification *ngFor="let notification of notifications" [variant]="'warning'">
          <fudis-body-text>
            {{ notification }}
          </fudis-body-text>
        </fudis-notification>
        <fudis-body-text *ngFor="let description of descriptions">{{ description | transloco }}</fudis-body-text>
      </fudis-grid>
    </fudis-dialog-content>
    <fudis-dialog-actions>
      <fudis-button *ngIf="!values.hideCancel" variant="secondary" fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')" />
      <fudis-button fudisDialogSubmit [label]="t('SIS_COMPONENTS.BUTTON.SAVE')" (handleClick)="close()" />
    </fudis-dialog-actions>
  </fudis-dialog>
</ng-container>
