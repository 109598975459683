/** @format */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { ExternalAttainedStudyAttachments, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';

const CONFIG = {
    ENDPOINTS: {
        backend: '/tasku/api/external-study-attachments',
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
})
export class ExternalAttainedStudyAttachmentService {
    constructor(private http: HttpClient) {}

    getExternalAttainedStudyAttachments(externalAttainedStudyId: string): Observable<ExternalAttainedStudyAttachments> {
        return this.http.get<ExternalAttainedStudyAttachments>(`${CONFIG.ENDPOINTS.backend}/${externalAttainedStudyId}`);
    }

    getExternalAttainedStudyAttachmentsByWorkflowId(workflowId: OtmId): Observable<ExternalAttainedStudyAttachments[]> {
        return this.http.get<ExternalAttainedStudyAttachments[]>(`${CONFIG.ENDPOINTS.backend}/for-workflow/${workflowId}`);
    }
}
