<!-- @format -->

<ng-container *ngIf="hasInvalidSelections">
  <ng-container *transloco="let t">
    <fudis-heading [level]="3">{{ t("PLAN_STRUCTURE_EDIT.INVALID_SELECTIONS_TITLE") }}</fudis-heading>
    <div *ngFor="let courseUnit of allCourseUnits">
      <sis-plan-structure-course-unit
        [courseUnit]="courseUnit"
        [planStateObject]="planStateObject"
        [planData]="planData"
        [headingLevel]="headingLevel"
        [action]="'select-to-plan'"
        [selected]="validatablePlan.isCourseUnitInPlan(courseUnit)"
        (actionChange)="handleCourseUnitToggle(courseUnit)"
      >
      </sis-plan-structure-course-unit>
    </div>
  </ng-container>
</ng-container>
