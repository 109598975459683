import { Injectable } from '@angular/core';

import { LocalStorageService } from '../storage/local-storage.service';
import { ConfigService } from '../config/config.service';


@Injectable({
    providedIn: 'root',
})
export class LocalStorageSelectedLanguageService {

    constructor(private localStorageService: LocalStorageService, private configService: ConfigService) { }
    selectedLanguageLocalStorageKey = 'selected_language';

    get() { 
        const officialLanguages = this.configService.get().officialLanguages;
        const storedLanguage = this.localStorageService.getItem(this.selectedLanguageLocalStorageKey);
        if (storedLanguage && officialLanguages.includes(storedLanguage)) { 
            return storedLanguage; 
        }
        return officialLanguages[0]; 
    }
    set(language: string) { this.localStorageService.setItem(this.selectedLanguageLocalStorageKey, language); }
}
