<!-- @format -->

<ng-container *ngIf="this.service.query.select() | async as wizardData">
  <div id="student-application" class="container student-application prior-learning-inclusion-application">
    <div class="row">
      <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
        <div class="form-group-mimic">
          <sis-wizard-progress [stepKeys]="wizardStepKeys" [currentStep]="currentStep()"></sis-wizard-progress>
        </div>

        <app-custom-attainment-basic-info-edit
          *ngIf="isFirstStep()"
          [planId]="plan?.id"
          [customStudyDraft]="getCustomStudyDraft(wizardData)"
          [workflowApplication]="getInclusionWorkflowApplication(wizardData)"
          (exit)="exit()"
          (continue)="continueAfterCustomAttainmentBasicInfoEdit($event)"
        >
        </app-custom-attainment-basic-info-edit>
        <app-prior-learning-and-attachments-edit
          *ngIf="currentStep() === 1"
          type="INCLUDE"
          (exit)="exit()"
          (previous)="previous()"
          (continue)="continue()"
        >
        </app-prior-learning-and-attachments-edit>
        <app-prior-learning-application-confirmation
          *ngIf="isLastStep()"
          [disableButtons]="uploading"
          (exit)="exit()"
          (previous)="previous()"
          (submit)="submit()"
        >
        </app-prior-learning-application-confirmation>
      </div>
    </div>
  </div>
</ng-container>
