<!-- @format -->

<sis-study-box
  [id]="customStudyDraft.id"
  [name]="customStudyDraftName"
  [credits]="customStudyDraftCredits"
  [type]="studyBoxType"
  [level]="headingLevel"
  [action]="action"
  [selected]="selected"
  (actionChange)="actionChange.emit($event)"
  (nameClickAction)="openCustomStudyDraftInfoModal()"
/>
