/** @format */
import angular from 'angular';
import * as _ from 'lodash-es';
import moment from 'moment';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { commonUniversityServiceModule } from 'sis-common/university/university.service.ts';
import { UuidService } from 'sis-common/uuid/uuid.service.ts';
import { commonDateConstantsModule } from '../date/constants';
import { publicPersonModuleModel } from '../model/publicPerson.model';
import { courseUnitRealisationServiceModule } from './courseUnitRealisation.service';
import { jsDataCacheHelperModule } from './jsDataCacheHelper.service';
import { jsDataRelationHelperModule } from './jsDataRelationHelper.service';
import { locationServiceModule } from './location.service';
export const commonStudyEventServiceModule = 'sis-components.service.studyEventService';
(function () {
  studyEventService.$inject = ["$q", "$log", "$translate", "$http", "dateFormat", "MAX_EVENTS", "universityService", "studySubGroupTitleFormat", "jsDataCacheHelper", "STUDY_EVENT_URL", "studyEventJSDataModel", "publicPersonModel", "localeService", "locationJSDataModel", "uuidService", "commonCourseUnitRealisationService", "jsDataRelationHelperService", "locationService"];
  angular.module(commonStudyEventServiceModule, [localeServiceModule, commonUniversityServiceModule, commonDateConstantsModule, 'sis-components.model.studyEvent', publicPersonModuleModel, 'sis-components.model.location', UuidService.downgrade.moduleName, jsDataCacheHelperModule, courseUnitRealisationServiceModule, jsDataRelationHelperModule, locationServiceModule]).factory('commonStudyEventService', studyEventService).constant('MAX_EVENTS', 365).constant('studySubGroupTitleFormat', {
    NO_EVENTS: 'NO_EVENTS',
    ONE_EVENT_ONE_TIME: 'ONE_EVENT_ONE_TIME',
    ONE_EVENT_ONE_DAY: 'ONE_EVENT_ONE_DAY',
    ONE_EVENT_MANY_DAYS: 'ONE_EVENT_MANY_DAYS',
    MANY_DATES_SAME_TIME: 'MANY_DATES_SAME_TIME',
    MANY_DATES_DIFFERENT_TIMES: 'MANY_DATES_DIFFERENT_TIMES'
  }).constant('studyGroupSetTitleFormat', {
    NO_EVENTS: 'NO_EVENTS',
    ONE_EVENT_ONE_TIME: 'ONE_EVENT_ONE_TIME',
    ONE_EVENT_ONE_DAY: 'ONE_EVENT_ONE_DAY',
    ONE_EVENT_MANY_DAYS: 'ONE_EVENT_MANY_DAYS',
    MANY_DATES_SAME_TIME: 'MANY_DATES_SAME_TIME',
    MANY_DATES_DIFFERENT_TIMES: 'MANY_DATES_DIFFERENT_TIMES'
  });

  /**
   * @ngInject
   */
  function studyEventService(
  // NOSONAR
  $q, $log, $translate, $http, dateFormat, MAX_EVENTS, universityService, studySubGroupTitleFormat, jsDataCacheHelper, STUDY_EVENT_URL, studyEventJSDataModel, publicPersonModel, localeService, locationJSDataModel, uuidService, commonCourseUnitRealisationService, jsDataRelationHelperService, locationService) {
    function datesEqual(date1, date2) {
      const event1Date = moment(date1);
      const event2Date = moment(date2);
      return event1Date.isSame(event2Date, 'day');
    }
    function dateTimeEqual(date1, date2) {
      const event1Date = moment(date1);
      const event2Date = moment(date2);
      return event1Date.isSame(event2Date, 'minute');
    }
    function studyEventIdsWithOutNew(studySubGroup) {
      if (!studySubGroup) {
        return [];
      }
      return _.compact(_.xor(studySubGroup.studyEventIds, _.map(studySubGroup.createdStudyEvents, 'id')));
    }
    function getStudySubGroupsOfCourseUnitRealisation(courseUnitRealisation) {
      return _.flatMap(_.get(courseUnitRealisation, 'studyGroupSets'), 'studySubGroups');
    }
    function findTeachers(courseUnitRealisation) {
      const teacherIds = _.flatMap(getStudySubGroupsOfCourseUnitRealisation(courseUnitRealisation), 'teacherIds');
      return jsDataCacheHelper.findByIds(publicPersonModel, _.uniq(_.compact(teacherIds)));
    }
    function createName(key) {
      const lang = localeService.getCurrentLocale() || 'fi';
      const name = {};
      name[lang] = $translate.instant(key);
      return name;
    }
    function containsDate(dateArray, date) {
      return !!_.find(dateArray, arrayDate => datesEqual(arrayDate, date));
    }
    function isExcluded(studyEvent, date) {
      return containsDate(studyEvent.exceptions, date);
    }
    function isCancelled(studyEvent, date) {
      return containsDate(studyEvent.cancellations, date);
    }
    function updateFirstAndLastEvent(firstAndLast, event) {
      if (event.start && event.excluded === false && event.cancelled === false) {
        if (!firstAndLast.firstStudyEvent || moment(event.start).isBefore(firstAndLast.firstStudyEvent.start)) {
          firstAndLast.firstStudyEvent = _.clone(event);
        }
        if (!firstAndLast.lastStudyEvent || moment(event.start).isAfter(firstAndLast.lastStudyEvent.start)) {
          firstAndLast.lastStudyEvent = _.clone(event);
        }
      }
      return firstAndLast;
    }
    function getAllLocationIdsFromStudyEvents(studyEvents) {
      const studyEventLocationIds = studyEvents.flatMap(studyEvent => studyEvent.locationIds);
      const overrideLocationIds = studyEvents.flatMap(studyEvent => _.uniq(_.compact(studyEvent.overrides))).flatMap(studyEvent => studyEvent.irregularLocationIds);
      return _.uniq(_.compact(_.concat(studyEventLocationIds, overrideLocationIds)));
    }
    function getAllTeacherIdsFromStudySubGroupsAndStudyEvents(studySubGroups, studyEvents) {
      const studySubGroupTeacherIds = _.compact(_.uniq(studySubGroups.flatMap(studySubGroup => studySubGroup.teacherIds)));
      const overrideTeacherIds = _.compact(_.uniq(studyEvents.flatMap(studyEvent => _.compact(_.uniq(studyEvent.overrides))).flatMap(override => override.irregularTeacherIds)));
      return _.uniq(_.concat(studySubGroupTeacherIds, overrideTeacherIds));
    }
    const api = {
      loadStudyEventsWithRelationsForCourseUnitRealisations(courseUnitRealisations, bypassCache = false) {
        const studyEventIds = _.flatMap(courseUnitRealisations, api.getAllStudyEventIdsForCourseUnitRealisation);
        return api.findByIds(studyEventIds, bypassCache).then(studyEvents => {
          const studySubGroups = _.flatMap(courseUnitRealisations, getStudySubGroupsOfCourseUnitRealisation);
          const teacherIds = getAllTeacherIdsFromStudySubGroupsAndStudyEvents(studySubGroups, studyEvents);
          const teacherFind = jsDataCacheHelper.findByIds(publicPersonModel, teacherIds);
          const locationFind = locationService.findByIds(getAllLocationIdsFromStudyEvents(studyEvents));
          return $q.all([teacherFind, locationFind]).then(() => {
            const loadStudyEventLoads = _.map(courseUnitRealisations, api.loadStudyEvents);
            return $q.all(loadStudyEventLoads);
          });
        });
      },
      attachTeacher(studySubGroup, personObj) {
        if (studySubGroup && personObj) {
          return publicPersonModel.find(personObj.id).then(publicPerson => {
            if (!_.isArray(studySubGroup.teacherIds)) {
              studySubGroup.teacherIds = [];
            }
            studySubGroup.teacherIds.push(publicPerson.id);
            studySubGroup.teacherIds = _.uniq(studySubGroup.teacherIds);
            if (!_.isArray(studySubGroup.teachers)) {
              studySubGroup.teachers = [];
            }
            studySubGroup.teachers.push(publicPerson);
            studySubGroup.teachers = _.uniqBy(studySubGroup.teachers, 'id');
            return publicPerson;
          }, error => {
            $log.warn('Failed to find publicPerson for personObj', personObj, error);
            return $q.reject(false);
          });
        }
        return $q.reject(false);
      },
      attachLocation(studyEvent, locationSearchResult) {
        if (studyEvent && locationSearchResult) {
          if (!_.isArray(studyEvent.locationIds)) {
            studyEvent.locationIds = [];
          }
          studyEvent.locationIds.push(locationSearchResult.id);
          studyEvent.locationIds = _.uniq(studyEvent.locationIds);
          if (!_.isArray(studyEvent.locations)) {
            studyEvent.locations = [];
          }
          return $q.when(locationJSDataModel.find(locationSearchResult.id).then(location => {
            studyEvent.locations.push(location);
            studyEvent.locations = _.uniqBy(studyEvent.locations, 'id');
            return location;
          }));
        }
        return $q.reject(false);
      },
      createStudyEvent(courseUnitRealisation, studySubGroup, startTime) {
        const newStudyEvent = {
          id: uuidService.randomOtmId(),
          primaryCourseUnitRealisationId: courseUnitRealisation.id,
          name: createName('TEACHING.CUR_STRUCTURE.STUDYEVENT_NAME_FOR_NEW'),
          events: [],
          locations: [],
          locationIds: [],
          overrides: [],
          startTime: moment(startTime).format(dateFormat.LOCAL_DATETIME),
          startDateTime: moment(startTime).toDate(),
          recursEvery: 'NEVER',
          universityOrgIds: [universityService.getCurrentUniversityOrgId()],
          duration: moment.duration(1, 'hours').toJSON()
        };
        if (!_.isArray(studySubGroup.studyEventIds)) {
          studySubGroup.studyEventIds = [];
        }
        studySubGroup.studyEventIds.push(newStudyEvent.id);
        if (!_.isArray(studySubGroup.studyEvents)) {
          studySubGroup.studyEvents = [];
        }
        if (!_.isArray(studySubGroup.createdStudyEvents)) {
          studySubGroup.createdStudyEvents = [];
        }
        studySubGroup.createdStudyEvents.push(newStudyEvent);
        studySubGroup.studyEvents.push(newStudyEvent);
        return newStudyEvent;
      },
      createStudySubGroup(studyGroupSet) {
        const newStudySubGroup = {
          id: uuidService.randomOtmId(),
          name: createName('TEACHING.CUR_STRUCTURE.STUDYSUBGROUP_NAME_FOR_NEW'),
          studyEventIds: [],
          teacherIds: [],
          cancelled: false
        };
        if (!_.isArray(studyGroupSet.studySubGroups)) {
          studyGroupSet.studySubGroups = [];
        }
        studyGroupSet.studySubGroups.push(newStudySubGroup);
        return newStudySubGroup;
      },
      createStudyGroupSet(courseUnitRealisation) {
        const newStudyGroupSet = {
          localId: uuidService.randomOtmId(),
          name: createName('TEACHING.CUR_STRUCTURE.STUDYGROUPSET_NAME_FOR_NEW'),
          studySubGroups: [],
          subGroupRange: {
            min: 0
          }
        };
        if (!_.isArray(courseUnitRealisation.studyGroupSets)) {
          courseUnitRealisation.studyGroupSets = [];
        }
        courseUnitRealisation.studyGroupSets.push(newStudyGroupSet);
        return newStudyGroupSet;
      },
      removeStudyGroupSet(studyGroupSet, courseUnitRealisation) {
        _.remove(courseUnitRealisation.studyGroupSets, {
          localId: studyGroupSet.localId
        });
        const studyEventDeletes = _.flatMap(studyGroupSet.studySubGroups, studySubGroup => _.map(studyEventIdsWithOutNew(studySubGroup), studyEventId => api.delete(studyEventId).catch(error => {
          $log.error('Failed to delete studyEventId for StudyGroupSet', studyEventId, studyGroupSet, error);
          return $q.reject(error);
        })));
        return $q.all(studyEventDeletes);
      },
      removeStudySubGroup(studyGroupSet, studySubGroup) {
        _.remove(studyGroupSet.studySubGroups, {
          id: studySubGroup.id
        });
        if (!studyGroupSet.removedStudySubGroups) {
          studyGroupSet.removedStudySubGroups = [];
        }
        studyGroupSet.removedStudySubGroups.push(studySubGroup);
      },
      removeStudyEvent(studySubGroup, studyEvent) {
        _.remove(studySubGroup.studyEventIds, studyEventId => studyEventId === studyEvent.id);
        _.remove(studySubGroup.studyEvents, studyEvent1 => studyEvent1.id === studyEvent.id);
        if (!_.isArray(studySubGroup.removedStudyEvents)) {
          studySubGroup.removedStudyEvents = [];
        }
        const createdStudyEventsIds = _.map(studySubGroup.createdStudyEvents, 'id');
        if (!_.includes(createdStudyEventsIds, studyEvent.id)) {
          studySubGroup.removedStudyEvents.push(studyEvent.id);
        }
        _.remove(studySubGroup.createdStudyEvents, createdStudyEvent => createdStudyEvent.id === studyEvent.id);
      },
      revertStudyEventsForStudyGroupSet(studyGroupSet, courseUnitRealisation) {
        _.forEach(studyGroupSet.studySubGroups, studySubGroup => {
          _.forEach(studyEventIdsWithOutNew(studySubGroup), studyEventId => {
            studyEventJSDataModel.revert(studyEventId);
          });
        });
        return api.loadStudyEvents(courseUnitRealisation);
      },
      toggleExceptionDate(studyEvent, event) {
        const toggledDate = moment(event.start).format(dateFormat.LOCAL_DATE);
        if (!_.isArray(studyEvent.exceptions)) {
          studyEvent.exceptions = [toggledDate];
          event.excluded = true;
        } else {
          const exception = _.find(studyEvent.exceptions, exceptionDate => datesEqual(exceptionDate, toggledDate));
          if (exception) {
            _.remove(studyEvent.exceptions, exceptionDate => datesEqual(exceptionDate, toggledDate));
            event.excluded = false;
          } else {
            event.excluded = true;
            studyEvent.exceptions.push(toggledDate);
            studyEvent.exceptions.sort();
          }
        }
      },
      toggleCancelledDate(studyEvent, event) {
        const toggledDate = moment(event.start).format(dateFormat.LOCAL_DATE);
        if (!_.isArray(studyEvent.cancellations)) {
          studyEvent.cancellations = [toggledDate];
          event.cancelled = true;
        } else {
          const exception = _.find(studyEvent.cancellations, exceptionDate => datesEqual(exceptionDate, toggledDate));
          if (exception) {
            _.remove(studyEvent.cancellations, exceptionDate => datesEqual(exceptionDate, toggledDate));
            event.cancelled = false;
          } else {
            event.cancelled = true;
            studyEvent.cancellations.push(toggledDate);
            studyEvent.cancellations.sort();
          }
        }
      },
      loadStudyEvents(courseUnitRealisation, bypassCache = false) {
        if (!courseUnitRealisation) {
          return $q.when(courseUnitRealisation);
        }
        let teacherIndex;
        let studyEventIndex;
        let locationIndex;
        const backEndLoads = [findTeachers(courseUnitRealisation).then(teachers => {
          teacherIndex = _.keyBy(teachers, 'id');
        }), api.findStudyEventsForCourseUnitRealisation(courseUnitRealisation, bypassCache).then(studyEvents => {
          studyEventIndex = _.keyBy(studyEvents, 'id');
        }), findLocations(courseUnitRealisation).then(locations => {
          locationIndex = _.keyBy(locations, 'id');
        })];
        return $q.all(backEndLoads).then(() => {
          _.forEach(_.get(courseUnitRealisation, 'studyGroupSets'), studyGroupSet => {
            studyGroupSet.eventCount = 0;
            _.forEach(studyGroupSet.studySubGroups, studySubGroup => {
              let eventDates = [];
              if (studySubGroup.studyEventIds) {
                studyGroupSet.eventCount += studySubGroup.studyEventIds.length;
              }
              studySubGroup.teachers = [];
              _.forEach(studySubGroup.teacherIds, teacherId => {
                studySubGroup.teachers.push(teacherIndex[teacherId]);
              });
              studySubGroup.studyEvents = [];
              _.forEach(studySubGroup.studyEventIds, studyEventId => {
                let studyEvent = studyEventIndex[studyEventId];
                studyEvent = studyEvent || {};
                studySubGroup.studyEvents.push(studyEvent);
                studyEvent.locations = [];
                _.forEach(studyEvent.locationIds, locationId => {
                  studyEvent.locations.push(locationIndex[locationId]);
                });
                _.forEach(studyEvent.events, event => {
                  event.datesEqual = datesEqual(event.start, event.end);
                });
                if (studyEvent.recursEvery === 'NEVER') {
                  studyEvent.recursUntil = undefined;
                }
                let recurringEndDateTime = studyEvent.endDateTime;
                if (studyEvent.recursUntil && studyEvent.endDateTime) {
                  const studyEventEndDateTime = moment(studyEvent.endDateTime);
                  recurringEndDateTime = moment(studyEvent.recursUntil).hours(studyEventEndDateTime.hours()).minutes(studyEventEndDateTime.minutes()).toDate();
                }
                eventDates.push({
                  cancelled: studySubGroup.cancelled,
                  start: studyEvent.startDateTime,
                  end: recurringEndDateTime,
                  hashKey: `${moment(studyEvent.startDateTime).format('YYYY-MM-DD-HHmm')}-${moment(studyEvent.endDateTime).format('YYYY-MM-DD-HHmm')}-${moment(studyEvent.recursUntil).format('YYYY-MM-DD')}`
                });
              });
              eventDates = _.chain(eventDates).compact('hashKey').orderBy(['start', 'end']).value();
              studySubGroup.eventDates = eventDates;
              if (eventDates.length === 0) {
                studySubGroup.titleFormat = studySubGroupTitleFormat.NO_EVENTS;
              } else if (eventDates.length === 1) {
                if (dateTimeEqual(eventDates[0].start, eventDates[0].end)) {
                  studySubGroup.titleFormat = studySubGroupTitleFormat.ONE_EVENT_ONE_TIME;
                } else if (datesEqual(eventDates[0].start, eventDates[0].end)) {
                  studySubGroup.titleFormat = studySubGroupTitleFormat.ONE_EVENT_ONE_DAY;
                } else {
                  studySubGroup.titleFormat = studySubGroupTitleFormat.ONE_EVENT_MANY_DAYS;
                }
              } else {
                const times = _.uniq(_.map(eventDates, eventDate => `${moment(eventDate.start).format('HHmm')}-${moment(eventDate.end).format('HHmm')}`));
                studySubGroup.times = times;
                if (times.length === 1) {
                  studySubGroup.titleFormat = studySubGroupTitleFormat.MANY_DATES_SAME_TIME;
                } else {
                  studySubGroup.titleFormat = studySubGroupTitleFormat.MANY_DATES_DIFFERENT_TIMES;
                }
              }
            });
          });
          return courseUnitRealisation;
        }, error => {
          $log.error('Failed to fetch StudyEvent data for courseUnitRealisation.', courseUnitRealisation, error);
          return $q.reject(error);
        });
      },
      getStudyEventEndTime(studyEvent, startTime) {
        const start = startTime || moment(studyEvent.startTime);
        return start.clone().add(moment.duration(studyEvent.duration).asMinutes(), 'minutes');
      },
      nextEventStartDate(type, date) {
        const nextStartDate = date.clone();
        if (type === 'DAILY') {
          nextStartDate.add(1, 'days');
        } else if (type === 'WEEKLY') {
          nextStartDate.add(7, 'days');
        } else if (type === 'EVERY_SECOND_WEEK') {
          nextStartDate.add(14, 'days');
        } else if (type === 'MONTHLY') {
          nextStartDate.add(1, 'months');
        }
        return nextStartDate;
      },
      createEventInstances(studyEvent) {
        function createEvent(start, studyEvent) {
          const end = api.getStudyEventEndTime(studyEvent, start);
          return {
            start: start.clone().toDate(),
            end: end.toDate(),
            excluded: isExcluded(studyEvent, start),
            cancelled: isCancelled(studyEvent, start),
            datesEqual: datesEqual(start, end)
          };
        }
        if (studyEvent) {
          if (studyEvent.events) {
            studyEvent.events.length = 0;
          } else {
            studyEvent.events = [];
          }
          const startDateTime = moment(studyEvent.startTime);
          const endTime = api.getStudyEventEndTime(studyEvent);
          studyEvent.endTime = endTime.format(dateFormat.LOCAL_DATETIME);
          if (studyEvent.recursEvery === 'NEVER') {
            studyEvent.events.push(createEvent(startDateTime.clone(), studyEvent));
          } else {
            const recursUntil = moment(studyEvent.recursUntil);
            let start = startDateTime.clone();
            for (let index = 0; index < MAX_EVENTS + 1 && start.isSameOrBefore(recursUntil, 'day'); index++) {
              studyEvent.events.push(createEvent(start, studyEvent));
              start = api.nextEventStartDate(studyEvent.recursEvery, start);
            }
          }
          const eventStartDates = _.map(studyEvent.events, 'start');
          studyEvent.exceptions = _.filter(studyEvent.exceptions, exceptionDate => containsDate(eventStartDates, exceptionDate));
          studyEvent.cancellations = _.filter(studyEvent.cancellations, cancelledDate => containsDate(eventStartDates, cancelledDate));
        }
      },
      setEventInstances(studyGroupSet) {
        _.forEach(studyGroupSet.studySubGroups, studySubGroup => {
          _.forEach(studySubGroup.studyEvents, studyEvent => {
            api.createEventInstances(studyEvent);
          });
        });
      },
      saveCourseUnitRealisation(courseUnitRealisation) {
        return commonCourseUnitRealisationService.saveStudyGroupSets(courseUnitRealisation);
      },
      saveStudyGroupSet(studyGroupSet, courseUnitRealisation) {
        let deleteStudyEventIds = [];
        let changedStudyEvents = [];
        let createdStudyEvents = [];
        _.forEach(studyGroupSet.removedStudySubGroups, removedStudySubGroup => {
          _.forEach(removedStudySubGroup.removedStudyEvents, studyEventId => {
            deleteStudyEventIds.push(studyEventId);
          });
          _.forEach(studyEventIdsWithOutNew(removedStudySubGroup), studyEventId => {
            deleteStudyEventIds.push(studyEventId);
          });
        });
        _.forEach(studyGroupSet.studySubGroups, studySubGroup => {
          _.forEach(studySubGroup.createdStudyEvents, studyEvent => {
            createdStudyEvents.push(studyEvent);
          });
          _.forEach(studySubGroup.removedStudyEvents, studyEventId => {
            deleteStudyEventIds.push(studyEventId);
          });
          _.forEach(studySubGroup.studyEvents, studyEvent => {
            if (studyEventJSDataModel.hasChanges(studyEvent)) {
              changedStudyEvents.push(studyEvent);
            }
          });
        });
        deleteStudyEventIds = _.compact(_.uniq(deleteStudyEventIds));
        changedStudyEvents = _.compact(_.uniq(changedStudyEvents));
        createdStudyEvents = _.compact(_.uniq(createdStudyEvents));
        const createAction = () => _.isEmpty(createdStudyEvents) ? $q.when() : $http.post('/kori/api/study-events/mass', removeEvents(createdStudyEvents)).then(response => studyEventJSDataModel.inject(response.data));
        const removeEvents = studyEvents => _.map(studyEvents, studyEvent => {
          const studyEventClone = _.cloneDeep(studyEvent);
          _.unset(studyEventClone, 'events');
          return studyEventClone;
        });
        return createAction().then(() => $http.put('/kori/api/study-events', removeEvents(changedStudyEvents))).then(() => $q.all(_.map(deleteStudyEventIds, deleteStudyEventId => api.delete(deleteStudyEventId)))).then(() => commonCourseUnitRealisationService.saveStudyGroupSets(courseUnitRealisation, true)).then(savedCUR => api.loadStudyEvents(savedCUR));
      },
      delete(studyEventId) {
        return studyEventJSDataModel.destroy(studyEventId).then(studyEvent => studyEvent, error => {
          $log.error('Failed to delete studyEvent ', studyEventId, error);
          return $q.reject(error);
        });
      },
      save(studyEvent) {
        return studyEventJSDataModel.save(studyEvent);
      },
      create(studyEvent) {
        // We need to clone studyEvent object because it has computed values that are removed if saving throws error.
        const studyEventClone = _.cloneDeep(studyEvent);
        return studyEventJSDataModel.create(studyEventClone);
      },
      getFirstAndLastStudyEvent(courseUnitRealisation) {
        let result = {
          firstStudyEvent: null,
          lastStudyEvent: null
        };
        return api.loadStudyEvents(courseUnitRealisation).then(courseUnitRealisation => {
          _.forEach(courseUnitRealisation.studyGroupSets, studyGroupSet => {
            _.forEach(studyGroupSet.studySubGroups, studySubGroup => {
              if (studySubGroup.cancelled === false) {
                _.forEach(studySubGroup.studyEvents, studyEvent => {
                  _.forEach(studyEvent.events, event => {
                    result = updateFirstAndLastEvent(result, event);
                  });
                });
              }
            });
          });
          return result;
        });
      },
      isStudyEventInFuture(event) {
        const currentMoment = moment();
        return event && event.start && moment(event.start).isAfter(currentMoment, 'day');
      },
      findByIds(studyEventIds, bypassCache = false) {
        return jsDataCacheHelper.findByIds(studyEventJSDataModel, _.uniq(studyEventIds), bypassCache);
      },
      searchByCurSelections(curSelections, startDate, endDate, loadRelations) {
        if (!curSelections) {
          return $q.when([]);
        }
        const studySubGroupIds = _.compact(_.uniq(curSelections.flatMap(curSelection => curSelection.selectedStudySubGroups)));
        const options = {
          bypassCache: false,
          endpoint: STUDY_EVENT_URL.SEARCH
        };
        const params = {
          startDate,
          endDate,
          studySubGroupId: studySubGroupIds
        };
        const findAll = studyEventJSDataModel.findAll(params, options);
        if (loadRelations) {
          return findAll.then(studyEvents => jsDataRelationHelperService.loadArrayRelationForArray(locationJSDataModel, studyEvents, false));
        }
        return findAll;
      },
      findLocations(courseUnitRealisation) {
        return api.findStudyEventsForCourseUnitRealisation(courseUnitRealisation).then(studyEvents => locationService.findByIds(getAllLocationIdsFromStudyEvents(studyEvents)));
      },
      findTeachers(courseUnitRealisation) {
        return api.findStudyEventsForCourseUnitRealisation(courseUnitRealisation).then(studyEvents => {
          const studySubGroups = getStudySubGroupsOfCourseUnitRealisation(courseUnitRealisation);
          const teacherIds = getAllTeacherIdsFromStudySubGroupsAndStudyEvents(studySubGroups, studyEvents);
          return jsDataCacheHelper.findByIds(publicPersonModel, teacherIds);
        });
      },
      getAllTeachersByIds(ids) {
        return jsDataCacheHelper.findByIds(publicPersonModel, ids);
      },
      getAllStudyEventIdsForCourseUnitRealisation: courseUnitRealisation => _.compact(_.uniq(getStudySubGroupsOfCourseUnitRealisation(courseUnitRealisation).flatMap(studySubGroup => studySubGroup.studyEventIds))),
      /**
       * Just finds study events, does not do manual enrichment.
       */
      findStudyEventsForCourseUnitRealisation(courseUnitRealisation, bypassCache = false) {
        return api.findStudyEventsForCourseUnitRealisations([courseUnitRealisation], bypassCache);
      },
      /**
       * Just finds study events, does not do manual enrichment.
       */
      findStudyEventsForCourseUnitRealisations(courseUnitRealisations, bypassCache = false) {
        const studyEventIds = _.flatMap(courseUnitRealisations, api.getAllStudyEventIdsForCourseUnitRealisation);
        return api.findByIds(studyEventIds, bypassCache);
      },
      /**
       * Does enrichment of study sub groups 'manually' (relation not defined by js-data model)
       */
      loadStudyEventsForCourseUnitRealisations(courseUnitRealisations) {
        const ssgs = _.flatMap(courseUnitRealisations, getStudySubGroupsOfCourseUnitRealisation);
        return jsDataRelationHelperService.loadArrayRelationForArray(studyEventJSDataModel, ssgs, false).then(() => courseUnitRealisations);
      },
      /**
       * Just finds locations, does not do manual enrichment.
       */
      findAllLocationsForStudyEvents(studyEvents) {
        return locationService.findByIds(getAllLocationIdsFromStudyEvents(studyEvents));
      },
      /**
       * Finds locations and irregular location for study events and enriches study events and any existing event.
       */
      loadLocationsForStudyEventsAndEvents(studyEvents) {
        return api.findAllLocationsForStudyEvents(studyEvents).then(() => {
          const events = _.compact(_.flatMap(studyEvents, 'events'));
          return $q.all([jsDataRelationHelperService.loadArrayRelationForArray(locationJSDataModel, studyEvents, false), jsDataRelationHelperService.loadArrayRelationForArray(locationJSDataModel, events, false, 'irregularLocation')]);
        }).then(() => studyEvents);
      },
      getMatchingOverride: (studyEvent, event) => {
        if (!studyEvent || !event) {
          return undefined;
        }
        const eventDateTime = moment(event.start);
        return _.find(studyEvent.overrides, override => eventDateTime.isSame(override.eventDate, 'day'));
      },
      // getIrregularEventsForStudent returns those events that have other irregularities
      // than irregularTeachers. it returns events in chronological order.
      // the argument is typically a flatlist of studyEvents from one or more
      // studySubGroups

      getIrregularEventsForStudent: studyEvents => {
        const irregularEvents = studyEvents.flatMap(studyEvent => studyEvent.events).filter(event => event.excluded || event.cancelled || event.irregularLocationIds !== null || event.notice !== null);
        return irregularEvents.sort((a, b) => moment(a.start).isBefore(moment(b.start)) ? -1 : 1);
      },
      // getIrregularEventsForStaff returns those events that have irregularities
      // including irregularTeachers. it returns events in chronological order.
      // the argument is typically a flatlist of studyEvents from one or more
      // studySubGroups

      getIrregularEventsForStaff: studyEvents => {
        const irregularEvents = studyEvents.flatMap(studyEvent => studyEvent.events).filter(event => event.excluded || event.cancelled || event.irregularLocationIds !== null || event.irregularTeacherIds !== null || event.notice !== null);
        return irregularEvents.sort((a, b) => moment(a.start).isBefore(moment(b.start)) ? -1 : 1);
      },
      /**
       * Sorts by date part of startTime, recursUntil, recursEvery (enum), time part of startTime
       */
      orderStudyEventsByTime(studyEvents) {
        const sortByStartDate = studyEvent => moment(studyEvent.startTime).format('YYYYMMDD');
        const sortByEndDate = studyEvent => moment(studyEvent.recursUntil).format('YYYYMMDD');
        function sortByRecurs(studyEvent) {
          switch (studyEvent.recursEvery) {
            case 'NEVER':
              return 0;
            case 'DAILY':
              return 1;
            case 'WEEKLY':
              return 2;
            case 'EVERY_SECOND_WEEK':
              return 3;
            case 'MONTHLY':
              return 4;
            default:
              return 0;
          }
        }
        const sortByTime = studyEvent => moment(studyEvent.startTime).format('HHmmss');
        return _.sortBy(studyEvents, sortByStartDate, sortByEndDate, sortByRecurs, sortByTime);
      }
    };
    function findLocations(courseUnitRealisation) {
      return api.findStudyEventsForCourseUnitRealisation(courseUnitRealisation).then(studyEvents => {
        const locationIds = _.flatMap(studyEvents, studyEvent => studyEvent.locationIds);
        return locationService.findByIds(locationIds);
      });
    }
    return api;
  }
})();