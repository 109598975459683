/**
 * @format
 */

import { Inject, Injectable } from '@angular/core';
import { PlanValidationTs, ValidatablePlan } from 'common-typescript';
import {
    Attainment,
    CourseUnit,
    CustomStudyDraft,
    Education,
    EntityWithRule,
    Grade,
    GradeScale,
    LocalId,
    Module,
    OtmId,
    Plan,
    StudyRight,
    SubstituteFor,
} from 'common-typescript/types';
import * as _ from 'lodash-es';

import {
    COLOR_SERVICE,
    COMMON_GRADE_SCALE_SERVICE,
    COMMON_MODULE_SERVICE,
    COMMON_PLAN_SERVICE,
    COMMON_STUDY_RIGHT_SERVICE,
    CUSTOM_STUDY_DRAFT_INFO_MODAL_SERVICE,
    PLAN_STUDY_RIGHT_SERVICE,
    PLAN_VALIDATION_RESULT_HELPER,
    VALID_ATTAINMENT_FILTER_SERVICE,
} from '../ajs-upgraded-modules';

import { CommonGradeAverageService } from './common-grade-average.service';
import { EducationEntityService } from './education-entity.service';

export interface PlanData {
    education: Education;
    modulesById: { [id: string]: Module };
    courseUnitsById: { [id: string]: CourseUnit };
    attainmentsById: { [id: string]: Attainment };
    customStudyDraftsById: { [id: string]: CustomStudyDraft };
}

export interface EducationStateObject {
    type: 'EducationStateObject';
    educationValidationResult: any;
    selectedCourseUnitIds: OtmId[];
    selectedModuleIds: OtmId[];
    selectedCustomCourseUnitAttainmentIds: OtmId[];
    selectedCustomModuleAttainmentIds: OtmId[];
    selectedCustomStudyDraftIds: LocalId[];
    implicitCourseUnitIds: OtmId[];
    implicitModuleIds: OtmId[];
    elementId: string;
}

export interface ModuleStateObject {
    type: 'ModuleStateObject';
    studyRightState: string;
    attainmentId: OtmId;
    grade: Grade;
    attainmentExpiryDate: string;
    isAttainmentAboutToExpire: boolean;
    selectedParentModuleId: OtmId;
    selectedParentCustomModuleAttainmentId: OtmId;
    moduleValidationResult: any;
    invalidSelection: boolean;
    hasModuleContentApproval: boolean;
    invalidSelectionAccordingToModuleContentApproval: boolean;
    isInvalidAccordingToModuleContentApprovalRequested: boolean;
    isInvalidAccordingToModuleContentApprovalAccepted: boolean;
    colorCategoryCssClass: string;
    planState: string;
    selectedCourseUnitIds: OtmId[];
    selectedModuleIds: OtmId[];
    selectedCustomCourseUnitAttainmentIds: OtmId[];
    selectedCustomModuleAttainmentIds: OtmId[];
    selectedCustomStudyDraftIds: LocalId[];
    implicitCourseUnitIds: OtmId[];
    implicitModuleIds: OtmId[];
    gradeAverage: number;
    isInPlan: boolean;
    elementId: string;
}

export interface CourseUnitStateObject {
    type: 'CourseUnitStateObject';
    attainmentId: OtmId;
    grade: Grade;
    attainmentExpiryDate: string;
    isAttainmentAboutToExpire: boolean;
    selectedParentModuleId: OtmId;
    selectedParentCustomModuleAttainmentId: OtmId;
    courseUnitValidationResult: any;
    invalidSelection: boolean;
    hasModuleContentApproval: boolean;
    invalidSelectionAccordingToModuleContentApproval: boolean;
    isInvalidAccordingToModuleContentApprovalRequested: boolean;
    isInvalidAccordingToModuleContentApprovalAccepted: boolean;
    colorCategoryCssClass: string;
    isSubstituted: boolean;
    substitutedBy: OtmId[];
    substituteFor: SubstituteFor[];
    isInPlan: boolean;
    isInPlanAsSubstitute: boolean;
    newerVersionAvailable?: boolean;
}

export interface CustomModuleAttainmentStateObject {
    type: 'CustomModuleAttainmentStateObject';
    grade: Grade;
    attainmentExpiryDate: string;
    isAttainmentAboutToExpire: boolean;
    selectedParentModuleId: OtmId;
    selectedParentCustomModuleAttainmentId: OtmId;
    customModuleValidationResult: any;
    invalidSelection: boolean;
    hasModuleContentApproval: boolean;
    invalidSelectionAccordingToModuleContentApproval: boolean;
    isInvalidAccordingToModuleContentApprovalRequested: boolean;
    isInvalidAccordingToModuleContentApprovalAccepted: boolean;
    colorCategoryCssClass: string;
    planState: string;
    selectedCourseUnitIds: OtmId[];
    selectedModuleIds: OtmId[];
    selectedCustomCourseUnitAttainmentIds: OtmId[];
    selectedCustomModuleAttainmentIds: OtmId[];
    gradeAverage: number;
    isInPlan: boolean;
}

export interface CustomCourseUnitAttainmentStateObject {
    type: 'CustomCourseUnitAttainmentStateObject';
    grade: Grade;
    attainmentExpiryDate: string;
    isAttainmentAboutToExpire: boolean;
    selectedParentModuleId: OtmId;
    selectedParentCustomModuleAttainmentId: OtmId;
    customMCourseUnitAttainmentValidationResult: any;
    invalidSelection: boolean;
    hasModuleContentApproval: boolean;
    invalidSelectionAccordingToModuleContentApproval: boolean;
    isInvalidAccordingToModuleContentApprovalRequested: boolean;
    isInvalidAccordingToModuleContentApprovalAccepted: boolean;
    colorCategoryCssClass: string;
    isInPlan: boolean;
}

export interface CustomStudyDraftStateObject {
    type: 'CustomStudyDraftStateObject';
    parentModuleId: OtmId;
}

export interface PlanStateAndData {
    planData: PlanData;
    planStateObject: PlanStateObject;
}

export interface PlanStateObject {
    education: EducationStateObject;
    modules: { [id: string]: ModuleStateObject };
    courseUnits: { [id: string]: CourseUnitStateObject };
    customModuleAttainments: { [id: string]: CustomModuleAttainmentStateObject };
    customCourseUnitAttainments: { [id: string]: CustomCourseUnitAttainmentStateObject };
    customStudyDrafts: { [id: string]: CustomStudyDraftStateObject };
    studyRight: StudyRight;
}

@Injectable({ providedIn: 'root' })
export class PlanStateService {
    constructor(
        private educationEntityService: EducationEntityService,
        @Inject(COMMON_PLAN_SERVICE) private commonPlanService: any,
        @Inject(COMMON_STUDY_RIGHT_SERVICE) private commonStudyRightService: any,
        @Inject(PLAN_STUDY_RIGHT_SERVICE) private planStudyRightService: any,
        @Inject(VALID_ATTAINMENT_FILTER_SERVICE) private validAttainmentFilterService: any,
        @Inject(PLAN_VALIDATION_RESULT_HELPER) private planValidationResultHelper: any,
        @Inject(COLOR_SERVICE) private colorService: any,
        @Inject(COMMON_GRADE_SCALE_SERVICE) private commonGradeScaleService: any,
        private commonGradeAverageService: CommonGradeAverageService,
        @Inject(CUSTOM_STUDY_DRAFT_INFO_MODAL_SERVICE) private customStudyDraftInfoModalService: any,
        @Inject(COMMON_MODULE_SERVICE) private commonModuleService: any,
    ) {}

    getPlanStateAndData(
        education: Education,
        validatablePlan: ValidatablePlan,
        planValidationResult: any,
        educationOptions: any,
        gradeScalesById: { [id: string]: GradeScale },
        studyRight: StudyRight,
        outDatedCourseUnitsInPlan?: [CourseUnit, CourseUnit][],
    ): PlanStateAndData {
        const planData = {
            modulesById: {},
            courseUnitsById: {},
            attainmentsById: {},
            customStudyDraftsById: {},
        } as PlanData;
        const planStateObject: PlanStateObject = {
            education: this.getEducationStateObject(education, validatablePlan, planValidationResult, planData),
            modules: this.getModuleStates(validatablePlan, educationOptions, planValidationResult, gradeScalesById, planData),
            courseUnits: this.getCourseUnitStateObject(
                validatablePlan,
                planValidationResult,
                gradeScalesById,
                planData,
                outDatedCourseUnitsInPlan,
            ),
            customModuleAttainments: this.getCustomModuleAttainmentStateObject(
                validatablePlan,
                planValidationResult,
                gradeScalesById,
                planData,
            ),
            customCourseUnitAttainments: this.getCustomCourseUnitAttainmentStateObject(
                validatablePlan,
                planValidationResult,
                gradeScalesById,
                planData,
            ),
            customStudyDrafts: this.getCustomStudyDraftStateObject(validatablePlan, planValidationResult, planData),
            studyRight,
        };
        return {
            planData,
            planStateObject,
        };
    }

    getEducationStateObject(
        education: Education,
        validatablePlan: ValidatablePlan,
        planValidationResult: any,
        planData: PlanData,
    ): EducationStateObject {
        planData.education = education;
        let childModules = validatablePlan.getSelectedModulesUnderModule(education);
        if (_.every(childModules, { type: 'DegreeProgramme' })) {
            childModules = this.commonModuleService.getDegreeProgrammesOrderedByTypeUrn(childModules);
        }
        return {
            type: 'EducationStateObject',
            educationValidationResult: planValidationResult.rootValidationResult,
            selectedCourseUnitIds: _.map(validatablePlan.getSelectedCourseUnitsUnderModule(education), 'id'),
            selectedModuleIds: _.map(childModules, 'id'),
            selectedCustomCourseUnitAttainmentIds: _.uniq(validatablePlan.getSelectedCustomCourseUnitAttainmentIdsUnderModule(education)),
            selectedCustomModuleAttainmentIds: _.uniq(validatablePlan.getSelectedCustomModuleAttainmentIdsUnderModule(education)),
            selectedCustomStudyDraftIds: _.map(validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(education.id), 'id'),
            implicitCourseUnitIds: planValidationResult.rootValidationResult?.implicitCourseUnitIds || [],
            implicitModuleIds: planValidationResult.rootValidationResult?.implicitCourseUnitIds || [],
            elementId: `edu:${education.id}`,
        } as EducationStateObject;
    }

    getModuleStates(
        validatablePlan: ValidatablePlan,
        educationOptions: any,
        planValidationResult: any,
        gradeScalesById: { [id: string]: GradeScale },
        planData: PlanData,
    ): { [id: string]: ModuleStateObject } {
        const moduleStateObjectsById: { [id: string]: ModuleStateObject } = {};
        const modulesInPlan = _.values(validatablePlan.modulesById);
        _.forEach(modulesInPlan, (module) => {
            _.set(planData.modulesById, module.id, module);
            const stateObject: any = {};
            stateObject.studyRightState = this.getStudyRightStateForModule(module, educationOptions, validatablePlan);
            const moduleAttainment = validatablePlan.getModuleAttainment(module.id);
            stateObject.attainmentId = moduleAttainment ? moduleAttainment.id : null;
            if (moduleAttainment) {
                stateObject.grade = this.getGrade(moduleAttainment.gradeId, gradeScalesById[moduleAttainment.gradeScaleId]);
                _.set(planData.attainmentsById, moduleAttainment.id, moduleAttainment);
            }
            stateObject.attainmentExpiryDate = moduleAttainment ? moduleAttainment.expiryDate : null;
            stateObject.isAttainmentAboutToExpire = this.isAttainmentAboutToExpire(moduleAttainment, validatablePlan);
            const moduleSelection = validatablePlan.getModuleSelection(module.id);
            const selectedParent = validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(module);
            stateObject.selectedParentModuleId = _.get(moduleSelection, 'parentModuleId');
            stateObject.selectedParentCustomModuleAttanmentId = _.get(moduleSelection, 'parentModuleAttainmentId');
            const moduleValidationResult = this.planValidationResultHelper.getModuleValidationResult(module.id, planValidationResult);
            if (moduleValidationResult) {
                stateObject.moduleValidationResult = moduleValidationResult;
                stateObject.invalidSelection = moduleValidationResult.invalidSelection;
                stateObject.hasModuleContentApproval = moduleValidationResult.invalidAccordingToModuleContentApproval !== undefined;
                stateObject.invalidSelectionAccordingToModuleContentApproval =
                    moduleValidationResult.invalidAccordingToModuleContentApproval;
                stateObject.planState = PlanValidationTs.getPlanStateForModule(module, validatablePlan, planValidationResult);
                stateObject.implicitCourseUnitIds = moduleValidationResult.implicitCourseUnitIds || [];
                stateObject.implicitModuleIds = moduleValidationResult.implicitModuleIds || [];
                stateObject.isInvalidAccordingToModuleContentApprovalRequested =
                    moduleValidationResult.invalidAccordingToModuleContentApproval &&
                    !this.isModuleContentApproved(selectedParent as EntityWithRule, planValidationResult);
                stateObject.isInvalidAccordingToModuleContentApprovalAccepted =
                    moduleValidationResult.invalidAccordingToModuleContentApproval &&
                    this.isModuleContentApproved(selectedParent as EntityWithRule, planValidationResult);
            } else if (moduleAttainment) {
                stateObject.moduleValidationResult = {
                    state: 'ATTAINED',
                    plannedCredits: { min: moduleAttainment.credits, max: moduleAttainment.credits },
                    attainedCredits: moduleAttainment.credits,
                };
            } else {
                stateObject.moduleValidationResult = {};
            }
            const colorCategory = this.colorService.getModuleColorCategory(validatablePlan, module.id);
            stateObject.colorCategoryCssClass = `cu-color-${colorCategory}`;
            stateObject.selectedCourseUnitIds = _.map(validatablePlan.getSelectedCourseUnitsUnderModule(module), 'id');
            stateObject.selectedModuleIds = _.map(validatablePlan.getSelectedModulesUnderModule(module), 'id');
            stateObject.selectedCustomCourseUnitAttainmentIds = _.uniq(
                validatablePlan.getSelectedCustomCourseUnitAttainmentIdsUnderModule(module),
            );
            stateObject.selectedCustomModuleAttainmentIds = _.uniq(validatablePlan.getSelectedCustomModuleAttainmentIdsUnderModule(module));
            stateObject.selectedCustomStudyDraftIds = _.map(validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(module.id), 'id');
            if (module.type !== 'GroupingModule') {
                stateObject.gradeAverage = this.getGradeAverage(module, moduleAttainment, validatablePlan, gradeScalesById['sis-0-5']);
            }
            stateObject.isInPlan = validatablePlan.isModuleInPlan(module.id);
            stateObject.type = 'ModuleStateObject';
            stateObject.elementId = `mod:${module.id}`;
            moduleStateObjectsById[module.id] = stateObject as ModuleStateObject;
        });
        return moduleStateObjectsById;
    }

    getCourseUnitStateObject(
        validatablePlan: ValidatablePlan,
        planValidationResult: any,
        gradeScalesById: { [id: string]: GradeScale },
        planData: PlanData,
        outDatedCourseUnitsInPlan?: [CourseUnit, CourseUnit][],
    ): { [id: string]: CourseUnitStateObject } {
        const courseUnitStatesById: { [id: string]: CourseUnitStateObject } = {};
        const courseUnitsInPlan = _.values(validatablePlan.courseUnitsById);
        _.forEach(courseUnitsInPlan, (courseUnit) => {
            _.set(planData.courseUnitsById, courseUnit.id, courseUnit);
            const stateObject: any = {};
            const courseUnitAttainment = validatablePlan.getCourseUnitAttainment(courseUnit.id);
            stateObject.attainmentId = courseUnitAttainment ? courseUnitAttainment.id : null;
            if (courseUnitAttainment) {
                stateObject.grade = this.getGrade(courseUnitAttainment.gradeId, gradeScalesById[courseUnitAttainment.gradeScaleId]);
                _.set(planData.attainmentsById, courseUnitAttainment.id, courseUnitAttainment);
            }
            stateObject.attainmentExpiryDate = courseUnitAttainment ? courseUnitAttainment.expiryDate : null;
            stateObject.isAttainmentAboutToExpire = this.isAttainmentAboutToExpire(courseUnitAttainment, validatablePlan);
            const courseUnitSelection = validatablePlan.getCourseUnitSelection(courseUnit.id);
            const selectedParent = validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit);
            const selectedParentModuleId = _.get(courseUnitSelection, 'parentModuleId');
            stateObject.selectedParentModuleId = selectedParentModuleId;
            stateObject.selectedParentCustomModuleAttanmentId = _.get(courseUnitSelection, 'parentModuleAttainmentId');
            const courseUnitValidationResult = this.planValidationResultHelper.getCourseUnitValidationResult(
                courseUnit.id,
                planValidationResult,
            );
            if (courseUnitValidationResult) {
                stateObject.courseUnitValidationResult = courseUnitValidationResult;
                stateObject.invalidSelection = courseUnitValidationResult.invalidSelection;
                stateObject.hasModuleContentApproval = courseUnitValidationResult.invalidAccordingToModuleContentApproval !== undefined;
                stateObject.invalidSelectionAccordingToModuleContentApproval =
                    courseUnitValidationResult.invalidAccordingToModuleContentApproval;

                const module = _.get(validatablePlan.modulesById, selectedParentModuleId);
                stateObject.isInvalidAccordingToModuleContentApprovalRequested =
                    courseUnitValidationResult.invalidAccordingToModuleContentApproval &&
                    !this.isModuleContentApproved(module as EntityWithRule, planValidationResult);
                stateObject.isInvalidAccordingToModuleContentApprovalAccepted =
                    courseUnitValidationResult.invalidAccordingToModuleContentApproval &&
                    this.isModuleContentApproved(module as EntityWithRule, planValidationResult);
            }
            const colorCategory = this.colorService.getCourseUnitColorCategory(validatablePlan, courseUnit.id);
            stateObject.colorCategoryCssClass = `cu-color-${colorCategory}`;
            stateObject.isInPlan = validatablePlan.isCourseUnitInPlan(courseUnit);
            stateObject.isInPlanAsSubstitute = validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnit);
            stateObject.isSubstituted = !_.isEmpty(_.get(courseUnitSelection, 'substitutedBy'));
            stateObject.substitutedBy = _.get(courseUnitSelection, 'substitutedBy');
            stateObject.substituteFor = _.get(courseUnitSelection, 'substituteFor');
            stateObject.type = 'CourseUnitStateObject';
            stateObject.newerVersionAvailable = _.some(
                outDatedCourseUnitsInPlan,
                (outDatedCourseUnit) => outDatedCourseUnit[0].id === courseUnit.id,
            );
            courseUnitStatesById[courseUnit.id] = stateObject as CourseUnitStateObject;
        });
        return courseUnitStatesById;
    }

    getCustomModuleAttainmentStateObject(
        validatablePlan: ValidatablePlan,
        planValidationResult: any,
        gradeScalesById: { [id: string]: GradeScale },
        planData: PlanData,
    ): { [id: string]: CustomModuleAttainmentStateObject } {
        const customModuleAttainmentStatesById: { [id: string]: CustomModuleAttainmentStateObject } = {};
        const customModuleAttainmentsPlan = _.values(validatablePlan.customModuleAttainmentsById);
        _.forEach(customModuleAttainmentsPlan, (customModuleAttainment) => {
            _.set(planData.attainmentsById, customModuleAttainment.id, customModuleAttainment);
            const stateObject: any = {};
            stateObject.grade = this.getGrade(customModuleAttainment.gradeId, gradeScalesById[customModuleAttainment.gradeScaleId]);
            stateObject.attainmentExpiryDate = customModuleAttainment ? customModuleAttainment.expiryDate : null;
            stateObject.isAttainmentAboutToExpire = this.isAttainmentAboutToExpire(customModuleAttainment, validatablePlan);
            const customModuleAttainmentSelection = validatablePlan.customModuleAttainmentIdSelectionMap[customModuleAttainment.id];
            const selectedParent = validatablePlan.getParentModuleOrCustomModuleAttainmentForCustomAttainment(customModuleAttainment);
            stateObject.selectedParentModuleId = _.get(customModuleAttainmentSelection, 'parentModuleId');
            stateObject.selectedParentCustomModuleAttanmentId = _.get(customModuleAttainmentSelection, 'parentModuleAttainmentId');
            const customModuleAttainmentValidationResult = this.planValidationResultHelper.getCustomModuleAttainmentValidationResult(
                customModuleAttainment.id,
                planValidationResult,
            );
            if (customModuleAttainmentValidationResult) {
                stateObject.customModuleAttainmentValidationResult = customModuleAttainmentValidationResult;
                stateObject.invalidSelection = customModuleAttainmentValidationResult.invalidSelection;
                stateObject.hasModuleContentApproval =
                    customModuleAttainmentValidationResult.invalidAccordingToModuleContentApproval !== undefined;
                stateObject.invalidSelectionAccordingToModuleContentApproval =
                    customModuleAttainmentValidationResult.invalidAccordingToModuleContentApproval;
                stateObject.planState = PlanValidationTs.getPlanStateForModule(
                    customModuleAttainment,
                    validatablePlan,
                    planValidationResult,
                );
                stateObject.isInvalidAccordingToModuleContentApprovalRequested =
                    customModuleAttainmentValidationResult.invalidAccordingToModuleContentApproval &&
                    !this.isModuleContentApproved(selectedParent as EntityWithRule, planValidationResult);
                stateObject.isInvalidAccordingToModuleContentApprovalAccepted =
                    customModuleAttainmentValidationResult.invalidAccordingToModuleContentApproval &&
                    this.isModuleContentApproved(selectedParent as EntityWithRule, planValidationResult);
            }
            const colorCategory = this.colorService.getModuleColorCategory(validatablePlan, customModuleAttainment.id);
            stateObject.colorCategoryCssClass = `cu-color-${colorCategory}`;
            stateObject.selectedCourseUnitIds = _.map(
                validatablePlan.getSelectedCourseUnitsUnderCustomModuleAttainment(customModuleAttainment),
                'id',
            );
            stateObject.selectedModuleIds = _.map(
                validatablePlan.getSelectedModulesUnderCustomModuleAttainment(customModuleAttainment),
                'id',
            );
            stateObject.selectedCustomCourseUnitAttainmentIds = _.map(
                _.uniq(validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderCustomModuleAttainment(customModuleAttainment)),
                'id',
            );
            stateObject.selectedCustomModuleAttainmentIds = _.map(
                _.uniq(validatablePlan.getSelectedCustomModuleAttainmentsUnderCustomModuleAttainment(customModuleAttainment)),
                'id',
            );
            stateObject.gradeAverage = this.getGradeAverage(null, customModuleAttainment, validatablePlan, gradeScalesById['sis-0-5']);
            stateObject.isInPlan = validatablePlan.isModuleAttainmentInPlan(customModuleAttainment.id);
            stateObject.type = 'CustomModuleAttainmentStateObject';

            customModuleAttainmentStatesById[customModuleAttainment.id] = stateObject as CustomModuleAttainmentStateObject;
        });
        return customModuleAttainmentStatesById;
    }

    getCustomCourseUnitAttainmentStateObject(
        validatablePlan: ValidatablePlan,
        planValidationResult: any,
        gradeScalesById: { [id: string]: GradeScale },
        planData: PlanData,
    ): { [id: string]: CustomCourseUnitAttainmentStateObject } {
        const customCourseUnitAttainmentStatesById: { [id: string]: CustomCourseUnitAttainmentStateObject } = {};
        const customCourseUnitAttainmentsInPlan = _.values(validatablePlan.customCourseUnitAttainmentsById);
        _.forEach(customCourseUnitAttainmentsInPlan, (customCourseUnitAttainment) => {
            _.set(planData.attainmentsById, customCourseUnitAttainment.id, customCourseUnitAttainment);
            const stateObject: any = {};
            stateObject.grade = this.getGrade(customCourseUnitAttainment.gradeId, gradeScalesById[customCourseUnitAttainment.gradeScaleId]);

            stateObject.attainmentExpiryDate = customCourseUnitAttainment.expiryDate || null;
            stateObject.isAttainmentAboutToExpire = this.isAttainmentAboutToExpire(customCourseUnitAttainment, validatablePlan);
            const customCourseUnitAttainmentSelection =
                validatablePlan.customCourseUnitAttainmentIdSelectionMap[customCourseUnitAttainment.id];
            const selectedParent = validatablePlan.getParentModuleOrCustomModuleAttainmentForCustomAttainment(customCourseUnitAttainment);
            const selectedParentModuleId = _.get(customCourseUnitAttainmentSelection, 'parentModuleId');
            stateObject.selectedParentModuleId = selectedParentModuleId;
            stateObject.selectedParentCustomModuleAttanmentId = _.get(customCourseUnitAttainmentSelection, 'parentModuleAttainmentId');
            const customCourseUnitAttainmentValidationResult =
                this.planValidationResultHelper.getCustomCourseUnitAttainmentValidationResult(
                    customCourseUnitAttainment.id,
                    planValidationResult,
                );
            if (customCourseUnitAttainmentValidationResult) {
                stateObject.customCourseUnitAttainmentValidationResult = customCourseUnitAttainmentValidationResult;
                stateObject.invalidSelection = customCourseUnitAttainmentValidationResult.invalidSelection;
                stateObject.hasModuleContentApproval =
                    customCourseUnitAttainmentValidationResult.invalidAccordingToModuleContentApproval !== undefined;
                stateObject.invalidSelectionAccordingToModuleContentApproval =
                    customCourseUnitAttainmentValidationResult.invalidAccordingToModuleContentApproval;
                const module = _.get(validatablePlan.modulesById, selectedParentModuleId);
                stateObject.isInvalidAccordingToModuleContentApprovalRequested =
                    customCourseUnitAttainmentValidationResult.invalidAccordingToModuleContentApproval &&
                    !this.isModuleContentApproved(module as EntityWithRule, planValidationResult);
                stateObject.isInvalidAccordingToModuleContentApprovalAccepted =
                    customCourseUnitAttainmentValidationResult.invalidAccordingToModuleContentApproval &&
                    this.isModuleContentApproved(module as EntityWithRule, planValidationResult);
            }
            const colorCategory = this.colorService.getCourseUnitColorCategory(validatablePlan, customCourseUnitAttainment.id);
            stateObject.colorCategoryCssClass = `cu-color-${colorCategory}`;
            stateObject.isInPlan = validatablePlan.isCustomCourseUnitAttainmentInPlan(customCourseUnitAttainment);
            stateObject.type = 'CustomCourseUnitAttainmentStateObject';

            customCourseUnitAttainmentStatesById[customCourseUnitAttainment.id] = stateObject as CustomCourseUnitAttainmentStateObject;
        });
        return customCourseUnitAttainmentStatesById;
    }

    getCustomStudyDraftStateObject(
        validatablePlan: ValidatablePlan,
        planValidationResult: { [id: string]: GradeScale },
        planData: PlanData,
    ): { [id: string]: CustomStudyDraftStateObject } {
        const customStudyDraftStatesById: { [id: string]: CustomStudyDraftStateObject } = {};
        const customStudyDraftsInPlan = validatablePlan.plan.customStudyDrafts || [];
        _.forEach(customStudyDraftsInPlan, (customStudyDraft) => {
            _.set(planData.customStudyDraftsById, customStudyDraft.id, customStudyDraft);
            const stateObject: any = {};
            stateObject.selectedParentModuleId = _.get(customStudyDraft, 'parentModuleId');
            stateObject.type = 'CustomStudyDraftStateObject';

            customStudyDraftStatesById[customStudyDraft.id] = stateObject as CustomStudyDraftStateObject;
        });
        return customStudyDraftStatesById;
    }

    getMatchingStudyRight(plan: Plan, studyRights: StudyRight[]): StudyRight {
        return _.find(studyRights, (studyRight) => {
            if (studyRight.educationId !== plan.rootId) {
                return false;
            }
            if (studyRight.learningOpportunityId !== plan.learningOpportunityId) {
                return false;
            }
            if (studyRight.documentState !== 'ACTIVE') {
                return false;
            }
            if (!_.includes(['ACTIVE', 'ACTIVE_NONATTENDING', 'PASSIVE', 'NOT_STARTED'], studyRight.state)) {
                return false;
            }
            return true;
        });
    }

    getStudyRightStateForModule(module: EntityWithRule, educationOptions: any, validatablePlan: ValidatablePlan): boolean {
        if (!module || !educationOptions) {
            return null;
        }
        const matchingEducationOption = this.planStudyRightService
            .getMatchingEducationOption(module.groupId, educationOptions, validatablePlan);
        if (!!matchingEducationOption && matchingEducationOption.isInPlan === true &&
            matchingEducationOption.isInPlanAsMinor === false) {
            return matchingEducationOption.studyRightState;
        }
        return null;
    }

    isAttainmentAboutToExpire(attainment: Attainment, validatablePlan: ValidatablePlan): boolean {
        if (
            attainment &&
            attainment.expiryDate &&
            this.validAttainmentFilterService.isAttainmentAboutToExpire(attainment) &&
            !this.validAttainmentFilterService.isAttached(attainment, validatablePlan.getAllAttainments())
        ) {
            return true;
        }
        return false;
    }

    getGrade(gradeId: number, gradeScale: GradeScale): Grade {
        return _.find(gradeScale.grades, (grade) => grade.localId === gradeId);
    }

    getGradeAverage(
        module: EntityWithRule,
        moduleAttainment: Attainment,
        validatablePlan: ValidatablePlan,
        gradeScale: GradeScale,
    ): number {
        let gradeAverageResult;
        let attainmentIds;
        const method = 'COURSE_UNIT_AND_EMPTY_MODULE_ARITHMETIC_MEAN_WEIGHTED_BY_CREDITS';
        const allAttainments = _.values(validatablePlan.attainmentsById);
        if (moduleAttainment) {
            attainmentIds = [moduleAttainment.id];
            gradeAverageResult = this.commonGradeAverageService.calculateGradeAverage(attainmentIds, allAttainments, gradeScale, method);
        } else {
            attainmentIds = this.commonGradeAverageService.getAttainmentIdsForModule(module, validatablePlan);
            gradeAverageResult = this.commonGradeAverageService.calculateGradeAverage(attainmentIds, allAttainments, gradeScale, method);
        }
        const gradeAverage = _.get(gradeAverageResult, 'gradeAverage');
        return _.isNumber(gradeAverage) ? +gradeAverage.toFixed(2) : undefined;
    }

    canApplyForCustomAttainments(
        validatablePlan: ValidatablePlan,
        validatablePlanEducation: Education,
        matchingStudyRight: StudyRight,
    ): boolean {
        const planContext = validatablePlan;
        const education = validatablePlanEducation;
        const studyRight = matchingStudyRight;
        if (planContext && education && studyRight) {
            if (this.isDegreeEducation(education)) {
                const educationChildModules = planContext.getSelectedModulesUnderModule(education);
                return !_.isEmpty(educationChildModules) && _.every(educationChildModules, { type: 'DegreeProgramme' });
            }
        }
        return false;
    }

    private isDegreeEducation(education: Education) {
        return _.startsWith(_.get(education, 'educationType'), 'urn:code:education-type:degree-education');
    }

    private isModuleContentApproved(module: EntityWithRule, planValidationResult: any) {
        const moduleValidationResult = _.get(planValidationResult.moduleValidationResults, module.id);
        return moduleValidationResult.isModuleContentApproved;
    }
}
