<!-- @format -->

<form class="reactive" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="text-md-end">
    <sis-required-field-legend></sis-required-field-legend>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <sis-text-field
          [label]="'STUDENT_APPLICATIONS.CUSTOM_ATTAINMENT.APPLICATION_RATIONALE' | translate"
          [helpBlock]="'PROFILE.APPLICATIONS.CUSTOM_ATTAINMENT.APPLICATION_RATIONALE_INFO'"
          [control]="applicationRationale"
          [textarea]="true"
          id="caa-application-rationale"
        >
        </sis-text-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <sis-text-field
          [label]="'STUDENT_APPLICATIONS.CUSTOM_ATTAINMENT.RESPONSIBLE_TEACHER' | translate"
          [helpBlock]="'PROFILE.APPLICATIONS.CUSTOM_ATTAINMENT.RESPONSIBLE_TEACHER_INFO'"
          [control]="responsibleTeacher"
          id="caa-responsible-teacher"
        >
        </sis-text-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="caa-planned-credits" [class]="getLabelState(plannedCredits)">{{
          "STUDENT_APPLICATIONS.CUSTOM_ATTAINMENT.PLANNED_CREDITS" | translate
        }}</label>
        <div class="row">
          <div class="col-8 col-md-4">
            <sis-text-field [control]="plannedCredits" [showLabelIcon]="false" id="caa-planned-credits"> </sis-text-field>
          </div>
          <div class="col-4 align-content-center" translate>CREDITS</div>
        </div>
        <p *ngIf="plannedCredits.valid || (plannedCredits.untouched && plannedCredits.pristine)" class="help-block">
          {{ "PROFILE.APPLICATIONS.CUSTOM_ATTAINMENT.PLANNED_CREDITS_INFO" | translate }}
        </p>
      </div>
    </div>
    <div class="col-6 col-md-4 offset-md-2">
      <div class="form-group">
        <label [for]="'caa-attainment-language'" [class]="getLabelState(attainmentLanguage)">{{
          "STUDENT_APPLICATIONS.ATTAINMENT_LANGUAGE" | translate
        }}</label>
        <sis-code-select-editor
          [codebookUrn]="attainmentLanguageCodeBookUrn"
          [selectedUrn]="attainmentLanguage.value"
          [required]="true"
          [attr.data-cy]="'caa-attainment-language-editor'"
          (onSelectCode)="onAttainmentLanguageChange($event.selectedUrn)"
          [id]="'caa-attainment-language'"
        >
        </sis-code-select-editor>
        <sis-validation-errors [control]="attainmentLanguage" [formFieldId]="'caa-attainment-language'" [ariaLive]="false" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <sis-text-field
          [label]="'STUDENT_APPLICATIONS.CUSTOM_ATTAINMENT.ATTAINMENT_DESCRIPTION' | translate"
          [helpBlock]="'PROFILE.APPLICATIONS.CUSTOM_ATTAINMENT.ATTAINMENT_DESCRIPTION_INFO'"
          [control]="attainmentDescription"
          [textarea]="true"
          id="caa-attainment-description"
        >
        </sis-text-field>
      </div>
    </div>
  </div>
  <div class="application-attachments">
    <app-application-attachments [initialAttachments]="initialAttachments" (updateAttachments)="attachmentsChange.emit($event)">
    </app-application-attachments>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="float-end">
        <sis-button [naked]="true" (clicked)="exit.emit()">
          <sis-icon icon="back"></sis-icon>{{ "SIS_COMPONENTS.BUTTON.EXIT" | translate }}
        </sis-button>
        <sis-button [hollow]="true" [attr.data-cy]="'back-button'" (clicked)="previous.emit()">
          {{ "SIS_COMPONENTS.BUTTON.PREVIOUS" | translate }}
        </sis-button>
        <sis-button type="submit" [attr.data-cy]="'continue-button'">
          {{ "SIS_COMPONENTS.BUTTON.CONTINUE" | translate }}
        </sis-button>
      </div>
    </div>
  </div>
</form>
