import { ChangeDetectionStrategy, Component, inject, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppErrorHandler } from '../error-handler/app-error-handler';

/**
 * Confirmation dialog FD modal is a confirm dialog implementation using fudis dialog.
 * It is currently not a full implementation of the old confirmDialog, but the interface has been kept the same.
 * Currently supported are description, descriptions, notifications and callBackObservable parameters. As other features are needed, they can
 * be easily implemented.
 */

export interface FdDialogDescriptionSet {
    name?: string;
    descriptions?: string[];
    listTitle?: string;
    objects?: any[];
}

export interface ConfirmDialogFdValues<T> {
    title: string;
    description?: string;
    descriptions?: string[];
    notifications?: string[];
    name?: string;
    listTitle?: string;
    objects?: any[];
    descriptionSets?: FdDialogDescriptionSet[];
    hideCancel?: boolean;
    confirmText?: string;
    cancelText?: string;
    /**
     * "Callback" observable that will be subscribed to when user click on "confirm" button. Modal will be closed after first value is
     * emitted from observable.
     */
    confirmObservable?: Observable<T>;
}

export function confirmDialogFdOpener() {
    const fudisDialogService = inject(FudisDialogService);
    return (modalValues?: ConfirmDialogFdValues<boolean>) => fudisDialogService.open(ConfirmDialogFdComponent<boolean>, { data: modalValues });
}

@Component({
    selector: 'sis-confirm-dialog-fd',
    templateUrl: './confirm-dialog-fd.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogFdComponent<T> implements OnInit {

    descriptions: string[] = [];
    notifications: string[] = [];
    private closed = false;

    private fudisDialogService = inject(FudisDialogService);

    constructor(
        private appErrorHandler: AppErrorHandler,
        @Inject(MAT_DIALOG_DATA) public values: ConfirmDialogFdValues<T>,
    ) {
    }

    ngOnInit(): void {
        this.descriptions = this.values.descriptions || [];
        if (this.values.description) {
            this.descriptions.unshift(this.values.description);
        }
        this.notifications = this.values.notifications || [];
    }

    close() {
        // prevent double clicks
        if (this.closed) {
            return ;
        }
        this.closed = true;
        if (!this.values.confirmObservable) {
            this.fudisDialogService.close();
            return;
        }
        this.values.confirmObservable
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe((response: T) => {
                this.fudisDialogService.close(response);
            });
    }
}
