<!-- @format -->

<div class="row">
  <div class="col">
    <h3>{{ "FILE_UPLOAD.FILE_TITLE" | translate }}</h3>
  </div>
  <div class="col-md-auto button-holder">
    <sis-button (clicked)="editAttachments()" [size]="'sm'">{{ "BUTTON.EDIT" | translate }}</sis-button>
  </div>
</div>
<p class="guidance" *ngIf="(!initialAttachments || initialAttachments.length === 0) && externalAttainedStudyAttachments.length === 0"
  >{{ "FILE_UPLOAD.ZERO_FILES" | translate }}
</p>
<sis-emrex-attachments [externalAttainedStudyAttachments]="externalAttainedStudyAttachments"></sis-emrex-attachments>
<div class="object file-preview" *ngFor="let attachment of initialAttachments">
  <a *ngIf="attachment.localId" [href]="attachment.preSignedGetUrl" target="_blank" class="file-name" data-cy="attachment">
    {{ attachment.name }} ({{ readableFileSizeString(attachment.file.size) }})
    <span class="visually-hidden">{{ "ARIA_LABEL.OPENS_TO_A_NEW_TAB" | translate }}</span>
  </a>
  <span *ngIf="!attachment.localId" class="file-name">{{ attachment.file.name }} ({{ readableFileSizeString(attachment.file.size) }})</span>
  <p>{{ attachment.explanation }}</p>
</div>
