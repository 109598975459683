function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import angular from 'angular';
import * as _ from 'lodash-es';
import { firstValueFrom } from "rxjs";
import completionMethodsTpl from './completionMethods.tpl.html';
(function () {
  CompletionMethodsController.$inject = ["$q", "$log", "$anchorScroll", "$timeout", "$translate", "$filter", "$state", "$rootScope", "uiStateStore", "enrolmentService", "AuthService", "planCourseUnitService", "courseUnitService", "assessmentItemService", "commonCourseUnitRealisationService", "studyPeriodService", "commonStudyPeriodService", "commonEnrolmentService", "planAssessmentItemService", "planCompletionMethodService", "alertsService", "selectAssessmentItemModal", "localeService", "defaultPromiseHandler", "commonGradeScaleService", "enrolmentStudentService"];
  angular.module('student.common.components.courseUnitInfoModal').component('completionMethods', {
    bindings: {
      plan: '<',
      attainments: '<',
      courseUnit: '<',
      completionMethods: '<',
      courseUnitRealisation: '<',
      forceEnableCalendarAdd: '<',
      language: '<',
      level: '<',
      versionChangeInProgress: '<',
      unplannedCourseUnit: '<',
      module: '<',
      realisationTabId: '<'
    },
    controller: CompletionMethodsController,
    template: completionMethodsTpl
  });
  function CompletionMethodsController(
  //NOSONAR
  $q, $log, $anchorScroll, $timeout, $translate, $filter, $state, $rootScope, uiStateStore, enrolmentService, AuthService, planCourseUnitService,
  // NOSONAR
  courseUnitService, assessmentItemService, commonCourseUnitRealisationService, studyPeriodService, commonStudyPeriodService, commonEnrolmentService, planAssessmentItemService, planCompletionMethodService, alertsService, selectAssessmentItemModal, localeService, defaultPromiseHandler, commonGradeScaleService, enrolmentStudentService) {
    const $ctrl = this;
    $ctrl.currentlySelectedCompletionMethod = undefined;
    $ctrl.currentlyHighlightedCompletionMethod = undefined;
    $ctrl.cursPerElement = 5;
    $ctrl.assessmentItems = [];
    $ctrl.CURsByAssessmentItemId = {};
    $ctrl.sectionsOpenByAssessmentItemId = {};
    $ctrl.sectionsOpenByCurId = {};
    $ctrl.sectionsOpenedOnceByCurId = {};
    $ctrl.enrolmentForCourseUnitRealisations = {};
    $ctrl.enrolmentSourceNamesByCurId = {};
    let periods = [];
    function findEnrolmentsForCourseUnitRealisations(courseUnitRealisations) {
      if (AuthService.loggedIn()) {
        const bypassCache = true;
        const loadRelations = false;
        const courseUnitRealisationsIds = _.map(courseUnitRealisations, 'id');
        return commonEnrolmentService.getStudentsEnrolmentsForCourseUnitRealisations(courseUnitRealisationsIds, bypassCache, loadRelations).then(enrolments => {
          $ctrl.enrolmentsCourseUnit = enrolments;
          $ctrl.enrolmentForCourseUnitRealisations = _.keyBy(enrolments, 'courseUnitRealisationId');
          $ctrl.resolveEnrolmentSourceNames(enrolments);
        });
      }
      $ctrl.enrolmentsCourseUnit = [];
      $ctrl.enrolmentForCourseUnitRealisations = {};
      $ctrl.enrolmentSourceNamesByCurId = {};
      return $q.when();
    }
    function loadAssessmentItemsByIds(assessmentItemIds) {
      return assessmentItemService.findByIds(assessmentItemIds).then(assessmentItems => {
        $ctrl.assessmentItems = assessmentItems.map(item => {
          if (item?.responsibilityInfos?.length > 0) {
            return _objectSpread(_objectSpread({}, item), {}, {
              responsibilityInfos: item.responsibilityInfos.filter(info => _.get(info, 'roleUrn') !== 'urn:code:module-responsibility-info-type:administrative-person')
            });
          }
          return item;
        });
      }, error => {
        error.titleKey = 'ERROR.ASSESSMENT_ITEM_NOT_FOUND';
        throw error;
      });
    }
    function loadPeriods() {
      periods = [];
      if ($ctrl.plan) {
        const periodLoads = [];
        _.forEach($ctrl.plan.getPlannedPeriods($ctrl.courseUnit), periodLocator => {
          periodLoads.push(commonStudyPeriodService.getPeriodsFor(periodLocator).then(period => {
            periods.push(period);
          }));
        });
        return $q.all(periodLoads);
      }
      return $q.when(periods);
    }
    function updateSelectedCompletionMethod(completionMethod) {
      $ctrl.currentlySelectedCompletionMethod = completionMethod;
      $ctrl.highlightCompletionMethod(completionMethod);
    }
    function loadStudyYearTemplates() {
      return studyPeriodService.getStudyYearTemplatesForCourseUnit($ctrl.courseUnit).then(templates => {
        $ctrl.studyYearTemplates = templates;
        setCurrentlyHighlightedCompletionMethodRepeats();
      });
    }
    function setCurrentlyHighlightedCompletionMethodRepeats() {
      if ($ctrl.studyYearTemplates && $ctrl.currentlyHighlightedCompletionMethod) {
        $ctrl.currentlyHighlightedCompletionMethodRepeats = _.map($ctrl.currentlyHighlightedCompletionMethod.repeats, repeat => studyPeriodService.transformRepeatArrayToStudyPeriodRangesArray(repeat.repeatPossibility, $ctrl.studyYearTemplates));
      } else {
        $ctrl.currentlyHighlightedCompletionMethodRepeats = undefined;
      }
    }
    function highlightDefaultCompletionMethod() {
      let defaultHighlightedCompletionMethod;
      const courseUnitSelection = $ctrl.plan ? $ctrl.plan.getCourseUnitSelection($ctrl.courseUnit) : undefined;
      if (courseUnitSelection) {
        const selectedCompletionMethodId = courseUnitSelection.completionMethodId;
        if (selectedCompletionMethodId) {
          $ctrl.currentlySelectedCompletionMethod = _.find($ctrl.completionMethods, {
            localId: selectedCompletionMethodId
          });
          defaultHighlightedCompletionMethod = $ctrl.currentlySelectedCompletionMethod;
        }
      }
      if (!defaultHighlightedCompletionMethod && $ctrl.courseUnitRealisation && !_.isEmpty($ctrl.completionMethods)) {
        _.forEach($ctrl.courseUnitRealisation.assessmentItemIds, assessmentItemId => {
          defaultHighlightedCompletionMethod = defaultHighlightedCompletionMethod || _.find($ctrl.completionMethods, completionMethod => _.includes(completionMethod.assessmentItemIds, assessmentItemId));
        });
      }
      if (!defaultHighlightedCompletionMethod) {
        defaultHighlightedCompletionMethod = _.first($ctrl.completionMethods);
      }
      if (defaultHighlightedCompletionMethod) {
        $ctrl.highlightCompletionMethod(defaultHighlightedCompletionMethod);
      }
    }
    function loadCourseUnitRealisations(assessmentItemIds) {
      return commonCourseUnitRealisationService.findActiveCourseUnitRealisationsForAssessmentItemIds(assessmentItemIds).then(courseUnitRealisations => {
        _.forEach(assessmentItemIds, assessmentItemId => {
          const courseUnitRealisationsForAssessmentItem = _.filter(courseUnitRealisations, courseUnitRealisation => _.includes(courseUnitRealisation.assessmentItemIds, assessmentItemId));
          $ctrl.CURsByAssessmentItemId[assessmentItemId] = _.orderBy(courseUnitRealisationsForAssessmentItem, 'activityPeriod.startDate');
        });
        return findEnrolmentsForCourseUnitRealisations(courseUnitRealisations);
      });
    }
    function loadCourseUnitData() {
      const promises = [];
      promises.push(loadStudyYearTemplates());
      const assessmentItemIds = planCourseUnitService.getAssessmentItemIdsByStudyTypes($ctrl.courseUnit, ['DEGREE_STUDIES']);
      if (!_.isEmpty(assessmentItemIds)) {
        promises.push(loadAssessmentItemsByIds(assessmentItemIds));
        promises.push(loadCourseUnitRealisations(assessmentItemIds));
      }
      promises.push(loadPeriods());
      return $q.all(promises).then(() => {
        highlightDefaultCompletionMethod();
        $ctrl.setOpenSelections();
      });
    }
    function checkNeedForShowingSelectAssessmentItemModal(completionMethod) {
      // OTM-16904 requires a dialog on selected completion method when enrolment can be used for more than one
      // completion method
      if ($ctrl.currentlySelectedCompletionMethod === completionMethod && $ctrl.courseUnitRealisation && completionMethod.typeOfRequire !== 'ALL_SELECTED_REQUIRED') {
        const enrolment = $ctrl.enrolmentForCourseUnitRealisations[$ctrl.courseUnitRealisation.id];
        const multiple = _.intersection(completionMethod.assessmentItemIds, $ctrl.courseUnitRealisation.assessmentItemIds);
        if (enrolment && enrolment.state === 'NOT_ENROLLED' && multiple.length > 1) {
          // show dialog for selecting the assessmentItem you wish to select for this enrolment
          selectAssessmentItemModal.open(completionMethod, $ctrl.courseUnitRealisation, $ctrl.courseUnit, enrolment, $ctrl.assessmentItems).result.then(assessmentItemId => {
            if (assessmentItemId && !_.find($ctrl.plan.assessmentItemSelections, {
              assessmentItemId
            })) {
              $ctrl.updateEnrolmentDetails(enrolment, assessmentItemId);
            }
          }, () => angular.noop());
        } else if (enrolment && enrolment.state === 'NOT_ENROLLED' && multiple.length === 1) {
          // update with the one matching
          $ctrl.updateEnrolmentDetails(enrolment, multiple[0]);
        }
      }
    }
    $ctrl.updateEnrolmentDetails = function updateEnrolmentDetails(enrolment, assessmentItemId, isInCalendar) {
      const assessmentItem = _.find($ctrl.assessmentItems, {
        id: assessmentItemId
      });
      if (assessmentItem) {
        let changes = false;
        if (enrolment.assessmentItemId !== assessmentItemId) {
          enrolment.assessmentItemId = assessmentItemId;
          changes = true;
        }
        if ($ctrl.courseUnit && enrolment.courseUnitId !== $ctrl.courseUnit.id) {
          enrolment.courseUnitId = $ctrl.courseUnit.id;
          changes = true;
        }
        if (isInCalendar && isInCalendar !== enrolment.isInCalendar) {
          enrolment.isInCalendar = isInCalendar;
          changes = true;
        }
        if (changes) {
          return commonEnrolmentService.updateEnrolment(enrolment).then(() => {
            if (!isInCalendar && $ctrl.currentlySelectedCompletionMethod.typeOfRequire !== 'ALL_SELECTED_REQUIRED') {
              $ctrl.toggleAssessmentItemSelection(assessmentItem);
            }
            $ctrl.courseUnitRealisation = undefined;
          });
        }
        $ctrl.toggleAssessmentItemSelection(assessmentItem);
        $ctrl.courseUnitRealisation = undefined;
      }
      return $q.when();
    };
    $ctrl.$onChanges = changes => {
      if (changes.courseUnit?.previousValue?.id && changes.courseUnit.currentValue.id !== changes.courseUnit.previousValue.id) {
        $ctrl.anyCurMatchesTiming();
        return loadCourseUnitData();
      }
      return $q.when([]);
    };
    $ctrl.$onInit = function () {
      getGradeScales();
      $ctrl.expanded = false;
      $ctrl.realisationInfoOpenTab = $ctrl.realisationTabId || 0;
      $ctrl.attainedAssessmentItems = _.chain($ctrl.attainments).filter({
        type: 'AssessmentItemAttainment'
      }).map('assessmentItemId').uniq().compact().value();
      return loadCourseUnitData();
    };
    $ctrl.isAssessmentItemAttained = function (assessmentItem) {
      return assessmentItem && _.includes($ctrl.attainedAssessmentItems, assessmentItem.id);
    };
    $ctrl.getAbbreviation = assessmentItem => {
      const attainment = getAttainedAssessmentItemsAttainment(assessmentItem);
      if (attainment && $ctrl.gradeScales && $ctrl.gradeScales[attainment.gradeScaleId]) {
        const scale = $ctrl.gradeScales[attainment.gradeScaleId];
        if (scale && scale.grades) {
          const grade = $ctrl.gradeScales[attainment.gradeScaleId].grades[attainment.gradeId];
          if (grade) {
            return grade.abbreviation;
          }
        }
      }
      return undefined;
    };
    function getGradeScales() {
      commonGradeScaleService.getGradeScales($ctrl.attainments).then(result => {
        $ctrl.gradeScales = result;
      });
    }
    function getAttainedAssessmentItemsAttainment(assessmentItem) {
      if ($ctrl.isAssessmentItemAttained(assessmentItem)) {
        return _.find($ctrl.attainments, {
          assessmentItemId: assessmentItem.id
        });
      }
      return undefined;
    }
    $ctrl.showAddToCalendar = courseUnitRealisation => !this.isCourseUnitRealisationInCalendar(courseUnitRealisation) && AuthService.loggedIn();
    $ctrl.getHighlightedCompletionMethodIndex = function () {
      let index = -1;
      if ($ctrl.currentlyHighlightedCompletionMethod) {
        index = _.findIndex($ctrl.completionMethods, {
          localId: $ctrl.currentlyHighlightedCompletionMethod.localId
        });
      }
      return index + 1;
    };
    $ctrl.curMatchesTiming = function (courseUnitRealisation) {
      let matchesTiming = false;
      if ($ctrl.plan) {
        const courseUnitSelection = $ctrl.plan.getCourseUnitSelection($ctrl.courseUnit);
        matchesTiming = planCourseUnitService.courseUnitRealisationMatchesTiming(courseUnitSelection, courseUnitRealisation, periods);
      }
      return matchesTiming;
    };
    $ctrl.anyCurMatchesTiming = () => _.some(_.flatMap($ctrl.CURsByAssessmentItemId), cur => $ctrl.curMatchesTiming(cur));
    $ctrl.isCourseUnitRealisationInCalendar = function (courseUnitRealisation) {
      return _.get($ctrl.enrolmentForCourseUnitRealisations[courseUnitRealisation.id], 'isInCalendar', false);
    };
    $ctrl.removeFromCalendar = courseUnitRealisation => {
      if (courseUnitRealisation) {
        const enrolment = $ctrl.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
        if (enrolment) {
          enrolment.isInCalendar = false;
          alertsService.dismissAlertIfExists(`enrolment-in-calendar-alert-${courseUnitRealisation.id}`);
          if (enrolment.state === 'NOT_ENROLLED') {
            delete $ctrl.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
            enrolmentStudentService.storeRemove(enrolment.id);
            return commonEnrolmentService.deleteEnrolment(enrolment).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
          }
          return commonEnrolmentService.updateEnrolment(enrolment).then(response => {
            enrolmentStudentService.storeUpsert(_.cloneDeep(response));
          }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        }
      }
      return $q.when([]);
    };
    $ctrl.showRemoveFromCalendar = (courseUnitRealisation, assessmentItem) => $ctrl.isCourseUnitRealisationInCalendar(courseUnitRealisation) && !$ctrl.versionChangeInProgress && !$ctrl.isEnrolledToFromAnotherEntity(courseUnitRealisation, assessmentItem);
    $ctrl.disableRemoveFromCalendar = function (courseUnitRealisation) {
      const enrolment = $ctrl.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
      const enrolmentState = _.get(enrolment, 'state');
      const canRemove = enrolmentState === 'NOT_ENROLLED' || enrolmentState === 'ABORTED_BY_TEACHER' || enrolmentState === 'ABORTED_BY_STUDENT' || enrolmentState === 'REJECTED';
      return !canRemove;
    };
    $ctrl.disableAddToCalendar = function (courseUnitRealisation, assessmentItem) {
      const courseUnitRealisationAllowsAdd = courseUnitRealisation.flowState !== 'CANCELLED';
      const currentlySelectedCompletionMethodAllowsAdd = $ctrl.currentlySelectedCompletionMethod && _.includes($ctrl.currentlySelectedCompletionMethod.assessmentItemIds, assessmentItem.id);
      const canAdd = courseUnitRealisationAllowsAdd && (currentlySelectedCompletionMethodAllowsAdd || $ctrl.forceEnableCalendarAdd);
      return !canAdd;
    };
    const tryAddToCalendar = (courseUnitRealisation, assessmentItem) => {
      const enrolment = $ctrl.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
      if (enrolment) {
        return $ctrl.updateEnrolmentDetails(enrolment, assessmentItem.id, true);
      }
      return firstValueFrom(enrolmentStudentService.findEnrolment(courseUnitRealisation.id, true))
      // there might already exist enrolment e.g. when using multiple browser tabs
      .then(alreadyAddedEnrolment => {
        if (alreadyAddedEnrolment) {
          return _.cloneDeep(alreadyAddedEnrolment);
        }
        return commonEnrolmentService.createOwnEnrolment(courseUnitRealisation, assessmentItem, $ctrl.courseUnit);
      }).then(response => {
        $ctrl.enrolmentForCourseUnitRealisations[courseUnitRealisation.id] = response;
        enrolmentStudentService.storeUpsert(_.cloneDeep(response));
      });
    };
    $ctrl.addToCalendar = (courseUnitRealisation, assessmentItem) => {
      function goToEnrolmentsTab() {
        alertsService.dismissAlertIfExists(`enrolment-in-calendar-alert-${courseUnitRealisation.id}`);

        /* fixes the race-condition which happens when "plan" or "enrolment" is changed and "courseUnitInfoModalFactory"
        tries to reload the current state, when in this case we want the state to navigate to a student-calendar view */
        $rootScope.$broadcast('calendarStateChangePriority');
        $rootScope.$broadcast('closeCourseInfoModal');
        $state.go('student.logged-in.enrolments', {}, {
          reload: true
        });
      }
      if (courseUnitRealisation) {
        $ctrl.addingToCalendar = true;
        return tryAddToCalendar(courseUnitRealisation, assessmentItem).catch(error => {
          defaultPromiseHandler.loggingRejectedPromiseHandler(error);
        }).finally(() => {
          $ctrl.addingToCalendar = false;
          alertsService.info($translate.instant('ENROLMENT.FINNISH_ENROLMENT_IN_ENROLMENT_TAB', {
            cur: $filter('localizedString')(courseUnitRealisation.name),
            date: $filter('activityPeriod')(courseUnitRealisation),
            attainment: $filter('localizedString')(courseUnitRealisation.nameSpecifier)
          }), undefined, `enrolment-in-calendar-alert-${courseUnitRealisation.id}`, goToEnrolmentsTab);
        });
      }
      return $q.when([]);
    };
    $ctrl.isSelectAll = function (completionMethod) {
      return _.get(completionMethod, 'typeOfRequire') === 'ALL_SELECTED_REQUIRED';
    };
    $ctrl.areAssessmentItemsOptional = function () {
      return _.get($ctrl.currentlyHighlightedCompletionMethod, 'typeOfRequire') !== 'ALL_SELECTED_REQUIRED';
    };
    $ctrl.isAssessmentItemSelected = function (assessmentItem, completionMethod) {
      let isSelectedAssessmentItem = false;
      if ($ctrl.plan) {
        isSelectedAssessmentItem = $ctrl.plan.isSelectedAssessmentItem($ctrl.courseUnit, assessmentItem);
        if (isSelectedAssessmentItem && completionMethod) {
          isSelectedAssessmentItem = $ctrl.plan.isSelectedCompletionMethod(completionMethod, $ctrl.courseUnit);
        }
      }
      return isSelectedAssessmentItem;
    };
    $ctrl.toggleAssessmentItemSelection = assessmentItem => {
      if ($ctrl.courseUnit && assessmentItem && $ctrl.plan) {
        return planAssessmentItemService.toggleAssessmentItemSelection(assessmentItem.id, $ctrl.courseUnit.id, $ctrl.plan);
      }
      $log.warn('can not toggle assessmentItem selection, because courseUnit, assessmentItem or plan is not defined', 'assessmentItem:', assessmentItem, 'courseUnit:', $ctrl.courseUnit, 'plan:', $ctrl.plan);
      return undefined;
    };
    $ctrl.isCompletionMethodSelected = function (completionMethod) {
      return planCompletionMethodService.isCompletionMethodSelected($ctrl.courseUnit.id, completionMethod, $ctrl.plan);
    };
    $ctrl.showCheckIcon = function (assessmentItem) {
      const curs = $ctrl.CURsByAssessmentItemId[assessmentItem.id];
      for (let i = 0; i < _.size(curs); i++) {
        if ($ctrl.showRemoveFromCalendar(curs[i], assessmentItem)) {
          return true;
        }
      }
      return false;
    };
    $ctrl.showCurLink = function (courseUnitRealisation) {
      if (!$ctrl.currentlyHighlightedCompletionMethod || !courseUnitRealisation) {
        return false;
      }
      const completionMethodAssessmentItemIds = $ctrl.currentlyHighlightedCompletionMethod.assessmentItemIds;
      for (let i = 0; i < _.size(courseUnitRealisation.assessmentItemIds); i++) {
        if (_.includes(completionMethodAssessmentItemIds, courseUnitRealisation.assessmentItemIds[i])) {
          return true;
        }
      }
      return false;
    };
    $ctrl.selectCompletionMethod = function (completionMethod) {
      return planCompletionMethodService.selectCompletionMethod($ctrl.courseUnit.id, completionMethod, $ctrl.plan, $ctrl.unplannedCourseUnit, $ctrl.module).then(() => {
        updateSelectedCompletionMethod(completionMethod);
      }, () => {
        $log.error('Unable to save completion method');
      });
    };
    $ctrl.highlightCompletionMethod = function (completionMethod) {
      $ctrl.currentlyHighlightedCompletionMethod = completionMethod;
      setCurrentlyHighlightedCompletionMethodRepeats();
      checkNeedForShowingSelectAssessmentItemModal(completionMethod);
    };
    $ctrl.isCompletionMethodHighlighted = function (completionMethod) {
      return completionMethod && _.get($ctrl.currentlyHighlightedCompletionMethod, 'localId') === completionMethod.localId;
    };
    $ctrl.completionMethodContainsAssessmentItem = function (completionMethod, assessmentItem) {
      return _.includes(_.get(completionMethod, 'assessmentItemIds'), _.get(assessmentItem, 'id'));
    };
    $ctrl.isEditable = function () {
      return $ctrl.plan && ($ctrl.plan.getCourseUnitSelection($ctrl.courseUnit) || $ctrl.unplannedCourseUnit) && !$ctrl.plan.isSubstituted($ctrl.courseUnit);
    };
    $ctrl.isCompletionMethodReadOnly = function (completionMethod) {
      let isReadOnly = true;
      if (!$ctrl.plan || !$ctrl.plan.getCourseUnitSelection($ctrl.courseUnit)) {
        isReadOnly = true;
      } else if (completionMethod) {
        isReadOnly = !$ctrl.isCompletionMethodSelected(completionMethod);
      }
      return isReadOnly;
    };
    $ctrl.selectCourseUnitRealisation = function (courseUnitRealisation, assessmentItem) {
      if ($ctrl.currentlyHighlightedCompletionMethod && assessmentItem && !$ctrl.completionMethodContainsAssessmentItem($ctrl.currentlyHighlightedCompletionMethod, assessmentItem)) {
        const completionMethod = _.find($ctrl.completionMethods, method => $ctrl.completionMethodContainsAssessmentItem(method, assessmentItem));
        $ctrl.highlightCompletionMethod(completionMethod);
      }
      $ctrl.courseUnitRealisation = courseUnitRealisation;
      $ctrl.setOpenSelections();
    };
    $ctrl.toggleCurSection = courseUnitRealisation => {
      $ctrl.sectionsOpenByCurId[courseUnitRealisation.id] = !$ctrl.sectionsOpenByCurId[courseUnitRealisation.id];
      $ctrl.sectionsOpenedOnceByCurId[courseUnitRealisation.id] = true;
      $ctrl.expanded = !$ctrl.expanded;
    };
    $ctrl.setOpenSelections = function () {
      $ctrl.sectionsOpenByAssessmentItemId = {};
      $ctrl.sectionsOpenByCurId = {};
      if ($ctrl.currentlyHighlightedCompletionMethod) {
        _.forEach($ctrl.currentlyHighlightedCompletionMethod.assessmentItemIds, assessmentItemId => {
          $ctrl.sectionsOpenByAssessmentItemId[assessmentItemId] = true;
        });
      }
      if ($ctrl.courseUnitRealisation) {
        $ctrl.sectionsOpenByCurId[$ctrl.courseUnitRealisation.id] = true;
        $ctrl.sectionsOpenedOnceByCurId[$ctrl.courseUnitRealisation.id] = true;
        _.forEach($ctrl.courseUnitRealisation.assessmentItemIds, assessmentItemId => {
          $ctrl.sectionsOpenByAssessmentItemId[assessmentItemId] = true;
        });
        $timeout(() => {
          if ($ctrl.courseUnitRealisation) {
            $anchorScroll($ctrl.courseUnitRealisation.id);
          }
        });
      }
    };

    /**
     * Returns true, if the student has enrolled to the given course unit realisation via some other
     * course unit than the one shown in this modal, or via some other assessment item than the given
     * one. It's enough that the enrolment is an initial one (i.e. realisation is added to calendar).
     */
    $ctrl.isEnrolledToFromAnotherEntity = function (courseUnitRealisation, assessmentItem) {
      const enrolment = _.get($ctrl.enrolmentForCourseUnitRealisations, courseUnitRealisation.id, null);
      return enrolment !== null && _.includes(['NOT_ENROLLED', 'PROCESSING', 'ENROLLED'], enrolment.state) && (enrolment.assessmentItemId !== assessmentItem.id || enrolment.courseUnitId !== $ctrl.courseUnit.id);
    };

    /**
     * Resolves the names of the course units and assessment items through which the given enrolments where made.
     */
    $ctrl.resolveEnrolmentSourceNames = function (enrolments) {
      if (_.isEmpty(enrolments)) {
        return;
      }
      const courseUnitIds = _.chain(enrolments).map('courseUnitId').uniq().filter(id => !_.isEmpty(id) && id !== $ctrl.courseUnit.id).value();
      const assessmentItemIds = _.chain(enrolments).map('assessmentItemId').uniq().value().filter(Boolean);
      $q.all([courseUnitService.findByIds(courseUnitIds), assessmentItemService.findByIds(assessmentItemIds)]).then(values => {
        const courseUnitsById = _.keyBy(values[0].concat($ctrl.courseUnit), 'id');
        const assessmentItemsById = _.keyBy(values[1], 'id');
        _.forEach(enrolments, enrolment => {
          const courseUnit = courseUnitsById[enrolment.courseUnitId];
          const assessmentItem = assessmentItemsById[enrolment.assessmentItemId];
          let name = '';
          if (courseUnit) {
            name = `${courseUnit.code} ${localeService.getLocalizedValue(courseUnit.name)}`;
          }
          if (assessmentItem) {
            const assessmentItemName = localeService.getLocalizedValue(assessmentItem.name);
            name = _.isEmpty(name) ? assessmentItemName : `${name}, ${assessmentItemName}`;
          }
          $ctrl.enrolmentSourceNamesByCurId[enrolment.courseUnitRealisationId] = name;
        });
      });
    };
  }
})();