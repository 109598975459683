/**
 * @format
 */

import { inject, Injectable } from '@angular/core';
import { PlanValidationResult, PlanValidationTs, ValidatablePlan } from 'common-typescript';
import {
    CourseUnit,
    Education,
    GradeScale,
    OtmId,
    Plan,
    PlanEducationOptions,
    StudyRight,
    UniversitySettings,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { combineLatest, distinctUntilChanged, forkJoin, from, map, Observable, of, shareReplay, switchMap, take } from 'rxjs';
import { COMMON_PLAN_SERVICE, PLAN_STUDY_RIGHT_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { GradeScaleEntityService } from 'sis-components/service/grade-scale-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { PlanStateAndData, PlanStateService } from 'sis-components/service/plan-state.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { UniversityService } from 'sis-components/service/university.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { StudentPlanOutdatedCourseUnitsService } from '../common/service/student-plan-outdated-course-units.service';

export interface PlanStructureData {
    allStudentPlans: Plan[];
    plan: Plan;
    allStudentStudyRights: StudyRight[];
    planStateAndData: PlanStateAndData;
    validatablePlan: ValidatablePlan;
    validatablePlanEducation: Education;
    planValidationResult: PlanValidationResult;
    matchingStudyRight: StudyRight;
    universitySettings: UniversitySettings;
    educationOptions: PlanEducationOptions;
    outDatedCourseUnitsInPlan: [CourseUnit, CourseUnit][];
}

@Injectable({
    providedIn: 'root',
})
export class PlanDataService {
    planEntityService = inject(PlanEntityService);
    appErrorHandler = inject(AppErrorHandler);
    studyRightEntityService = inject(StudyRightEntityService);
    planStateService = inject(PlanStateService);
    gradeScaleEntityService = inject(GradeScaleEntityService);
    universityService = inject(UniversityService);
    commonPlanService = inject(COMMON_PLAN_SERVICE);
    planStudyRightService = inject(PLAN_STUDY_RIGHT_SERVICE);
    studentPlanOutdatedCourseUnitsService = inject(StudentPlanOutdatedCourseUnitsService);

    createInputDataObservableFromPlanIdInput(planIdInput$: Observable<OtmId>): Observable<[string, Plan[], StudyRight[]]> {
        return this.createInputDataObservable(planIdInput$, this.createGetMyPlansObservable(), this.createStudyRightsObservable());
    }

    createInputDataObservable(
        planIdInput$: Observable<OtmId>,
        allStudentPlans$: Observable<Plan[]>,
        studyRights$: Observable<StudyRight[]>,
    ): Observable<[string, Plan[], StudyRight[]]> {
        return combineLatest([planIdInput$, allStudentPlans$, studyRights$]).pipe(
            distinctUntilChanged((current, previous) => {
                const [curPlanId, curPlans, curStudyRights] = current;
                const [prevPlanId, prevPlans, prevStudyRights] = previous;
                return curPlanId === prevPlanId && _.isEqual(curPlans, prevPlans) && _.isEqual(curStudyRights, prevStudyRights);
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    createDataObservable(inputData$: Observable<[string, Plan[], StudyRight[]]>): Observable<PlanStructureData> {
        const universitySettings$ = this.createUniversitySettingsObservable();
        return inputData$.pipe(
            map((data) => {
                const [planId, allStudentPlans, allStudentStudyRights] = data;
                const plan = this.findSelectedPlan(allStudentPlans, planId);
                const matchingStudyRight = this.getMatchingStudyRight(plan, allStudentStudyRights);
                return { plan, allStudentPlans, allStudentStudyRights, matchingStudyRight };
            }),
            switchMap((data) =>
                forkJoin({
                    validatablePlan: this.createValidatablePlanObservable(data.plan),
                    universitySettings: universitySettings$,
                }).pipe(map((result) => ({ ...data, ...result }))),
            ),
            switchMap((data) => {
                const { validatablePlan, matchingStudyRight } = data;
                const validatablePlanEducation = this.getValidatablePlanEducation(validatablePlan);
                const educationOptions = this.getEducationOptions(validatablePlan, validatablePlanEducation, matchingStudyRight);
                const planValidationResult = this.getPlanValidationResult(validatablePlan);
                return forkJoin({
                    validatablePlanEducation: of(validatablePlanEducation),
                    planValidationResult: of(planValidationResult),
                    educationOptions: of(educationOptions),
                    gradeScalesById: this.createGradeScalesByIdObservable(validatablePlan),
                    outDatedCourseUnitsInPlan: this.createOutDatedCourseUnitsInPlanObservable(validatablePlan),
                }).pipe(map((result) => ({ ...data, ...result })));
            }),
            map((data) => {
                const planStateAndData = this.getPlanStateAndData(
                    data.validatablePlanEducation,
                    data.validatablePlan,
                    data.planValidationResult,
                    data.educationOptions,
                    data.gradeScalesById,
                    data.matchingStudyRight,
                    data.outDatedCourseUnitsInPlan,
                );
                return { ...data, planStateAndData };
            }),
        );
    }

    createGetMyPlansObservable(): Observable<Plan[]> {
        return this.planEntityService
            .getMyPlans()
            .pipe(this.appErrorHandler.defaultErrorHandler(), shareReplay({ bufferSize: 1, refCount: true }));
    }

    findSelectedPlan(allStudentPlans: Plan[], planId: string): Plan {
        return allStudentPlans.find((plan) => plan.id === planId);
    }

    createValidatablePlanObservable(plan: Plan): Observable<ValidatablePlan> {
        return from(convertAJSPromiseToNative<ValidatablePlan>(this.commonPlanService.getValidatablePlan(_.cloneDeep(plan), false, false)));
    }

    getPlanValidationResult(validatablePlan: ValidatablePlan): PlanValidationResult {
        return PlanValidationTs.validatePlan(validatablePlan);
    }

    createStudyRightsObservable(): Observable<StudyRight[]> {
        return this.studyRightEntityService
            .getStudyRightsForCurrentUser()
            .pipe(this.appErrorHandler.defaultErrorHandler(), shareReplay({ bufferSize: 1, refCount: true }));
    }

    getMatchingStudyRight(plan: Plan, studyRights: StudyRight[]): StudyRight {
        return this.planStateService.getMatchingStudyRight(plan, studyRights);
    }

    getValidatablePlanEducation(validatablePlan: ValidatablePlan): Education {
        return validatablePlan.rootModule;
    }

    getEducationOptions(validatablePlan: ValidatablePlan, education: Education, studyRight: StudyRight): PlanEducationOptions {
        return this.planStudyRightService.getValidatedEducationOptions(validatablePlan, education, studyRight);
    }

    createGradeScalesByIdObservable(validatablePlan: ValidatablePlan) {
        return of(validatablePlan).pipe(
            map((vp) => _.chain(_.values(vp.getAllAttainments())).map('gradeScaleId').concat('sis-0-5').compact().uniq().value()),
            switchMap((gradeScaleIds) =>
                this.gradeScaleEntityService.getByIds(gradeScaleIds).pipe(take(1), this.appErrorHandler.defaultErrorHandler()),
            ),
            map((gradeScales) => _.keyBy(gradeScales, 'id')),
        );
    }

    getPlanStateAndData(
        education: Education,
        validatablePlan: ValidatablePlan,
        planValidationResult: PlanValidationResult,
        educationOptions: PlanEducationOptions,
        gradeScalesById: { [index: string]: GradeScale },
        matchingStudyRight: StudyRight,
        outDatedCourseUnitsInPlan: [CourseUnit, CourseUnit][],
    ): PlanStateAndData {
        return this.planStateService.getPlanStateAndData(
            education,
            validatablePlan,
            planValidationResult,
            educationOptions,
            gradeScalesById,
            matchingStudyRight,
            outDatedCourseUnitsInPlan,
        );
    }

    createUniversitySettingsObservable(): Observable<UniversitySettings> {
        return this.universityService
            .getCurrentUniversitySettings()
            .pipe(take(1), shareReplay({ bufferSize: 1, refCount: true }), this.appErrorHandler.defaultErrorHandler());
    }

    createOutDatedCourseUnitsInPlanObservable(validatablePlan: ValidatablePlan): Observable<[CourseUnit, CourseUnit][]> {
        return this.studentPlanOutdatedCourseUnitsService
            .checkLatestCourseUnitsAreInSelectedPlan(validatablePlan)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler());
    }
}
