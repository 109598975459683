<!-- @format -->

<ng-container *ngIf="this.service.query.select() | async as wizardData">
  <div class="container">
    <div class="row">
      <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
        <div class="page-title-container fake-crumbs">
          <h1 id="skip-link-target" tabindex="-1">
            {{ "STUDENT_APPLICATIONS.TYPE." + workflow.type | translate }}: {{ getWorkflowApplicationName(wizardData) }}
            <app-workflow-state-badge [state]="workflow.state"></app-workflow-state-badge>
          </h1>
        </div>
        <sis-code-panel [code]="this.workflow.code"></sis-code-panel>
        <sis-contextual-notification *ngIf="this.supplementRequestRationale$ | async as supplementRequestRationale" [variant]="'warning'">
          {{ "PROFILE.APPLICATIONS.SUPPLEMENT_REQUESTED_RATIONALE" | translate }}<br aria-hidden="true" />{{ supplementRequestRationale }}
        </sis-contextual-notification>
        <div class="form-group-mimic">
          <sis-wizard-progress [stepKeys]="wizardStepKeys" [currentStep]="currentStep()"></sis-wizard-progress>
        </div>
        <app-custom-attainment-basic-info-edit
          *ngIf="isFirstStep()"
          [workflowApplication]="getWorkflowApplication(wizardData)"
          [planId]="workflow.planId"
          (exit)="exit()"
          (continue)="continueAfterCustomAttainmentBasicInfoEdit($event)"
        >
        </app-custom-attainment-basic-info-edit>
        <app-prior-learning-and-attachments-edit
          *ngIf="currentStep() === 1"
          type="INCLUDE"
          (exit)="exit()"
          (previous)="previous()"
          (continue)="continue()"
        >
        </app-prior-learning-and-attachments-edit>
        <app-prior-learning-application-confirmation
          *ngIf="isLastStep()"
          [disableButtons]="uploading"
          (exit)="exit()"
          (previous)="previous()"
          (submit)="submit()"
        >
        </app-prior-learning-application-confirmation>
      </div>
    </div>
  </div>
</ng-container>
