import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LocalStorageSelectedLanguageService } from './local-storage-selected-language.service';

/**
 * TODO: Angular 12 supports returning Observable instead of Promise. Would be cleaner to use that here.
 */
function setInitialLanguage(localStorageSelectedLanguageService: LocalStorageSelectedLanguageService, translate: TranslateService) {
    return () => new Promise((resolve) => {
        const selectedLanguage = localStorageSelectedLanguageService.get();
        translate.use(selectedLanguage)
            .pipe(catchError((error) => throwError(() => error)))
            .subscribe(() => resolve(selectedLanguage));
    });
}

export const initialLanguageProvider = ({
    provide: APP_INITIALIZER,
    useFactory: setInitialLanguage, // Makes sure that initial language is loaded before app is initialized
    deps: [LocalStorageSelectedLanguageService, TranslateService],
    multi: true,
});
