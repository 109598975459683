/** @format */
import { ChangeDetectionStrategy, Component, DoCheck, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FudisSelectOption } from '@funidata/ngx-fudis/lib/types/forms';
import { TranslocoService } from '@ngneat/transloco';
import { PlanValidationResult, ValidatablePlan } from 'common-typescript';
import {
    AnyCourseUnitRule,
    CourseUnit,
    CourseUnitResultItem,
    CustomCourseUnitAttainment,
    CustomStudyDraft,
    EntityWithRule,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppErrorHandler } from '../../../error-handler/app-error-handler';
import { CreditRangePipe } from '../../../number/credit-range.pipe';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../../../plan/plan-actions-service/plan-actions.service';
import { ReadMoreModalService } from '../../../read-more-ng/read-more-modal.service';
import { ReadMoreValues } from '../../../read-more-ng/read-more-ng-modal/read-more-ng-modal.component';
import { CourseUnitEntityService, CourseUnitSearchQueryParams } from '../../../service/course-unit-entity.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';

interface CourseUnitSearchOptionType {
    value: CourseUnitResultItem;
    label: string;
    subLabel?: string;
}

interface TemplateSelectedToggleData {
    [id: string]: boolean;
}

@Component({
    selector: 'sis-plan-structure-any-course-unit-rule',
    templateUrl: './plan-structure-any-course-unit-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureAnyCourseUnitRuleComponent implements OnInit, DoCheck, OnDestroy {
    @Input({ required: true }) rule: AnyCourseUnitRule;
    @Input({ required: true }) parentModule: EntityWithRule;
    @Input({ required: true }) planValidationResult: PlanValidationResult;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Input({ required: true }) ruleDepthLevel: number;
    @Input({ required: true }) headingLevel: number;
    @Input({ required: true }) selectionUIState: 'ACTIVE' | 'DISABLED' | 'SELECTABLE' = 'ACTIVE';
    @Input({ required: true }) currentUniversityOrgId: OtmId;
    @Input({ required: true }) crossStudyFeaturesEnabled: boolean;
    @Input({ required: true }) cooperationNetworkIds: OtmId[];
    @Input({ required: true }) curriculumPeriodIds: OtmId[];

    courseUnits: CourseUnit[] = [];
    customCourseUnitAttainments: CustomCourseUnitAttainment[] = [];
    customStudyDrafts: CustomStudyDraft[] = [];
    templateSelectedToggleData: TemplateSelectedToggleData;
    courseUnitSelectControl = new FormControl<null | FudisSelectOption<CourseUnitResultItem>>(null);
    ripaStudiesIncluded = false;

    private destroy$ = new Subject<void>();
    protected searchTextUpdateSubject = new Subject<string | null>();
    protected searchResults = new BehaviorSubject<FudisSelectOption<CourseUnitSearchOptionType>[]>([]);

    constructor(
        private appErrorHandler: AppErrorHandler,
        private courseUnitEntityService: CourseUnitEntityService,
        private creditRangePipe: CreditRangePipe,
        private translocoService: TranslocoService,
        private readMoreModalService: ReadMoreModalService,
        @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
    ) {
        this.searchTextUpdateSubject
            .pipe(
                tap(() => this.searchResults?.next([])),
                filter((textQuery) => textQuery?.trim() !== ''),
                switchMap((textQuery) => this.searchCourseUnits(textQuery)),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    ngOnInit() {
        this.templateSelectedToggleData = {};
        const ruleValidationResult = _.get(this.planValidationResult.ruleValidationResults, [this.parentModule.id, this.rule.localId]);
        if (ruleValidationResult) {
            this.courseUnits = _.chain(ruleValidationResult.selectedCourseUnitsById)
                .values()
                .filter((courseUnit) => !this.validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnit))
                .value();
            this.customCourseUnitAttainments = _.values(ruleValidationResult.selectedCustomCourseUnitAttainmentsById);
            this.customStudyDrafts = _.values(ruleValidationResult.selectedCustomStudyDraftsById);
        }

        this.courseUnits.forEach((courseUnit) => {
            this.templateSelectedToggleData[courseUnit.id] = this.validatablePlan.isCourseUnitInPlan(courseUnit);
        });
        this.customCourseUnitAttainments.forEach((customCourseUnitAttainment) => {
            this.templateSelectedToggleData[customCourseUnitAttainment.id] =
                this.validatablePlan.isCustomCourseUnitAttainmentInPlan(customCourseUnitAttainment);
        });
        this.customStudyDrafts.forEach((customStudyDraft) => {
            this.templateSelectedToggleData[customStudyDraft.id] = true;
        });
    }

    ngDoCheck() {
        const ruleValidationResult = _.get(this.planValidationResult.ruleValidationResults, [this.parentModule.id, this.rule.localId]);
        if (ruleValidationResult) {
            const selectedCustomStudyDrafts = _.values(ruleValidationResult.selectedCustomStudyDraftsById);
            selectedCustomStudyDrafts?.forEach((customStudyDraft) => {
                if (this.templateSelectedToggleData[customStudyDraft.id] === undefined) {
                    this.templateSelectedToggleData[customStudyDraft.id] = true;
                    this.customStudyDrafts.push(customStudyDraft);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    handleCUSelectToggle(courseUnit: CourseUnit): void {
        const active = this.templateSelectedToggleData[courseUnit.id];
        const parentModule = this.parentModule;
        if (!active) {
            this.planActionsService?.selectCourseUnit?.(courseUnit, parentModule);
        } else {
            this.planActionsService?.removeCourseUnit?.(courseUnit, parentModule);
        }
        this.templateSelectedToggleData[courseUnit.id] = !active;
    }

    handleCCUASelectToggle(customCourseUnitAttainment: CustomCourseUnitAttainment): void {
        const active = this.templateSelectedToggleData[customCourseUnitAttainment.id];
        const parentModule = this.parentModule;
        if (!active) {
            this.planActionsService?.selectCustomCourseUnitAttainment?.(customCourseUnitAttainment, parentModule);
        } else {
            this.planActionsService?.removeCustomCourseUnitAttainmentById?.(customCourseUnitAttainment.id, parentModule);
        }
        this.templateSelectedToggleData[customCourseUnitAttainment.id] = !active;
    }

    handleCSDSelectToggle(customStudyDraft: CustomStudyDraft): void {
        const active = this.templateSelectedToggleData[customStudyDraft.id];
        const parentModule = this.parentModule;
        if (!active) {
            this.planActionsService?.addCustomStudyDraft?.(customStudyDraft, parentModule);
        } else {
            this.planActionsService?.removeCustomStudyDraft?.(customStudyDraft, parentModule);
        }
        this.templateSelectedToggleData[customStudyDraft.id] = !active;
    }

    openAddStudyDraftModal() {
        this.planActionsService.openCustomStudyDraftCreationModal(this.parentModule);
    }

    openReadMoreAboutStudyDraftModal() {
        this.readMoreModalService.open(this.getReadMoreOptions());
    }

    getReadMoreOptions(): ReadMoreValues {
        return {
            options: {
                contentHtml: null,
                contentTranslateKey: 'PLAN_EDIT.SELECTION_MODAL.ANY_COURSE_UNIT_RULE.CUSTOM_STUDY_DRAFT_READ_MORE_DESCRIPTION',
                contentTranslateParameters: null,
                title: this.translocoService.translate('PLAN_EDIT.SELECTION_MODAL.ANY_COURSE_UNIT_RULE.CUSTOM_STUDY_DRAFT_READ_MORE_TITLE'),
            },
        };
    }

    searchCourseUnits(textQuery: string): Observable<CourseUnitSearchOptionType[]> {
        const searchParameters = {
            fullTextQuery: textQuery,
            orgRootId: this.currentUniversityOrgId,
            curriculumPeriodId: this.curriculumPeriodIds,
            validity: 'ONGOING_AND_FUTURE',
            limit: 100,
            start: 0,
        };

        const cooperationNetworkParameters = this.ripaStudiesIncluded
            ? {
                  cooperationNetworkId: this.cooperationNetworkIds,
                  cooperationNetworkSearchType: 'COURSE_UNITS_COMBINED_WITH_COOPERATION_NETWORKS',
                  validityInCooperationNetwork: 'ONGOING',
              }
            : {};

        const queryParams = Object.assign(searchParameters, cooperationNetworkParameters) as CourseUnitSearchQueryParams;

        return this.courseUnitEntityService.searchActive(queryParams).pipe(
            map((courseUnits) =>
                courseUnits.searchResults.map((courseUnit) => ({
                    value: courseUnit,
                    label: `${courseUnit.name} (${this.creditRangePipe.transform(courseUnit.credits)})`,
                    subLabel: `${courseUnit.code} | ${this.translocoService.translate('SIS_COMPONENTS.STUDY.COURSE_UNIT')}`,
                })),
            ),
            tap((opts) => {
                this.searchResults?.next(opts);
            }),
            this.appErrorHandler.defaultErrorHandler(),
        );
    }

    toggleRipaStudiesForSearch = (ripaStudiesIncluded: boolean) => {
        this.ripaStudiesIncluded = ripaStudiesIncluded;
    };

    updateSelected(option: FudisSelectOption<CourseUnitSearchOptionType>): void {
        // TODO: add the selected option to the course units shown underneath the search
    }
}
