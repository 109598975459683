<!-- @format -->

<ng-container *ngIf="data$ | async as data" data-cy="'prior-learning-container'">
  <sis-expandable
    *ngIf="loggedIn && data.creditTransferVisible && (data.latestSubstitutionWorkflow || plan?.id)"
    [closed]="false"
    [level]="4"
    [title]="'COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.TITLE' | translate"
  >
    <ng-template sisExpandableCustomTitle>
      <sis-tiny-badge *ngIf="data.workflowBadgeVariant" [variant]="data.workflowBadgeVariant">
        {{ "STUDENT_APPLICATIONS.STATE." + data.latestSubstitutionWorkflow.state | translate }}
      </sis-tiny-badge>
    </ng-template>
    <ng-template sisExpandableContent>
      <div *ngIf="!data.validPlanAndStudyRight && !data.latestSubstitutionWorkflow" class="row">
        <div class="col-12">
          <p>{{ "COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.NO_STUDY_RIGHT" | translate }}</p>
        </div>
      </div>

      <div *ngIf="data.validPlanAndStudyRight || data.latestSubstitutionWorkflow">
        <div class="row">
          <div *ngIf="data?.latestSubstitutionWorkflow?.state === this.workflowState.ACCEPTED" class="col-12">
            <sis-attainment-workflow-decision-summary
              [workflowDecision]="data.latestSubstitutionWorkflow.decision"
              [workflowState]="data.latestSubstitutionWorkflow.state"
              [workflowType]="$any(data.latestSubstitutionWorkflow.type)"
            >
            </sis-attainment-workflow-decision-summary>
          </div>

          <div
            *ngIf="
              data?.latestSubstitutionWorkflow?.state === this.workflowState.REQUESTED ||
              data?.latestSubstitutionWorkflow?.state === this.workflowState.IN_HANDLING ||
              data?.latestSubstitutionWorkflow?.state === this.workflowState.SUPPLEMENT_REQUESTED
            "
            class="col-12"
          >
            <sis-contextual-notification [variant]="'warning'">{{
              "COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.ATTACHMENTS_REMINDER" | translate
            }}</sis-contextual-notification>
            <div class="mb-3">
              <sis-localized-markup-string-view [content]="courseUnit.inclusionApplicationInstruction"></sis-localized-markup-string-view>
            </div>
            <div *ngIf="(workflowCancelState$ | async) === cancelState.ACTIVE" class="cancel-box">
              <h5 class="header__size__md" [tabIndex]="0" sisAutofocus>{{
                "PROFILE.APPLICATIONS.CANCEL_APPLICATION.TITLE." + data.latestSubstitutionWorkflow.type | translate
              }}</h5>
              <p>{{ "PROFILE.APPLICATIONS.CANCEL_APPLICATION_MODAL.SUBTITLE" | translate }}</p>
              <div class="button-holder">
                <sis-button [variant]="'primary'" [size]="'lg'" [hollow]="true" (clicked)="workflowCancelState$.next(cancelState.INACTIVE)">
                  {{ "PROFILE.APPLICATIONS.CANCEL_APPLICATION.BUTTON.CANCEL" | translate }}
                </sis-button>
                <sis-button [variant]="'primary'" [size]="'lg'" (clicked)="cancelWorkflow(data.latestSubstitutionWorkflow)">
                  {{ "PROFILE.APPLICATIONS.CANCEL_APPLICATION.BUTTON.CONTINUE" | translate }}
                </sis-button>
              </div>
            </div>
            <sis-button
              #cancelConfirmToggleButton
              *ngIf="(workflowCancelState$ | async) === cancelState.INACTIVE"
              [id]="'cancelConfirmToggleButton'"
              [variant]="'primary'"
              [size]="'lg'"
              (clicked)="workflowCancelState$.next(cancelState.ACTIVE)"
            >
              {{ "COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.CANCEL_APPLICATION" | translate }}
            </sis-button>
            <div class="prior-learning-list">
              <sis-prior-learning-list
                [level]="5"
                [external-attained-studies]="$ctrl.externalAttainedStudiesForLatestWorkflow"
                [priorLearnings]="data.latestSubstitutionWorkflow.priorLearnings"
                [applicationType]="data.latestSubstitutionWorkflow.type"
              >
              </sis-prior-learning-list>
            </div>
          </div>

          <div *ngIf="data.latestSubstitutionWorkflow?.state === this.workflowState.REJECTED">
            <transloco
              key="{{ 'COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.APPLICATION_REJECTED' }}"
              [params]="{ id: data.latestSubstitutionWorkflow.code }"
            ></transloco>
            <div class="mb-3">
              <sis-localized-markup-string-view [content]="courseUnit.inclusionApplicationInstruction"></sis-localized-markup-string-view>
            </div>

            <sis-button [variant]="'primary'" [size]="'lg'" [hollow]="true" (clicked)="openApplication(data.latestSubstitutionWorkflow.id)">
              {{ "COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.MOVE_TO_REJECTED_APPLICATION" | translate }}
            </sis-button>
            <!-- Only allow creating new applications from plan -->
            <ng-container *ngIf="data.validPlanAndStudyRight">
              <sis-button [variant]="'primary'" [size]="'lg'" (clicked)="openSubstitutionForm(courseUnit.id, plan.id)">
                {{ "COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.CREATE_NEW_APPLICATION" | translate }}
              </sis-button>
            </ng-container>

            <div class="attainment-workflow-decision-summary">
              <sis-attainment-workflow-decision-summary
                [workflowDecision]="data.latestSubstitutionWorkflow.decision"
                [workflowState]="data.latestSubstitutionWorkflow.state"
                [workflowType]="$any(data.latestSubstitutionWorkflow.type)"
              >
              </sis-attainment-workflow-decision-summary>
            </div>
          </div>

          <div *ngIf="!data.latestSubstitutionWorkflow?.state && data.blockingWorkflowForOtherCourseUnit" class="col-12">
            <p class="sis-guidance">
              <ng-container *ngIf="data.blockingWorkflowForOtherCourseUnit.courseUnitId | courseUnit | async as courseUnit">
                {{
                  "COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.OTHER_VERSION_DESCRIPTION"
                    | translate
                      : {
                          version:
                            (courseUnit.name | localizedString) +
                            " " +
                            (courseUnit.curriculumPeriodIds | curriculumPeriodName: true | async),
                        }
                }}
              </ng-container>
            </p>
          </div>

          <div
            *ngIf="!data?.latestSubstitutionWorkflow?.state && !data.validCourseUnit && !data.blockingWorkflowForOtherCourseUnit"
            class="col-12"
          >
            <p class="sis-guidance"> {{ "COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.EXPIRED_DESCRIPTION" | translate }}</p>
          </div>

          <div
            *ngIf="
              (data?.latestSubstitutionWorkflow?.state === workflowState.CANCELLED ||
                data?.latestSubstitutionWorkflow?.state === workflowState.ACCEPTANCE_REVOKED) &&
              data.validPlanAndStudyRight
            "
            class="col-12"
          >
            <transloco
              key="{{ 'COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.DESCRIPTION' }}"
              [params]="{ currentUniversity: currentUniversity }"
            ></transloco>
            <div class="mb-3">
              <sis-localized-markup-string-view [content]="courseUnit.inclusionApplicationInstruction"></sis-localized-markup-string-view>
            </div>
            <sis-button [variant]="'primary'" [size]="'lg'" (clicked)="openSubstitutionForm(courseUnit.id, plan.id)">
              {{ "COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.MOVE_TO_APPLICATION" | translate }}
            </sis-button>
          </div>

          <div
            *ngIf="!data?.latestSubstitutionWorkflow?.state && data.validCourseUnit && !data.blockingWorkflowForOtherCourseUnit"
            class="col-12"
          >
            <transloco
              key="{{ 'COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.DESCRIPTION' }}"
              [params]="{ currentUniversity: currentUniversity }"
            ></transloco>
            <br />
            <br />
            <div class="mb-3">
              <sis-localized-markup-string-view [content]="courseUnit.inclusionApplicationInstruction"></sis-localized-markup-string-view>
            </div>
            <!-- Only allow creating new applications from plan -->
            <ng-container *ngIf="data.validPlanAndStudyRight">
              <sis-button [variant]="'primary'" [size]="'lg'" (clicked)="openSubstitutionForm(courseUnit.id, plan.id)">
                {{ "COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.MOVE_TO_APPLICATION" | translate }}
              </sis-button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </sis-expandable>
</ng-container>
