import { Component, EventEmitter, inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { CustomStudyDraft, OtmId } from 'common-typescript/types';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';

import {
    CreateCustomStudyDraftModalComponent,
} from '../../../common/components/plan-common/create-custom-study-draft-modal/create-custom-study-draft-modal.component';

@Component({
    selector: 'app-create-custom-study-draft-modal-wrapper',
    templateUrl: './create-custom-study-draft-modal-wrapper.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CreateCustomStudyDraftModalWrapperComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.plan.freeEdit.createCustomStudyDraftModalWrapper.downgraded',
        directiveName: 'appCreateCustomStudyDraftModalWrapper',
    };

    private dialogService = inject(FudisDialogService);

    @Input() moduleId: OtmId;
    @Output() customStudyDraftCreated = new EventEmitter<CustomStudyDraft>();

    createNewCustomStudyDraft() {
        this.dialogService.open(CreateCustomStudyDraftModalComponent, { data: { selectedModuleId: this.moduleId } })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.customStudyDraftCreated.emit(result);
                }
            });
    }

}
