<!-- @format -->

<ng-container *ngIf="this.stateService.query.select() | async as wizardData">
  <div class="row">
    <div class="col-12">
      <span class="guidance">{{ "PROFILE.APPLICATIONS.PRIOR_LEARNING.CONFIRMATION_INFO" | translate }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <sis-prior-learning-list
        [externalAttainedStudies]="wizardData?.selectedExternalAttainedStudies"
        [priorLearnings]="wizardData?.priorLearningWorkflowApplication?.priorLearnings"
        [applicationType]="wizardData?.priorLearningWorkflowApplication?.type"
      >
      </sis-prior-learning-list>
    </div>
  </div>
  <div *ngIf="customStudyDraft" class="row">
    <div class="col-12 col-md-12">
      <h3>{{ "PROFILE.APPLICATIONS.CUSTOM_STUDY_DRAFT_THAT_APPLICATION_IS_BASED_ON" | translate }}</h3>
      <div class="row">
        <div class="col-6 form-group-mimic">
          <h4>{{ "CUSTOM_STUDY_DRAFT.NAME" | translate }}</h4>
          <span>{{ customStudyDraft.name }}</span>
        </div>
        <div class="col-6 form-group-mimic">
          <h4>{{ "CUSTOM_STUDY_DRAFT.CREDITS" | translate }}</h4>
          <span>{{ customStudyDraft.credits }} {{ "CREDITS" | translate }}</span>
        </div>
        <div class="col-6 form-group-mimic">
          <h4>{{ "CUSTOM_STUDY_DRAFT.LOCATION" | translate }}</h4>
          <span>{{ customStudyDraft.location }}</span>
        </div>
        <div class="col-6 form-group-mimic">
          <h4>{{ "CUSTOM_STUDY_DRAFT.DESCRIPTION" | translate }}</h4>
          <span>{{ customStudyDraft.description }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 form-group">
      <app-attachments-preview
        [attachments]="wizardData.fileItems"
        [externalAttainedStudyAttachments]="getAttachedExternalAttainedStudyAttachments(wizardData)"
      ></app-attachments-preview>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h3>{{ "STUDENT_APPLICATIONS.GRADING_AND_DECISION" | translate }}</h3>
      <div class="form-group checkbox-group">
        <h4 [class]="getLabelState(mustPrintDecision)">{{ "PROFILE.APPLICATIONS.DECISION_AND_DELIVERY" | translate }}</h4>
        <p>{{ "PROFILE.APPLICATIONS.DECISION_AND_DELIVERY_INFO" | translate }}</p>
        <sis-radio-button [control]="mustPrintDecision" [value]="false" [label]="'PROFILE.APPLICATIONS.DECISION_DELIVERY_IN_SISU'">
        </sis-radio-button>
        <sis-radio-button [control]="mustPrintDecision" [value]="true" [label]="'PROFILE.APPLICATIONS.DECISION_DELIVERY_BY_MAIL'">
        </sis-radio-button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="confirmation-button-wrapper">
        <sis-button (clicked)="exit.emit()" [disabled]="disableButtons" naked="true">
          <sis-icon icon="back"></sis-icon>
          {{ "SIS_COMPONENTS.BUTTON.EXIT" | translate }}
        </sis-button>
        <sis-button (clicked)="previous.emit()" [disabled]="disableButtons" hollow="true">
          {{ "SIS_COMPONENTS.BUTTON.PREVIOUS" | translate }}
        </sis-button>
        <sis-button type="submit" (clicked)="onSubmit()" [disabled]="disableButtons">
          {{ "STUDENT_APPLICATIONS.SEND" | translate }}
        </sis-button>
      </div>
    </div>
  </div>
</ng-container>
