import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { ModuleInfoModule } from '../common/components/module-info/module-info.module';
import { PlanCommonModule } from '../common/components/plan-common/plan-common.module';

import { ChangeLearningOpportunityModalComponent } from './changeLearningOpportunityModal/changeLearningOpportunityModal.component';
import { ChangeLearningOpportunityModalDirective } from './changeLearningOpportunityModal/changeLearningOpportunityModal.dir.upgraded';
import { ApplyCustomStudyDraftCreditComponent } from './custom-study-draft-modal/apply-custom-study-draft-credit/apply-custom-study-draft-credit.component';
import { CustomStudyDraftInfoComponent } from './custom-study-draft-modal/custom-study-draft-info/custom-study-draft-info.component';
import {
    StudentCustomStudyDraftModalComponent,
} from './custom-study-draft-modal/custom-study-draft-modal.component';
import { CreateCustomStudyDraftModalWrapperComponent } from './freeEdit/create-custom-study-draft-modal-wrapper/create-custom-study-draft-modal-wrapper.component';
import { MinorSelectionModalComponent } from './minorSelectionModal/minorSelectionModal-ng.component';
import { MinorSelectionModalDirective } from './minorSelectionModal/minorSelectionModal.dir.upgraded';
import { PlanStudyRightModalModule } from './planStudyRightModal/plan-study-right-modal.module';
import { AnyCourseUnitRuleRipaCheckboxComponent } from './rules/any-course-unit-rule-ripa-checkbox/any-course-unit-rule-ripa-checkbox.component';
import { SelectCourseUnitAndCompletionMethodModalComponent } from './selectCourseUnitAndCompletionMethodModal.component';
import { SelectCourseUnitAndCompletionMethodModalDirective } from './selectCourseUnitAndCompletionMethodModal.dir.upgraded';
import { SelectEducationModalComponent } from './selectEducation/selectEducationModal.component';
import { SelectEducationModalDirective } from './selectEducation/selectEducationModal.dir.upgraded';

@NgModule({
    declarations: [
        ChangeLearningOpportunityModalDirective,
        ChangeLearningOpportunityModalComponent,
        MinorSelectionModalComponent,
        MinorSelectionModalDirective,
        SelectCourseUnitAndCompletionMethodModalComponent,
        SelectCourseUnitAndCompletionMethodModalDirective,
        SelectEducationModalComponent,
        SelectEducationModalDirective,
        StudentCustomStudyDraftModalComponent,
        AnyCourseUnitRuleRipaCheckboxComponent,
        CustomStudyDraftInfoComponent,
        ApplyCustomStudyDraftCreditComponent,
        CreateCustomStudyDraftModalWrapperComponent,
    ],
    imports: [
        PlanCommonModule,
        CommonModule,
        SisCommonModule,
        SisComponentsModule,
        ModuleInfoModule,
        PlanStudyRightModalModule,
        TranslateModule.forChild(),
        ReactiveFormsModule,
        NgxFudisModule,
    ],
})
export class PlanModule {}
