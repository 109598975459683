/**
 * @format
 */
import { inject, Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { ExternalAttainedStudy, ExternalAttainedStudyAttachments, PriorLearningWorkflowApplication } from 'common-typescript/types';
import { FileItem } from 'sis-components/file-upload/file-upload.component';

export interface PriorLearningWorkflowApplicationWizardData {
    fileItems: FileItem[];
    priorLearningWorkflowApplication: PriorLearningWorkflowApplication;
    fetchedExternalAttainedStudies: ExternalAttainedStudy[];
    fetchedExternalAttainedStudyAttachments: ExternalAttainedStudyAttachments[];
    selectedExternalAttainedStudies: ExternalAttainedStudy[];
}

export function createWizardStateService(): {
    store: PriorLearningWorkflowApplicationWizardStore;
    query: PriorLearningWorkflowApplicationWizardQuery;
} {
    const store = inject(PriorLearningWorkflowApplicationWizardStore);
    const query = new PriorLearningWorkflowApplicationWizardQuery(store);
    return {
        store,
        query,
    };
}

export function getAttachedExternalAttainedStudyAttachments(data: PriorLearningWorkflowApplicationWizardData) {
    return (
        data.selectedExternalAttainedStudies
            ?.map((study) =>
                data.fetchedExternalAttainedStudyAttachments?.find((attachments) => attachments.externalAttainedStudyId === study.id),
            )
            .filter((attachments) => attachments !== null && attachments !== undefined) || []
    );
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'priorLearningWorkflowApplicationWizard' })
export class PriorLearningWorkflowApplicationWizardStore extends Store<PriorLearningWorkflowApplicationWizardData> {
    constructor() {
        super({});
    }
}

export class PriorLearningWorkflowApplicationWizardQuery extends Query<PriorLearningWorkflowApplicationWizardData> {
    constructor(protected store: PriorLearningWorkflowApplicationWizardStore) {
        super(store);
    }
}
