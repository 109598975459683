/**
 * @format
 */
import { ChangeDetectionStrategy, Component, Input, OnInit, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { StateService } from '@uirouter/angular';
import {
    ApplicationAttachments,
    Attachment,
    PriorLearningSubstitutionWorkflow,
    PriorLearningSubstitutionWorkflowApplication,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { FileItem } from 'sis-components/file-upload/file-upload.component';
import { ApplicationAttachmentEntityService } from 'sis-components/service/application-attachment-entity.service';
import { WorkflowDataChangeService } from 'sis-components/service/workflow-data-change.service';
import { ApplicationCreationService } from '../../../../common/service/application-creation.service';
import { createWizardStateService } from '../store/prior-learning-workflow-application-wizard-utils';

@Component({
    selector: 'app-prior-learning-substitution-workflow-supplement-wizard',
    templateUrl: './prior-learning-substitution-workflow-supplement-wizard.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorLearningSubstitutionWorkflowSupplementWizardComponent implements OnInit {
    @Input() workflow: PriorLearningSubstitutionWorkflow;

    readonly wizardStepKeys = [
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.SUPPLEMENT.SUBSTITUTION_PHASE_1',
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.SUPPLEMENT.SUBSTITUTION_PHASE_2',
    ];

    currentStep: WritableSignal<number> = signal(0);

    service = createWizardStateService();
    uploading = false;
    supplementRequestRationale$: Observable<string>;
    private originalApplicationAttachments: ApplicationAttachments;

    constructor(
        private applicationCreationService: ApplicationCreationService,
        private appErrorHandler: AppErrorHandler,
        private applicationAttachmentEntityService: ApplicationAttachmentEntityService,
        private workflowDataChangeService: WorkflowDataChangeService,
        private state: StateService,
    ) {}

    ngOnInit(): void {
        this.service.store.update((_state) => ({
            priorLearningWorkflowApplication: this.workflow?.application as PriorLearningSubstitutionWorkflowApplication,
            selectedExternalAttainedStudies: [],
            fetchedExternalAttainedStudies: [],
            fetchedExternalAttainedStudyAttachments: [],
        }));

        this.applicationAttachmentEntityService
            .getAttachmentsByApplicationId(this.workflow.id)
            .pipe(
                catchError((err) => {
                    // Expect 404 if the workflow has no attachments, pass other errors to default handler
                    if (err.status === 404) {
                        return of(undefined);
                    }
                    return throwError(() => err);
                }),
                this.appErrorHandler.defaultErrorHandler(),
                map((applicationAttachments: ApplicationAttachments) => {
                    this.originalApplicationAttachments = applicationAttachments;
                    if (applicationAttachments?.attachments) {
                        return this.getFileItemsFromAttachments(applicationAttachments.attachments);
                    }
                    return [];
                }),
                tap((fileItems) => {
                    this.service.store.update((state) => ({
                        ...state,
                        fileItems,
                    }));
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();
        this.supplementRequestRationale$ = this.workflowDataChangeService
            .getActiveSupplementRequestDescription(this.workflow.id)
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }

    getFileItemsFromAttachments(attachments: Attachment[]): FileItem[] {
        const fileItems: FileItem[] = [];
        _.each(attachments, (attachment) => {
            const file = new File([new ArrayBuffer(attachment.size)], attachment.name, { type: attachment.fileType });
            fileItems.push({
                file,
                explanation: attachment.comment,
                preSignedGetUrl: attachment.preSignedGetUrl,
                localId: attachment.localId,
                name: file.name.normalize(),
            });
        });
        return fileItems;
    }

    isFirstStep(): boolean {
        return this.currentStep() === 0;
    }

    isLastStep(): boolean {
        return this.currentStep() === this.wizardStepKeys.length - 1;
    }

    exit(): void {
        this.state.go('^');
    }

    previous(): void {
        if (!this.isFirstStep()) {
            this.currentStep.set(this.currentStep() - 1);
        }
    }

    continue(): void {
        if (!this.isLastStep()) {
            this.currentStep.set(this.currentStep() + 1);
        }
    }

    submit() {
        if (!this.uploading) {
            const wizardData = this.service.query.getValue();

            this.uploading = true;
            this.applicationCreationService
                .supplementWorkflowApplication(
                    this.workflow.id,
                    this.workflow.studentId,
                    wizardData.priorLearningWorkflowApplication,
                    wizardData.fileItems,
                    this.originalApplicationAttachments?.attachments,
                    this.originalApplicationAttachments?.metadata,
                )
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                    finalize(() => (this.uploading = false)),
                )
                .subscribe({
                    next: () => {
                        this.state.go('^', {}, { custom: { skipConfirmationDialog: true }, reload: true });
                    },
                });
        }
    }
}
