<!-- @format -->

<h3>{{ "FILE_UPLOAD.FILE_TITLE" | translate }}</h3>
<span class="guidance" *ngIf="!(attachments?.length > 0) && currentExternalAttachments.length === 0">{{
  "FILE_UPLOAD.ZERO_FILES" | translate
}}</span>
<sis-emrex-attachments [showLink]="false" [externalAttainedStudyAttachments]="externalAttainedStudyAttachments"></sis-emrex-attachments>
<div *ngFor="let attachment of attachments" class="file-preview">
  <span class="file-name">{{ attachment.file.name }} ({{ readableFileSizeString(attachment.file.size) }})</span>
  <p class="file-comment">{{ attachment.explanation }}</p>
</div>
