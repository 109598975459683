/**
 * @format
 */
import { Component, EventEmitter, inject, Input, OnInit, Output, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { ExternalAttainedStudy, PriorLearningWorkflow } from 'common-typescript/types';
import { take, tap } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { STUDENT_WORKFLOW_STATE, STUDENT_WORKFLOW_TYPE } from 'sis-components/model/student-workflow-constants';
import { ExternalAttainedStudyService } from 'sis-components/service/external-attained-study.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-prior-learning-workflow-content',
    templateUrl: './prior-learning-workflow-content.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorLearningWorkflowContentComponent implements OnInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.profile.application.prior-learning-workflow-content',
        directiveName: 'appPriorLearningWorkflowContent',
    };

    readonly studentWorkflowState = STUDENT_WORKFLOW_STATE;
    readonly studentWorkflowType = STUDENT_WORKFLOW_TYPE;

    @Input() priorLearningWorkflow: PriorLearningWorkflow;
    @Output() openCourseUnitInfoModal = new EventEmitter();

    externalAttainedStudyService = inject(ExternalAttainedStudyService);
    appErrorHandler = inject(AppErrorHandler);
    externalAttainedStudies: WritableSignal<ExternalAttainedStudy[]> = signal([]);

    ngOnInit() {
        this.externalAttainedStudyService
            .getExternalAttainedStudyByWorkflowId(this.priorLearningWorkflow.id)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
                tap((studies) => this.externalAttainedStudies.set(studies)),
            )
            .subscribe();
    }
}
