/** @format */
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { numberUtils } from 'common-typescript';
import { ExternalAttainedStudyAttachments, ExternalAttainedStudyDocument } from 'common-typescript/types';
import { Observable } from 'rxjs';

@Component({
    selector: 'sis-emrex-attachments',
    templateUrl: './emrex-attachments.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EmrexAttachmentsComponent {
    @Input() externalAttainedStudyAttachments: ExternalAttainedStudyAttachments[];
    @Input() showLink: boolean = false;

    externalAttachments$: Observable<ExternalAttainedStudyAttachments[]>;

    getAttachmentLanguages(document: ExternalAttainedStudyDocument): string[] {
        return Object.keys(document.attachments);
    }

    readableFileSizeString(number: any) {
        return numberUtils.readableFileSizeString(number);
    }

    getDescriptionForLang(document: ExternalAttainedStudyDocument, lang: string): string {
        return !!document.description ? document.description[lang] : '';
    }
}
