/**
 * @format
 */
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MaxLength } from 'common-typescript/constants';
import {
    CustomStudyDraft,
    ExternalAttainedStudy,
    ExternalAttainedStudyAttachments,
    LocalDateString,
    OtmId,
    PriorCompetence,
    PriorLearning,
    PriorStudies,
    UniversitySettings,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable, of, switchMap } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { FileItem } from 'sis-components/file-upload/file-upload.component';
import { maxLength, required } from 'sis-components/form/form-validators';
import { getLocalDateEditorValue, getLocalDateRangeEditorValue, subtractDayFromEndDate } from 'sis-components/form/formUtils';
import { LocalDateRangeForm, SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { PRIOR_LEARNING_TYPE } from 'sis-components/model/student-application-constants';
import { ExternalAttainedStudyAttachmentService } from 'sis-components/service/external-attained-study-attachment.service';
import { ExternalAttainedStudyService } from 'sis-components/service/external-attained-study.service';
import { UniversityService } from 'sis-components/service/university.service';
import { AddPriorLearningModalComponent } from '../add-prior-learning-modal/add-prior-learning-modal.component';
import {
    createWizardStateService,
    getAttachedExternalAttainedStudyAttachments,
} from '../store/prior-learning-workflow-application-wizard-utils';

interface PriorLearningCommonControls {
    attainmentLanguage: FormControl;
    description: FormControl;
    name: FormControl;
    organisation: FormControl;
    type: FormControl;
}

interface PriorCompetenceControls extends PriorLearningCommonControls {
    attainmentPeriod: FormGroup<LocalDateRangeForm>;
}

interface PriorStudiesControls extends PriorLearningCommonControls {
    attainmentDate: FormControl<LocalDateString>;
    code: FormControl;
    credits: FormControl;
    externalAttainedStudyId: FormControl<OtmId | null>;
    grade: FormControl;
    gradeScale: FormControl;
}

@Component({
    selector: 'app-prior-learning-and-attachments-edit',
    templateUrl: './prior-learning-and-attachments-edit.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorLearningAndAttachmentsEditComponent implements OnInit {
    /**
     * Determines the naming of the prior learning entries (depending on the type of the application).
     */
    @Input() type: 'INCLUDE' | 'SUBSTITUTE';
    @Input() customStudyDraft?: CustomStudyDraft;
    /**
     * Initializes the form with the given prior learnings.
     */
    @Input() hidePreviousButton: boolean;
    @Output() exit = new EventEmitter<void>();
    @Output() previous = new EventEmitter<void>();
    @Output() continue = new EventEmitter<void>();

    stateService = createWizardStateService();

    changeDetector = inject(ChangeDetectorRef);

    form: FormGroup<{ priorLearnings: FormArray<FormGroup<PriorStudiesControls | PriorCompetenceControls>> }>;
    externalAttainedStudies$: Observable<ExternalAttainedStudy[]>;
    emrexIntegrationEnabled: boolean = false;

    constructor(
        private alertsService: AlertsService,
        private confirmDialogService: ConfirmDialogService,
        private fb: SisFormBuilder,
        private modalService: ModalService,
        private translate: TranslateService,
        private appErrorHandler: AppErrorHandler,
        private externalAttainedStudyService: ExternalAttainedStudyService,
        private externalAttainedStudyAttachmentService: ExternalAttainedStudyAttachmentService,
        private universityService: UniversityService,
    ) {
        this.addPriorLearningControls = this.addPriorLearningControls.bind(this);
    }

    ngOnInit(): void {
        const priorLearnings = this.stateService.query.getValue()?.priorLearningWorkflowApplication?.priorLearnings;
        this.form = this.fb.group({
            priorLearnings: this.fb.array<FormGroup<PriorStudiesControls | PriorCompetenceControls>>(
                priorLearnings ? priorLearnings.map((pl) => this.initializeFormGroupFromPriorLearning(pl)) : [],
                required(),
            ),
        });

        this.externalAttainedStudies$ = this.universityService.getCurrentUniversitySettings().pipe(
            take(1),
            this.appErrorHandler.defaultErrorHandler(),
            switchMap((settings: UniversitySettings) => {
                this.emrexIntegrationEnabled = _.get(settings, 'frontendFeatureToggles.emrexIntegrationEnabled', false);

                if (this.emrexIntegrationEnabled) {
                    return this.externalAttainedStudyService.getExternalAttainedStudies().pipe(
                        take(1),
                        tap((externalAttainedStudies: ExternalAttainedStudy[]) => externalAttainedStudies),
                        this.appErrorHandler.defaultErrorHandler(),
                    );
                }
                return of([] as ExternalAttainedStudy[]);
            }),
            tap((studies) => {
                this.stateService.store.update((state) => ({
                    ...state,
                    fetchedExternalAttainedStudies: studies,
                }));
            }),
        );
    }

    addPriorLearning(): void {
        this.modalService.open(AddPriorLearningModalComponent, null, { size: 'sm' }).closed.subscribe(this.addPriorLearningControls);
    }

    createPriorLearningForm(type: PRIOR_LEARNING_TYPE): FormGroup<PriorCompetenceControls> | FormGroup<PriorStudiesControls> {
        const priorLearningCommonControls = {
            attainmentLanguage: this.fb.control(null, [required()]),
            description: this.fb.control(null, [required(), maxLength(MaxLength.MAX_LONG_STRING_LENGTH)]),
            name: this.fb.control(null, [required(), maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)]),
            organisation: this.fb.control(null, [required(), maxLength(MaxLength.MAX_MEDIUM_STRING_LENGTH)]),
        };
        if (type === PRIOR_LEARNING_TYPE.COMPETENCE) {
            return this.fb.group<PriorCompetenceControls>({
                type: this.fb.control('COMPETENCE'),
                attainmentPeriod: this.fb.localDateRange(null, { required: true }),
                ...priorLearningCommonControls,
            });
        }
        if (type === PRIOR_LEARNING_TYPE.STUDIES) {
            return this.fb.group<PriorStudiesControls>({
                type: this.fb.control('STUDIES'),
                attainmentDate: this.fb.localDate(null, { required: true }),
                code: this.fb.control(null, [maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]),
                credits: this.fb.control(null, [required(), maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]),
                externalAttainedStudyId: this.fb.control(null),
                grade: this.fb.control(null, [required(), maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]),
                gradeScale: this.fb.control(null, [required(), maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]),
                ...priorLearningCommonControls,
            });
        }
        return null;
    }

    initializeFormGroupFromPriorLearning(priorLearning: PriorLearning): FormGroup {
        const priorLearningForm = this.createPriorLearningForm(priorLearning.type as PRIOR_LEARNING_TYPE);
        if (priorLearning.type === 'STUDIES') {
            const priorStudies = priorLearning as PriorStudies;
            priorLearningForm.patchValue({
                ...priorStudies,
                attainmentDate: this.fb.formatLocalDate(priorStudies.attainmentDate),
            });
        }
        if (priorLearning.type === 'COMPETENCE') {
            const priorCompetence = priorLearning as PriorCompetence;
            priorLearningForm.patchValue({
                ...priorCompetence,
                attainmentPeriod: {
                    startDate: this.fb.formatLocalDate(priorCompetence.attainmentPeriod.startDate),
                    endDate: this.fb.formatLocalDate(subtractDayFromEndDate(priorCompetence.attainmentPeriod.endDate)),
                },
            });
        }
        return priorLearningForm;
    }

    initializeFormGroupFromCustomStudyDraft(type: PRIOR_LEARNING_TYPE): FormGroup {
        const priorLearningForm = this.createPriorLearningForm(type);
        priorLearningForm.patchValue({
            description: this.customStudyDraft.description,
            name: this.customStudyDraft.name,
            organisation: this.customStudyDraft.location,
        });
        if (type === 'STUDIES') {
            priorLearningForm.patchValue({
                credits: `${this.customStudyDraft.credits ?? 0} ${this.translate.instant('CREDITS')}`,
            });
        }
        return priorLearningForm;
    }

    addPriorLearningControls(type: PRIOR_LEARNING_TYPE): void {
        // If creating the application based on a custom study draft, init the first prior learning entry with the data from the draft
        if (this.customStudyDraft && this.priorLearnings.length === 0) {
            this.priorLearnings.push(this.initializeFormGroupFromCustomStudyDraft(type));
        } else {
            this.priorLearnings.push(this.createPriorLearningForm(type));
        }
        this.changeDetector.markForCheck();
    }

    getPriorLearningEditorTitle(control: AbstractControl, index: number): string {
        const prefix = this.priorLearnings.length > 1 ? `${index + 1}. ` : '';
        const title = this.translate.instant(`STUDENT_APPLICATIONS.PRIOR_LEARNING.${this.type}_${control?.get('type')?.value}.TITLE_EDIT`);
        return `${prefix}${title}`;
    }

    isPriorCompetenceFormGroup(control: AbstractControl): boolean {
        return control?.get('type')?.value === PRIOR_LEARNING_TYPE.COMPETENCE;
    }

    isPriorStudiesFormGroup(control: AbstractControl): boolean {
        return control?.get('type')?.value === PRIOR_LEARNING_TYPE.STUDIES;
    }

    onDeletePriorLearning(index: number): void {
        if (index >= 0 && index < this.priorLearnings.length) {
            if (this.priorLearnings.at(index)?.pristine) {
                this.priorLearnings.removeAt(index);
            } else {
                this.confirmDialogService
                    .confirm({
                        title: 'STUDENT_APPLICATIONS.PRIOR_LEARNING.REMOVE_PRIOR_LEARNING_TITLE',
                        description: 'STUDENT_APPLICATIONS.PRIOR_LEARNING.REMOVE_PRIOR_LEARNING_DESCRIPTION',
                    })
                    .then(() => {
                        this.priorLearnings.removeAt(index);
                    })
                    .catch(() => {});
            }
        }
    }

    previousClicked() {
        this.updateState();
        this.previous.emit();
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.updateState();
            this.continue.emit();
        } else if (this.priorLearnings.length === 0) {
            this.alertsService.addAlert({
                type: AlertType.DANGER,
                message: this.translate.instant('PROFILE.APPLICATIONS.PRIOR_LEARNING.PRIOR_LEARNING_REQUIRED'),
            });
        } else {
            this.form.markAllAsTouched();
            this.alertsService.addFormSubmissionFailureAlert();
        }
    }

    get priorLearnings(): FormArray<FormGroup<PriorStudiesControls> | FormGroup<PriorCompetenceControls>> {
        return this.form.get('priorLearnings') as FormArray;
    }

    private mapFormValueToDataModel(): PriorLearning[] {
        return this.priorLearnings.controls.map((formGroup: FormGroup) =>
            this.isPriorStudiesFormGroup(formGroup)
                ? {
                      ...formGroup.value,
                      attainmentDate: getLocalDateEditorValue(formGroup.get('attainmentDate') as FormControl),
                  }
                : {
                      ...formGroup.value,
                      attainmentPeriod: getLocalDateRangeEditorValue(formGroup.get('attainmentPeriod') as FormGroup),
                  },
        );
    }

    onSelectExternalAttainedStudy($event: ExternalAttainedStudy): void {
        if (!!$event) {
            if (
                !this.stateService.store
                    .getValue()
                    ?.fetchedExternalAttainedStudyAttachments?.some((attachments) => attachments.externalAttainedStudyId === $event.id)
            ) {
                this.externalAttainedStudyAttachmentService
                    .getExternalAttainedStudyAttachments($event.id)
                    .pipe(
                        take(1),
                        this.appErrorHandler.defaultErrorHandler(),
                        tap((externalAttainedStudyAttachments) => {
                            this.selectExternalAttainedStudyAttachments(externalAttainedStudyAttachments);
                        }),
                    )
                    .subscribe();
            }
        }
    }

    private selectExternalAttainedStudyAttachments(externalAttainedStudyAttachments: ExternalAttainedStudyAttachments) {
        const fetchedAttachments = this.stateService.store.getValue()?.fetchedExternalAttainedStudyAttachments;
        const updatedAttachments = [
            ...fetchedAttachments.filter(
                (attachments) => attachments.externalAttainedStudyId !== externalAttainedStudyAttachments.externalAttainedStudyId,
            ),
            externalAttainedStudyAttachments,
        ];
        this.stateService.store.update((state) => ({
            ...state,
            fetchedExternalAttainedStudyAttachments: updatedAttachments,
        }));
    }

    private updateState() {
        const priorLearnings = this.mapFormValueToDataModel();
        const application = this.stateService.store.getValue()?.priorLearningWorkflowApplication;
        const fetchedExternalAttainedStudies = this.stateService.query.getValue().fetchedExternalAttainedStudies;
        const selectedExternalAttainedStudies = fetchedExternalAttainedStudies.filter((study) =>
            priorLearnings.some((priorLearing) => (priorLearing as PriorStudies)?.externalAttainedStudyId === study.id),
        );
        this.stateService.store.update((state) => ({
            ...state,
            selectedExternalAttainedStudies,
            priorLearningWorkflowApplication: { ...application, priorLearnings },
        }));
    }

    updateAttachments($event: FileItem[]) {
        this.stateService.store.update((state) => ({
            ...state,
            fileItems: $event,
        }));
    }

    protected readonly getAttachedExternalAttainedStudyAttachments = getAttachedExternalAttainedStudyAttachments;
}
