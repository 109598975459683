import angular from 'angular';
import angularTranslate from 'angular-translate';
import moment from 'moment';
import '../translate/translations';
import { configModule } from '../config/config.module.ts';
import './localeLoaderService';
export const localeServiceModule = 'sis-common.l10n.localeService';
(function () {
  translationsConfig.$inject = ["$translateProvider", "translateLocales", "sisCommonTranslationsBundled", "localStorageSelectedLanguageProvider"];
  translateAsyncLoaderService.$inject = ["$q", "appTranslationsAsync", "localeLoaderService"];
  localeService.$inject = ["$translate", "$rootScope", "configService", "$q", "tmhDynamicLocale", "translateLocales"];
  angular.module(localeServiceModule, [angularTranslate, configModule, 'sis-common.l10n.localeLoaderService', 'sis-common.translations', 'app.common.translations']).factory('localeService', localeService).service('translateAsyncLoaderService', translateAsyncLoaderService).constant('translateLocales', ['fi', 'en', 'sv']) // In order of preference
  .provider('localStorageSelectedLanguage', localStorageSelectedLanguageProvider).config(translationsConfig);

  /**
   * @ngInject
   */
  function localeService($translate, $rootScope, configService, $q, tmhDynamicLocale, translateLocales) {
    var config = configService.get();
    var localeLocalizedNames = {
      fi: {
        fi: 'suomi',
        sv: 'finska',
        en: 'Finnish'
      },
      en: {
        fi: 'englanti',
        sv: 'engelska',
        en: 'English'
      },
      sv: {
        fi: 'ruotsi',
        sv: 'svenska',
        en: 'Swedish'
      }
    };
    var localizedAnd = {
      fi: 'ja',
      en: 'and',
      sv: 'och'
    };
    var localeNames = _.reduce(translateLocales, function (hash, locale) {
      var name = localeLocalizedNames[locale][locale];
      hash[locale] = name;
      return hash;
    }, {});
    $rootScope.$on('$translateChangeSuccess', () => {
      const language = $translate.use();
      tmhDynamicLocale.set(language);
    });
    var api = {
      supportedLocales: config.officialLanguages,
      getLocaleOrder: function () {
        return translateLocales;
      },
      getCurrentLocale: function () {
        return $translate.use();
      },
      getLocalizedArrayValue: function (localizedStringArray, localeOverride) {
        var strings = _.map(localizedStringArray, function (localizedString) {
          return getLocalizedValue(localizedString, localeOverride);
        });
        switch (strings.length) {
          case 0:
            return '';
          case 1:
            return strings[0];
          default:
            return _.join(strings.slice(0, strings.length - 1), ", ") + ` ${localizedAnd[localeOverride || api.getCurrentLocale()]} ${strings[strings.length - 1]}`;
        }
      },
      concatLocalized: concatLocalized,
      getLocalizedValue: getLocalizedValue,
      getSupportedLocales: getOfficialLanguages,
      getLocaleLocalizedNames: function (locales) {
        if (_.isNil(locales)) {
          return _.clone(localeLocalizedNames);
        } else {
          return _.map(locales, function (locale) {
            return localeLocalizedNames[locale];
          });
        }
      }
    };
    function getOfficialLanguages() {
      return $q.when(config.officialLanguages);
    }
    function getLocalizedValue(localizedString, localeOverride) {
      if (!localizedString) {
        return undefined;
      }
      const preferredLocale = localeOverride || api.getCurrentLocale();
      const locales = _.uniq([preferredLocale, ...translateLocales]);
      const localeWithTranslation = _.find(locales, locale => localizedString[locale]);
      return localeWithTranslation ? localizedString[localeWithTranslation] : undefined;
    }
    function localizedValueIfMap(localizedStringOrValue, localeOverride) {
      if (_.isString(localizedStringOrValue)) {
        return localizedStringOrValue;
      } else {
        return getLocalizedValue(localizedStringOrValue, localeOverride);
      }
    }

    /**
     * Concatenate localized string to other string. All locale versions
     * are set even, therefore this should only be used when displaying results.
     */
    function concatLocalized() {
      var localizedResult = {};
      if (_.isEmpty(arguments)) {
        return {};
      }
      var args = _.values(arguments);
      _.forEach(translateLocales, function (locale) {
        localizedResult[locale] = _.chain(_.values(args)).map(function (arg) {
          return localizedValueIfMap(arg, locale);
        }).join('').value();
      });
      return localizedResult;
    }
    return api;
  }

  /**
   * @ngInject
   */
  function localStorageSelectedLanguageProvider() {
    const selectedLanguageLocalStorageKey = 'selected_language';
    this.$get = () => this;
    this.get = function () {
      const {
        officialLanguages
      } = universityConfig;
      const storedLanguage = localStorage.getItem(selectedLanguageLocalStorageKey);
      if (storedLanguage && officialLanguages.includes(storedLanguage)) {
        return storedLanguage;
      }
      return officialLanguages[0];
    };
  }

  /**
   * @ngInject
   */
  function translateAsyncLoaderService($q, appTranslationsAsync, localeLoaderService) {
    return options => $q.all([$q.all(localeLoaderService.loadLocales(options.key)), $q.all(appTranslationsAsync(options.key)).then(translations => _.merge({}, ...translations))]);
  }

  /**
   * @ngInject
   */
  function translationsConfig($translateProvider, translateLocales, sisCommonTranslationsBundled, localStorageSelectedLanguageProvider) {
    /*
     * https://angular-translate.github.io/docs/#/guide/19_security.
     * 'sanitize' / 'sanitizeParameters' fails with UTF8 characters: https://github.com/angular-translate/angular-translate/issues/1101.
     * 'escape' would not allow HTML formatting in translations.
     * -> use 'escapeParameters' to prevents XSS.
     */
    $translateProvider.useSanitizeValueStrategy('escapeParameters');

    // Bundled translations
    translateLocales.forEach(locale => $translateProvider.translations(locale, sisCommonTranslationsBundled[locale]));
    $translateProvider.preferredLanguage(localStorageSelectedLanguageProvider.get());
    $translateProvider.use(localStorageSelectedLanguageProvider.get());

    // Dynamically loaded translations
    $translateProvider.registerAvailableLanguageKeys(translateLocales, {
      'fi_*': 'fi',
      'en_*': 'en',
      'sv_*': 'sv',
      '*': 'fi'
    });
    $translateProvider.useLoader('translateAsyncLoaderService');
    $translateProvider.forceAsyncReload(true);
  }
})();