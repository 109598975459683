import { Injectable } from '@angular/core';
import { CustomStudyDraft } from 'common-typescript/types';
import _ from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class PlanDiffService {

    hasUnselectedCustomStudyDrafts(allCustomStudyDrafts: CustomStudyDraft[], selectedCustomStudyDrafts: CustomStudyDraft[]): boolean {
        const allCustomStudyDraftIds = _.map(allCustomStudyDrafts, 'id');
        const selectedCustomStudyDraftIds = _.map(selectedCustomStudyDrafts, 'id');
        const unselectedCustomStudyDrafts = _.pullAll(allCustomStudyDraftIds, selectedCustomStudyDraftIds);
        return !_.isEmpty(unselectedCustomStudyDrafts);
    }

}
