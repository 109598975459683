/**
 * @format
 */
import { ChangeDetectionStrategy, Component, Input, OnInit, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { CourseUnit, Plan, PriorLearningSubstitutionWorkflowApplication, StudyRight } from 'common-typescript/types';
import { Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { STUDENT_APPLICATION_TYPE } from 'sis-components/model/student-application-constants';
import { UiRouterService } from 'sis-components/router/ui-router.service';
import { filterStudyRights, StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { ApplicationCreationService } from '../../../../common/service/application-creation.service';
import { createWizardStateService } from '../store/prior-learning-workflow-application-wizard-utils';

@Component({
    selector: 'app-prior-learning-substitution-application-wizard',
    templateUrl: './prior-learning-substitution-workflow-application-wizard.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorLearningSubstitutionWorkflowApplicationWizardComponent implements OnInit {
    @Input() plan: Plan;
    @Input() courseUnit: CourseUnit;

    readonly wizardStepKeys = [
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.SUBSTITUTION_PHASE_1',
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.SUBSTITUTION_PHASE_2',
    ];

    currentStep: WritableSignal<number> = signal(0);

    service = createWizardStateService();
    uploading = false;

    constructor(
        private applicationCreationService: ApplicationCreationService,
        private studyRightEntityService: StudyRightEntityService,
        private uiRouterService: UiRouterService,
        private appErrorHandler: AppErrorHandler,
    ) {}

    ngOnInit(): void {
        const workflowApplication = {
            courseUnitId: this.courseUnit?.id,
            courseUnitGroupId: this.courseUnit?.groupId,
            planId: this.plan?.id,
            type: STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_SUBSTITUTION_APPLICATION,
            priorLearnings: [],
        } as PriorLearningSubstitutionWorkflowApplication;

        this.service.store.update((_state) => ({
            priorLearningWorkflowApplication: workflowApplication,
            selectedExternalAttainedStudies: [],
            fetchedExternalAttainedStudies: [],
            fetchedExternalAttainedStudyAttachments: [],
        }));

        this.getStudyRightForPlan(this.plan)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
            .subscribe({
                next: (studyRight) => {
                    this.service.store.update((state) => {
                        const priorLearningWorkflowApplication = state.priorLearningWorkflowApplication;
                        priorLearningWorkflowApplication.studyRightId = studyRight?.id;
                        return {
                            ...state,
                            priorLearningWorkflowApplication,
                        };
                    });
                },
            });
    }

    isFirstStep(): boolean {
        return this.currentStep() === 0;
    }

    isLastStep(): boolean {
        return this.currentStep() === this.wizardStepKeys.length - 1;
    }

    exit(): void {
        this.uiRouterService.goToPreviousState();
    }

    previous(): void {
        if (!this.isFirstStep()) {
            this.currentStep.set(this.currentStep() - 1);
        }
    }

    continue(): void {
        if (!this.isLastStep()) {
            this.currentStep.set(this.currentStep() + 1);
        }
    }

    submit() {
        if (!this.uploading) {
            const wizardData = this.service.query.getValue();

            this.uploading = true;
            this.applicationCreationService
                .createWorkflowApplication(
                    wizardData.priorLearningWorkflowApplication,
                    wizardData.fileItems,
                    'student.logged-in.profile.applications.prior-learning-application',
                )
                .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
                .subscribe({
                    complete: () => {
                        this.uploading = false;
                    },
                });
        }
    }

    getStudyRightForPlan(plan: Plan): Observable<StudyRight | null> {
        return this.studyRightEntityService.findStudyRightsForPlan(plan).pipe(
            filterStudyRights({
                onlyOngoing: true,
                states: ['ACTIVE', 'PASSIVE', 'ACTIVE_NONATTENDING'],
            }),
            // There should only be one active study right per education + learning opportunity combo
            map((studyRights) => (studyRights?.length > 0 ? studyRights[0] : null)),
        );
    }
}
