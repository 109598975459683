/**
 * @format
 */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    CustomStudyDraft,
    PriorLearningApplication,
    PriorLearningInclusionApplication,
    PriorLearningInclusionWorkflowApplication,
    PriorLearningWorkflowApplication,
} from 'common-typescript/types';
import { AlertsService } from 'sis-components/alerts/alerts-ng.service';
import { required } from 'sis-components/form/form-validators';
import { getLabelState } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { STUDENT_APPLICATION_TYPE } from 'sis-components/model/student-application-constants';
import { STUDENT_WORKFLOW_APPLICATION_TYPE } from 'sis-components/model/student-workflow-constants';
import {
    createWizardStateService,
    getAttachedExternalAttainedStudyAttachments,
} from '../store/prior-learning-workflow-application-wizard-utils';

@Component({
    selector: 'app-prior-learning-application-confirmation',
    templateUrl: './prior-learning-application-confirmation.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorLearningApplicationConfirmationComponent implements OnInit {
    @Input() disableButtons = false;
    @Output() exit = new EventEmitter<void>();
    @Output() previous = new EventEmitter<void>();
    @Output() submit = new EventEmitter<void>();

    mustPrintDecision: FormControl;
    getLabelState = getLabelState;

    stateService = createWizardStateService();

    constructor(
        private alertsService: AlertsService,
        private fb: SisFormBuilder,
    ) {}

    ngOnInit(): void {
        const wizardData = this.stateService.query.getValue();

        this.mustPrintDecision = this.fb.control(wizardData?.priorLearningWorkflowApplication?.mustPrintDecision, required());
    }

    get customStudyDraft(): CustomStudyDraft | null {
        const wizardData = this.stateService.query.getValue();
        return this.isInclusionApplication(wizardData?.priorLearningWorkflowApplication) ||
            this.isPriorLearningWorkflowInclusionApplication(wizardData?.priorLearningWorkflowApplication)
            ? wizardData?.priorLearningWorkflowApplication?.customStudyDraft
            : null;
    }

    isInclusionApplication(
        application: Partial<PriorLearningApplication | PriorLearningWorkflowApplication>,
    ): application is PriorLearningInclusionApplication | PriorLearningInclusionWorkflowApplication {
        return application?.type === STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION;
    }

    isPriorLearningWorkflowInclusionApplication(
        application: Partial<PriorLearningApplication | PriorLearningWorkflowApplication>,
    ): application is PriorLearningInclusionWorkflowApplication {
        return application?.type === STUDENT_WORKFLOW_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION;
    }

    onSubmit(): void {
        if (this.mustPrintDecision.valid) {
            const wizardData = this.stateService.query.getValue();
            const application = wizardData.priorLearningWorkflowApplication;
            application.mustPrintDecision = this.mustPrintDecision.value;
            this.stateService.store.update((state) => ({
                ...state,
                priorLearningWorkflowApplication: application,
            }));
            this.submit.emit();
        } else {
            this.mustPrintDecision.markAsTouched();
            this.alertsService.addFormSubmissionFailureAlert();
        }
    }

    protected readonly getAttachedExternalAttainedStudyAttachments = getAttachedExternalAttainedStudyAttachments;
}
