/**
 * @format
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { dateUtils } from 'common-typescript/constants';
import { CourseUnit, ExternalAttainedStudy, Plan, PriorLearningSubstitutionWorkflow, StudyRight, Workflow } from 'common-typescript/types';
import moment from 'moment';
import {
    BehaviorSubject,
    combineLatest,
    map,
    Observable,
    of,
    OperatorFunction,
    ReplaySubject,
    shareReplay,
    skip,
    switchMap,
    take,
} from 'rxjs';
import { AuthService } from 'sis-common/auth/auth-service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { BadgeVariant } from 'sis-components/badge/tiny-badge/tiny-badge.component';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { STUDENT_WORKFLOW_STATE, STUDENT_WORKFLOW_TYPE } from 'sis-components/model/student-workflow-constants';
import { ExternalAttainedStudyService } from 'sis-components/service/external-attained-study.service';
import { UniversityService } from 'sis-components/service/university.service';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

export interface CourseUnitInfoModalPriorLearningData {
    latestSubstitutionWorkflow: PriorLearningSubstitutionWorkflow;
    validCourseUnit: boolean;
    blockingWorkflowForOtherCourseUnit: PriorLearningSubstitutionWorkflow;
    externalAttainedStudiesForLatestWorkflow: ExternalAttainedStudy[];
    creditTransferVisible: boolean;
    validPlanAndStudyRight: boolean;
    workflowBadgeVariant: BadgeVariant;
}

enum CancelState {
    ACTIVE,
    INACTIVE,
}

@Component({
    selector: 'app-course-unit-info-modal-prior-learning',
    templateUrl: './course-unit-info-modal-prior-learning.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitInfoModalPriorLearningComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChildren('cancelConfirmToggleButton') cancelConfirmToggleButton: QueryList<ElementRef>;

    @Input() courseUnit: CourseUnit;
    @Input() plan: Plan;
    @Input() studyRight: StudyRight;

    @Input() openSubstitutionForm: Function;
    @Input() openApplication: Function;

    data$: Observable<CourseUnitInfoModalPriorLearningData>;
    courseUnitInputReplaySubject$ = new ReplaySubject<CourseUnit>(1);

    reloadSubject$ = new BehaviorSubject(null);

    private initialCancelState = CancelState.INACTIVE;
    workflowCancelState$ = new BehaviorSubject<CancelState>(this.initialCancelState);

    loggedIn: boolean;
    currentUniversity: string;

    readonly workflowType = STUDENT_WORKFLOW_TYPE;
    readonly workflowState = STUDENT_WORKFLOW_STATE;
    readonly cancelState = CancelState;

    constructor(
        private authService: AuthService,
        private workflowEntityService: WorkflowEntityService,
        private externalAttainedStudyService: ExternalAttainedStudyService,
        private universityService: UniversityService,
        private appErrorHandler: AppErrorHandler,
        private alertsService: AlertsService,
        private translate: TranslateService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.courseUnit) {
            this.courseUnitInputReplaySubject$.next(changes.courseUnit.currentValue);
            this.workflowCancelState$.next(this.initialCancelState);
        }
    }

    ngOnInit(): void {
        this.loggedIn = this.authService.loggedIn();
        this.initDataObservable();
        this.currentUniversity = this.universityService.getCurrentUniversity().name;
    }

    ngAfterViewInit() {
        // Return focus to toggle button when cancelling cancel-operation
        this.cancelConfirmToggleButton.changes.pipe(skip(1)).subscribe(() => {
            if (this.cancelConfirmToggleButton.length > 0) {
                document.getElementById('cancelConfirmToggleButton').focus();
            }
        });
    }

    initDataObservable(): void {
        let blockingWorkflowForOtherCourseUnit$;
        let latestSubstitutionWorkflow$;

        if (this.loggedIn) {
            const studentWorkflows$ = this.reloadSubject$.pipe(
                switchMap(() =>
                    this.workflowEntityService
                        .getWorkflowsByStudentId(this.authService.personId())
                        .pipe(this.appErrorHandler.defaultErrorHandler()),
                ),
                shareReplay(1),
            );
            latestSubstitutionWorkflow$ = studentWorkflows$.pipe(
                this.filterWorkflowsByType<PriorLearningSubstitutionWorkflow>(this.workflowType.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW),
                switchMap((workflows) =>
                    combineLatest([of(workflows), this.courseUnitInputReplaySubject$]).pipe(
                        this.filterPriorLearningSubstitutionWorkflowsByCourseUnitGroupId(),
                        this.filterPriorLearningSubstitutionWorkflowsByCourseUnitId(),
                        map(([filteredWorkflows]) => filteredWorkflows),
                    ),
                ),
                this.findLatestWorkflow(),
            );

            blockingWorkflowForOtherCourseUnit$ = studentWorkflows$.pipe(
                this.filterWorkflowsByType<PriorLearningSubstitutionWorkflow>(this.workflowType.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW),
                switchMap((workflows) =>
                    combineLatest([of(workflows), this.courseUnitInputReplaySubject$]).pipe(
                        this.filterPriorLearningSubstitutionWorkflowsByCourseUnitGroupId(),
                        map(([work, courseUnit]) => work.filter(({ courseUnitId }) => courseUnitId !== courseUnit.id)),
                    ),
                ),
                this.filterWorkflowsByStates([this.workflowState.ACCEPTED, this.workflowState.IN_HANDLING, this.workflowState.REQUESTED]),
                this.findLatestWorkflow(),
            );
        }

        const creditTransferVisible$ = this.universityService.getCurrentUniversitySettings().pipe(
            this.appErrorHandler.defaultErrorHandler(),
            map((universitySettings) => universitySettings?.frontendFeatureToggles?.creditTransferEnabled ?? true),
        );

        this.data$ = combineLatest({
            latestSubstitutionWorkflow: latestSubstitutionWorkflow$ ?? of(undefined),
            externalAttainedStudiesForLatestWorkflow:
                latestSubstitutionWorkflow$?.pipe(this.getExternalAttainedStudiesForWorkflow()) ?? of([]),
            creditTransferVisible: creditTransferVisible$,
            validCourseUnit: this.courseUnitInputReplaySubject$.pipe(this.validCourseUnit()),
            blockingWorkflowForOtherCourseUnit: blockingWorkflowForOtherCourseUnit$ ?? of(undefined),
            validPlanAndStudyRight: of(this.validPlanAndStudyRight(this.studyRight, this.plan)),
            workflowBadgeVariant: latestSubstitutionWorkflow$?.pipe(this.getWorkflowBadgeVariant()) ?? of(undefined),
        });
    }

    validPlanAndStudyRight(studyRight: StudyRight, plan: Plan): boolean {
        return !!studyRight && !!plan && studyRight.learningOpportunityId === plan.learningOpportunityId;
    }

    /**
     * Filters an array of Workflows using a STUDENT_WORKFLOW_TYPE. Cast result to specified Workflow type.
     *
     * @param workflowType Workflow type to use as the filter.
     */
    filterWorkflowsByType<T extends Workflow>(workflowType: STUDENT_WORKFLOW_TYPE): OperatorFunction<Workflow[], T[]> {
        return map((workflows) => workflows.filter(({ type }) => type === workflowType) as T[]);
    }

    filterWorkflowsByStates<T extends Workflow>(workflowStates: STUDENT_WORKFLOW_STATE[]): OperatorFunction<T[], T[]> {
        return map((workflows) => workflows.filter(({ state }) => workflowStates.some((workflowState) => workflowState === state)));
    }

    /**
     * Filters an array of Workflows using the given course unit groupId.
     */
    filterPriorLearningSubstitutionWorkflowsByCourseUnitGroupId(): OperatorFunction<
        [PriorLearningSubstitutionWorkflow[], CourseUnit],
        [PriorLearningSubstitutionWorkflow[], CourseUnit]
    > {
        return map(([workflows, courseUnit]) => [
            workflows.filter(({ courseUnitGroupId }) => courseUnitGroupId === courseUnit.groupId),
            courseUnit,
        ]);
    }

    /**
     * Filters an array of Workflows using the given course unit Id.
     */
    filterPriorLearningSubstitutionWorkflowsByCourseUnitId(): OperatorFunction<
        [PriorLearningSubstitutionWorkflow[], CourseUnit],
        [PriorLearningSubstitutionWorkflow[], CourseUnit]
    > {
        return map(([workflows, courseUnit]) => [workflows.filter(({ courseUnitId }) => courseUnitId === courseUnit.id), courseUnit]);
    }

    /**
     * Returns the latest Workflow in the given array based on creationTime
     */
    findLatestWorkflow<T extends Workflow>(): OperatorFunction<T[], T> {
        return map((workflows) =>
            workflows.sort((a, b) => dateUtils.createMoment(b.creationTime).diff(dateUtils.createMoment(a.creationTime))).find(Boolean),
        );
    }

    /**
     * Is current date within course units validityPeriod -range.
     */
    validCourseUnit(): OperatorFunction<CourseUnit, boolean> {
        return map((courseUnit) => courseUnit.validityPeriod && dateUtils.rangeContains(moment(), courseUnit.validityPeriod));
    }

    getWorkflowBadgeVariant(): OperatorFunction<Workflow, BadgeVariant> {
        return map((workflow) => {
            const state = workflow?.state;
            switch (state) {
                case this.workflowState.ACCEPTED:
                    return 'success';
                case this.workflowState.REJECTED:
                    return 'danger';
                case this.workflowState.REQUESTED:
                case this.workflowState.IN_HANDLING:
                    return 'secondary';
                default:
                    return null;
            }
        });
    }

    getExternalAttainedStudiesForWorkflow(): OperatorFunction<Workflow, ExternalAttainedStudy[]> {
        return switchMap((workflow) =>
            workflow ? this.externalAttainedStudyService.getExternalAttainedStudyByWorkflowId(workflow.id) : of([]),
        );
    }

    cancelWorkflow(workflow: PriorLearningSubstitutionWorkflow): void {
        this.workflowEntityService
            .cancel(workflow.id)
            .pipe(this.appErrorHandler.defaultErrorHandler(), take(1))
            .subscribe(() => {
                this.alertsService.addAlert({
                    type: AlertType.SUCCESS,
                    message: this.translate.instant('COURSE_UNIT_INFO_MODAL.PRIOR_LEARNING.CANCEL_SUCCESSFUL'),
                });
                this.workflowCancelState$.next(this.cancelState.INACTIVE);
                this.reloadSubject$.next(null);
            });
    }
}
