<!-- @format -->

<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-heading [level]="2">{{ t("SELECT_EMREX_ATTAINMENT_DIALOG.TITLE") }}</fudis-heading>
    <fudis-dialog-content>
      <fudis-grid [rowGap]="'md'">
        <fudis-grid-item>
          <fudis-body-text [variant]="'md-light'">{{ t("SELECT_EMREX_ATTAINMENT_DIALOG.GUIDANCE") }}</fudis-body-text>
        </fudis-grid-item>
        <fudis-grid-item>
          <label>{{ t("SELECT_EMREX_ATTAINMENT_DIALOG.ATTAINMENTS_LABEL") }}</label>
          <sis-radio-button
            [translatedLabel]="t('SELECT_EMREX_ATTAINMENT_DIALOG.NO_ATTAINMENT_SELECTED')"
            [control]="form?.controls?.externalAttainedStudy"
            [value]="null"
          />
          <sis-radio-button
            *ngFor="let study of sortedExternalAttainedStudies"
            [translatedLabel]="study.translatedLabel"
            [control]="form?.controls?.externalAttainedStudy"
            [value]="getExternalAttainedStudyById(study.id)"
          />
        </fudis-grid-item>
      </fudis-grid>
    </fudis-dialog-content>
    <fudis-dialog-actions>
      <fudis-button fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')" [variant]="'secondary'" />
      <fudis-button [label]="t('SIS_COMPONENTS.BUTTON.SAVE')" (handleClick)="submit()" />
    </fudis-dialog-actions>
  </fudis-dialog>
</ng-container>
