/**
 * @format
 */

import { ChangeDetectionStrategy, Component, DestroyRef, Inject, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { PlanValidationTs, ValidatablePlan } from 'common-typescript';
import {
    Attainment,
    CourseUnit,
    CurriculumPeriod,
    CustomStudyDraft,
    DegreeProgramme,
    GroupingModule,
    Module,
    Plan,
    StudyModule,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    combineLatestWith,
    EMPTY,
    exhaustMap,
    from,
    map,
    merge,
    mergeMap,
    Observable,
    of,
    scan,
    shareReplay,
    Subject,
    switchMap,
    take,
    tap,
    withLatestFrom,
} from 'rxjs';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { CURRICULUM_PERIOD_SERVICE, PLAN_STUDY_RIGHT_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { confirmDialogFdOpener, ConfirmDialogFdValues } from 'sis-components/confirm/confirm-dialog-fd.component';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
    UiOperation,
    UiOperationType,
} from 'sis-components/plan/plan-actions-service/plan-actions.service';
import { PlanManager } from 'sis-components/plan/plan-manager/plan-manager.service';
import { GradeScaleEntityService } from 'sis-components/service/grade-scale-entity.service';
import { PlanDiffService } from 'sis-components/service/plan-diff.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { PlanData, PlanStateObject, PlanStateService } from 'sis-components/service/plan-state.service';
import { RawPlanEditService } from 'sis-components/service/raw-plan-edit.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { StudentPlanActionsService } from '../../../../service/student-plan-actions.service';
import { StudentPlanUiActionsServiceImplementation } from '../../../../service/student-plan-ui-actions-service-implementation.service';
import { PlanStructureEditModalValues } from '../plan-structure-edit-modal.component';

interface FreeEditPlanData {
    planData: PlanData;
    allModules: Module[];
    searchCurriculumPeriods: CurriculumPeriod[];
    allCourseUnits: CourseUnit[];
    allCustomStudyDrafts: CustomStudyDraft[];
    planStateObject: PlanStateObject;
    validatablePlan: ValidatablePlan;
}

@Component({
    selector: 'app-plan-structure-free-edit',
    templateUrl: './plan-structure-free-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        PlanManager,
        {
            provide: PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
            useClass: StudentPlanActionsService,
        },
    ],
})
export class PlanStructureFreeEditComponent implements OnInit {
    private dialogService = inject(FudisDialogService);
    private confirmDialogFdOpener = confirmDialogFdOpener();
    private studentPlanUIActionsImplementationService = inject(StudentPlanUiActionsServiceImplementation);

    @Input() modalValues: PlanStructureEditModalValues;

    submitClick$: Subject<void> = new Subject();

    data$: Observable<FreeEditPlanData>;

    unattachedAttainments: Attainment[] = [];
    isPlanRootModule: boolean;
    originalValidatablePlan: ValidatablePlan;

    constructor(
        private planEntityService: PlanEntityService,
        private destroyRef: DestroyRef,
        private planManager: PlanManager,
        private planStateService: PlanStateService,
        private gradeScaleEntityService: GradeScaleEntityService,
        private rawPlanEditService: RawPlanEditService,
        private planDiffService: PlanDiffService,
        private appErrorHandler: AppErrorHandler,
        @Inject(CURRICULUM_PERIOD_SERVICE) private curriculumPeriodService: any,
        @Inject(PLAN_STUDY_RIGHT_SERVICE) private planStudyRightService: any,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
        @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
    ) {}

    ngOnInit(): void {
        this.originalValidatablePlan = this.modalValues.validatablePlan;
        this.planManager.setValidatablePlan(_.cloneDeep(this.originalValidatablePlan));
        this.data$ = this.createDataObservable();
        this.createPlanOperationSubjectSubscription();
        this.createPlanUiOperationSubjectSubscription();
        this.createSubmitClickSubscription();
        this.unattachedAttainments = this.rawPlanEditService.getAllUnattachedAttainments(this.getAllAttainments());
        this.isPlanRootModule = _.get(this.modalValues.module, 'id') === this.modalValues.education.id;
    }

    /**
     * Subscribes to planOperationSubject and processes the operations by passing them directly to planManager.
     */
    createPlanOperationSubjectSubscription(): void {
        this.planActionsService.planOperationSubject
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                mergeMap((operation) => this.planManager.processPlanOperation(operation)),
            )
            .subscribe();
    }

    createPlanUiOperationSubjectSubscription(): void {
        this.planActionsService.uiOperationSubject
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap((operation) => this.handleUiOperation(operation)),
            )
            .subscribe();
    }

    handleUiOperation(operation: UiOperation): void {
        switch (operation.uiOperationType) {
            case UiOperationType.OPEN_CUSTOM_STUDY_DRAFT_CREATION_MODAL: {
                this.studentPlanUIActionsImplementationService
                    .openCreateCustomStudyDraftModalHandler(this.modalValues.module.id)
                    .afterClosed()
                    .subscribe((result) => {
                        if (result?.operation === 'ADD' && result?.customStudyDraft) {
                            this.planActionsService.addCustomStudyDraft(result.customStudyDraft, this.modalValues.module);
                        }
                    });
                break;
            }
            case UiOperationType.OPEN_COURSE_UNIT_INFO_MODAL: {
                this.studentPlanUIActionsImplementationService.openCourseUnitInfoModalHandler(
                    operation.target as CourseUnit,
                    this.modalValues.validatablePlan,
                );
                break;
            }
            case UiOperationType.OPEN_MODULE_INFO_MODAL: {
                this.studentPlanUIActionsImplementationService.openModuleInfoModalHandler(
                    operation.target as Module,
                    this.modalValues.validatablePlan,
                    this.modalValues.validatablePlanStudyRight,
                    this.modalValues.planValidationResult,
                );
                break;
            }
            case UiOperationType.OPEN_CUSTOM_STUDY_DRAFT_INFO_MODAL: {
                this.studentPlanUIActionsImplementationService.openCustomStudyDraftInfoModalHandler(
                    operation.target as CustomStudyDraft,
                    this.modalValues.validatablePlan.plan.userId,
                    // Don't allow moving to application wizards from edit modal
                    { customAttainmentApplicationsDisabled: true },
                );
                break;
            }
        }
    }

    createDataObservable(): Observable<FreeEditPlanData> {
        // First emitted value will be the initial validatable plan, after that,
        // emit values from planManager.validatablePlanSubject
        const validatablePlan$ = merge(of(this.modalValues.validatablePlan), this.planManager.validatablePlanSubject).pipe(
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        // Accumulate all course units that have been selected during this edit session
        const allCourseUnits$ = validatablePlan$.pipe(
            scan(
                (acc: CourseUnit[], validatablePlan: ValidatablePlan) =>
                    [...new Set([...acc, ...validatablePlan.getSelectedCourseUnitsUnderModule(this.modalValues.module)])] as CourseUnit[],
                [],
            ),
        );

        // Accumulate all modules that have been selected during this edit session
        const allModules$ = validatablePlan$.pipe(
            scan(
                (acc: Module[], validatablePlan: ValidatablePlan) =>
                    [...new Set([...acc, ...validatablePlan.getSelectedModulesUnderModule(this.modalValues.module)])] as Module[],
                [],
            ),
        );

        // Accumulate all custom study drafts that have been edited during this edit session
        const allCustomStudyDrafts$ = validatablePlan$.pipe(
            scan(
                (acc: CustomStudyDraft[], validatablePlan: ValidatablePlan) =>
                    _.uniqBy(
                        [...acc, ...validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(this.modalValues.module.id)],
                        'id',
                    ) as CustomStudyDraft[],
                [],
            ),
        );
        const gradeScalesById$ = validatablePlan$.pipe(
            switchMap((validatablePlan) => this.createGradeScalesByIdObservable(validatablePlan)),
        );

        const searchCurriculumPeriods$ = from(
            convertAJSPromiseToNative(
                this.curriculumPeriodService.getCurriculumPeriodsForSearch(this.modalValues.validatablePlan.plan.curriculumPeriodId),
            ).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler),
        ) as Observable<CurriculumPeriod[]>;

        return validatablePlan$.pipe(
            combineLatestWith(allModules$, allCourseUnits$, allCustomStudyDrafts$, gradeScalesById$, searchCurriculumPeriods$),
            map(([validatablePlan, allModules, allCourseUnits, allCustomStudyDrafts, gradeScalesById, searchCurriculumPeriods]) => {
                const planValidationResult = PlanValidationTs.validatePlan(validatablePlan);
                const educationOptions = this.planStudyRightService.getValidatedEducationOptions(
                    validatablePlan,
                    this.modalValues.education,
                    this.modalValues.validatablePlanStudyRight,
                );
                const planStateAndData = this.planStateService.getPlanStateAndData(
                    this.modalValues.education,
                    validatablePlan,
                    planValidationResult,
                    educationOptions,
                    gradeScalesById,
                    this.modalValues.validatablePlanStudyRight,
                );

                return {
                    validatablePlan,
                    allModules,
                    allCourseUnits,
                    allCustomStudyDrafts,
                    searchCurriculumPeriods,
                    planData: planStateAndData.planData,
                    planStateObject: planStateAndData.planStateObject,
                };
            }),
        );
    }

    /**
     * Gathers all grade scale ids from plan attainments and returns them in an object with the grade scale id as the key.
     *
     * @param validatablePlan Current validatable plan.
     */
    createGradeScalesByIdObservable(validatablePlan: ValidatablePlan): Observable<{ [id: string]: any }> {
        return this.gradeScaleEntityService
            .getByIds(_.chain(_.values(validatablePlan.getAllAttainments())).map('gradeScaleId').concat('sis-0-5').compact().uniq().value())
            .pipe(map((gradeScales) => _.keyBy(gradeScales, 'id')));
    }

    createSubmitClickSubscription() {
        this.submitClick$
            .pipe(
                withLatestFrom(this.data$),
                exhaustMap(([, data]) =>
                    this.updatePlan(data).pipe(
                        take(1),
                        this.appErrorHandler.defaultErrorHandler(),
                        tap(() => this.dialogService.closeAll()),
                    ),
                ),
            )
            .subscribe();
    }

    dismiss() {
        this.dialogService.close();
    }

    handleCourseUnitToggle(toggleData: { courseUnit: CourseUnit; isInPlan: boolean }): void {
        const { courseUnit, isInPlan } = toggleData;
        if (isInPlan) {
            this.planActionsService.forceRemoveCourseUnit(courseUnit, this.modalValues.module);
        } else {
            this.planActionsService.forceSelectCourseUnitById(courseUnit.id, this.modalValues.module);
        }
    }

    selectedCourseUnit(courseUnit: CourseUnit): void {
        const attainedVersionId = this.rawPlanEditService.findAttainedVersionIdForCourseUnit(
            courseUnit.groupId,
            this.unattachedAttainments,
        );
        const versionIdToBeSelected = attainedVersionId || courseUnit.id;
        this.planActionsService.forceSelectCourseUnitById(versionIdToBeSelected, this.modalValues.module);
    }

    selectedModule(module: GroupingModule | StudyModule | DegreeProgramme): void {
        const attainedVersionId = this.rawPlanEditService.findAttainedVersionIdForModule(module.groupId, this.unattachedAttainments);
        const versionIdToBeSelected = attainedVersionId || module.id;
        this.planActionsService.forceSelectModuleById(versionIdToBeSelected, this.modalValues.module);
    }

    handleModuleToggle(toggleData: { module: GroupingModule | StudyModule | DegreeProgramme; isInPlan: boolean }): void {
        const { module, isInPlan } = toggleData;
        if (isInPlan) {
            this.planActionsService.forceRemoveModule(module, this.modalValues.module);
        } else {
            this.planActionsService.forceSelectModuleById(module.id, this.modalValues.module);
        }
    }

    toggleCustomStudyDraft(toggleData: { customStudyDraft: CustomStudyDraft; isInPlan: boolean }): void {
        const { customStudyDraft, isInPlan } = toggleData;
        if (isInPlan) {
            this.planActionsService.removeCustomStudyDraft(customStudyDraft, this.modalValues.module);
        } else {
            this.planActionsService.addCustomStudyDraft(customStudyDraft, this.modalValues.module);
        }
    }

    getAllAttainments(): Attainment[] {
        return _.values(this.modalValues.validatablePlan.getAllAttainments());
    }

    updatePlan(data: FreeEditPlanData): Observable<Plan> {
        let customStudyDraftConfirmationObservable: Observable<boolean> = of(true);
        const { allCustomStudyDrafts, validatablePlan } = data;
        const selectedCustomStudyDrafts = validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(this.modalValues.module.id);
        if (this.planDiffService.hasUnselectedCustomStudyDrafts(allCustomStudyDrafts, selectedCustomStudyDrafts)) {
            customStudyDraftConfirmationObservable = this.confirmDialogFdOpener({
                title: 'PLAN.CUSTOM_STUDY_DRAFT_DELETE_CONFIRM_MODAL.TITLE',
                descriptions: [
                    'PLAN.CUSTOM_STUDY_DRAFT_DELETE_CONFIRM_MODAL.DESCRIPTION_1',
                    'PLAN.CUSTOM_STUDY_DRAFT_DELETE_CONFIRM_MODAL.DESCRIPTION_2',
                ],
                confirmObservable: of(true),
            } as ConfirmDialogFdValues<boolean>).afterClosed();
        }
        return customStudyDraftConfirmationObservable.pipe(
            switchMap((result: boolean) => (result ? this.planEntityService.updateMyPlan(validatablePlan.plan) : EMPTY)),
        );
    }

    openAddStudyDraftModal() {
        this.planActionsService.openCustomStudyDraftCreationModal(this.modalValues.module);
    }
}
