/** @format */
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { PlanValidationResult, ValidatablePlan } from 'common-typescript';
import {
    AnyCourseUnitRule,
    AnyModuleRule,
    CompositeRule,
    CourseUnitCountRule,
    CourseUnitRule,
    CreditsRule,
    EntityWithRule,
    ModuleRule,
    OtmId,
    Rule,
} from 'common-typescript/types';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AppErrorHandler } from '../../../error-handler/app-error-handler';
import { CurriculumPeriodEntityService } from '../../../service/curriculum-period-entity.service';
import { PlanRuleData } from '../../../service/plan-rule-data.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { StudyRightEntityService } from '../../../service/study-right-entity.service';
import { UniversitySettingsEntityService } from '../../../service/university-settings-entity.service';
import { UniversityService } from '../../../service/university.service';

type CombinedRules = CourseUnitCountRule | CompositeRule | CreditsRule | CourseUnitRule | ModuleRule | AnyCourseUnitRule | AnyModuleRule;
@Component({
    selector: 'sis-plan-structure-select-rule',
    templateUrl: './plan-structure-select-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureSelectRuleComponent implements OnInit, OnDestroy {
    @Input({ required: true }) parentModule: EntityWithRule;
    @Input({ required: true }) planValidationResult: PlanValidationResult;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) planRuleData: PlanRuleData;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Input({ required: true }) headingLevel: number;
    @Input({ required: true }) ruleDepthLevel: number;
    @Input({ transform: (value: number | string): string => value as string, required: true }) groupPrefix: string;
    @Input({ required: true }) selectionUIState: 'ACTIVE' | 'DISABLED' | 'SELECTABLE' = 'ACTIVE';
    @Input({ required: true }) set rule(rule: Rule) {
        this._rule = <CombinedRules>rule;
    }

    _rule: CombinedRules;

    currentUniversityOrgId: OtmId;
    crossStudyFeaturesEnabled = false;
    cooperationNetworkIds: OtmId[] = null;
    curriculumPeriodIds: OtmId[] = null;

    private destroy$ = new Subject<void>();

    constructor(
        private appErrorHandler: AppErrorHandler,
        private universityService: UniversityService,
        private curriculumPeriodEntityService: CurriculumPeriodEntityService,
        private universitySettingsService: UniversitySettingsEntityService,
        private studyRightEntityService: StudyRightEntityService,
    ) {
        this.currentUniversityOrgId = this.universityService.getCurrentUniversityOrgId();
    }

    ngOnInit(): void {
        if (['AnyCourseUnitRule', 'AnyModuleRule'].includes(this._rule.type)) {
            this.curriculumPeriodEntityService
                .getCurriculumPeriodsForSearch(this.currentUniversityOrgId, this.validatablePlan?.plan.curriculumPeriodId)
                .pipe(
                    takeUntil(this.destroy$),
                    tap((ids) => {
                        this.curriculumPeriodIds = _.map(ids, 'id');
                    }),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe();

            if (this._rule.type === 'AnyCourseUnitRule') {
                if (this.planStateObject?.studyRight) {
                    this.cooperationNetworkIds = this.studyRightEntityService.getValidCooperationNetworkIds(
                        this.planStateObject.studyRight,
                    );
                }

                this.universitySettingsService
                    .getById(this.currentUniversityOrgId)
                    .pipe(
                        takeUntil(this.destroy$),
                        tap((settings) => {
                            this.crossStudyFeaturesEnabled = !!_.get(
                                settings,
                                'frontendFeatureToggles.crossStudyFeaturesEnabledForStudent',
                            );
                        }),
                        this.appErrorHandler.defaultErrorHandler(),
                    )
                    .subscribe();
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
