/**
 * @format
 */

import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    OnInit,
    QueryList,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { PlanValidationResult, PlanValidationTs, ValidatablePlan } from 'common-typescript';
import { CourseUnit, CustomStudyDraft, Education, EntityWithRule, Plan, StudyRight } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { combineLatestWith, exhaustMap, map, merge, mergeMap, Observable, of, Subject, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { PLAN_STUDY_RIGHT_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Option } from 'sis-components/menuButton/menu-button.component';
import { RuleClearSignalService } from 'sis-components/plan-structure/rules/rule-clear-signal.service';
import { RuleError, RuleErrorState, RuleErrorStateService } from 'sis-components/plan-structure/rules/rule-error-state.service';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
    UiOperation,
    UiOperationType,
} from 'sis-components/plan/plan-actions-service/plan-actions.service';
import { PlanManager } from 'sis-components/plan/plan-manager/plan-manager.service';
import { GradeScaleEntityService } from 'sis-components/service/grade-scale-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { PlanRuleData, PlanRuleDataService } from 'sis-components/service/plan-rule-data.service';
import { PlanData, PlanStateObject, PlanStateService } from 'sis-components/service/plan-state.service';

import { StudentPlanActionsService } from '../../../service/student-plan-actions.service';
import { StudentPlanUiActionsServiceImplementation } from '../../../service/student-plan-ui-actions-service-implementation.service';

export function planStructureEditModalOpener(): (
    modalValues: PlanStructureEditModalValues,
) => MatDialogRef<PlanStructureEditModalComponent, { data: object }> {
    const fudisDialogService = inject(FudisDialogService);
    return (modalValues) => fudisDialogService.open(PlanStructureEditModalComponent, { data: modalValues });
}

export interface PlanStructureEditModalValues {
    module: EntityWithRule;
    validatablePlan: ValidatablePlan;
    validatablePlanStudyRight: StudyRight;
    planValidationResult: PlanValidationResult;
    education: Education;
}
interface PlanStructureEditModalData {
    planData: PlanData;
    planStateObject: PlanStateObject;
    planValidationResult: PlanValidationResult;
    planRuleData: PlanRuleData;
    validatablePlan: ValidatablePlan;
    selectedModule: EntityWithRule;
}
@Component({
    selector: 'app-plan-structure-edit-modal',
    templateUrl: './plan-structure-edit-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
            useClass: StudentPlanActionsService,
        },
        PlanManager,
        RuleClearSignalService,
        RuleErrorStateService,
    ],
})
export class PlanStructureEditModalComponent implements OnInit {
    modalValues: PlanStructureEditModalValues = inject(MAT_DIALOG_DATA);
    private planManager: PlanManager = inject(PlanManager);
    private planActionsService: PlanActionsService = inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN);
    private planStudyRightService: any = inject(PLAN_STUDY_RIGHT_SERVICE);
    private planStateService: PlanStateService = inject(PlanStateService);
    private planRuleDataService: PlanRuleDataService = inject(PlanRuleDataService);
    protected ruleErrorStateService: RuleErrorStateService = inject(RuleErrorStateService);
    private gradeScaleEntityService: GradeScaleEntityService = inject(GradeScaleEntityService);
    private planEntityService: PlanEntityService = inject(PlanEntityService);
    private dialogService = inject(FudisDialogService);
    private appErrorHandler = inject(AppErrorHandler);
    private destroyRef: DestroyRef = inject(DestroyRef);
    private studentPlanUIActionsImplementationService = inject(StudentPlanUiActionsServiceImplementation);

    hasAnyErrors$ = this.ruleErrorStateService.hasAnyErrors$;
    hasShownErrors$ = this.ruleErrorStateService.hasShownErrors$;
    @ViewChildren('errorSummary', { read: ElementRef }) errorSummaryQuery$: QueryList<ElementRef>;

    data$: Observable<PlanStructureEditModalData>;
    submitClick$: Subject<void> = new Subject();
    menuOptions: Option[] = [];
    showFreeEditMode: boolean = false;

    ngOnInit() {
        // Important to clone the validatable plan to avoid modifying the original plan
        this.planManager.setValidatablePlan(_.cloneDeep(this.modalValues.validatablePlan));
        this.planManager.setStudyRight(this.modalValues.validatablePlanStudyRight);
        this.data$ = this.createDataObservable();
        this.createPlanOperationSubjectSubscription();
        this.createPlanUIOperationSubscriptions();
        this.createSubmitClickSubscription();
        this.menuOptions.push({ name: 'SIS_COMPONENTS.PLAN.FREE_EDIT_MENU_OPTION', action: () => (this.showFreeEditMode = true) });
    }

    createDataObservable(): Observable<PlanStructureEditModalData> {
        // First emitted value will be the initial validatable plan, after that,
        // emit values from planManager.validatablePlanSubject
        return merge(of(this.modalValues.validatablePlan), this.planManager.validatablePlanSubject)
            .pipe(
                // Prepare data for plan update, combine gradescales and validatable plan
                switchMap((validatablePlan) =>
                    this.createGradeScalesByIdObservable(validatablePlan).pipe(combineLatestWith(of(validatablePlan))),
                ),
                map(([gradeScalesById, newValidatablePlan]) => this.handleValidatablePlanUpdate(gradeScalesById, newValidatablePlan)),
            )
            .pipe(
                // Resolve shown selectable course units and modules.
                // This is only done once and the initial value is passed on each emit
                combineLatestWith(this.planRuleDataService.resolvePlanRuleData(this.modalValues.validatablePlan, this.modalValues.module)),
                map(([data, planRuleData]) => ({ ...data, planRuleData }) as PlanStructureEditModalData),
            );
    }

    /**
     * Gathers all grade scale ids from plan attainments and returns them in an object with the grade scale id as the key.
     *
     * @param validatablePlan Current validatable plan.
     */
    createGradeScalesByIdObservable(validatablePlan: ValidatablePlan): Observable<{ [id: string]: any }> {
        return this.gradeScaleEntityService
            .getByIds(_.chain(_.values(validatablePlan.getAllAttainments())).map('gradeScaleId').concat('sis-0-5').compact().uniq().value())
            .pipe(map((gradeScales) => _.keyBy(gradeScales, 'id')));
    }

    /**
     * Subscribes to planOperationSubject and processes the operations by passing them directly to planManager.
     */
    createPlanOperationSubjectSubscription(): void {
        this.planActionsService.planOperationSubject
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                mergeMap((operation) => this.planManager.processPlanOperation(operation)),
            )
            .subscribe();
    }

    createPlanUIOperationSubscriptions(): void {
        this.planActionsService.uiOperationSubject
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap((operation) => this.uiOperationHandler(operation)),
            )
            .subscribe();
    }

    uiOperationHandler(operation: UiOperation): void {
        switch (operation.uiOperationType) {
            case UiOperationType.OPEN_CUSTOM_STUDY_DRAFT_CREATION_MODAL: {
                this.studentPlanUIActionsImplementationService
                    .openCreateCustomStudyDraftModalHandler(this.modalValues.module.id)
                    .afterClosed()
                    .subscribe((result) => {
                        if (result?.operation === 'ADD' && result?.customStudyDraft) {
                            this.planActionsService.addCustomStudyDraft(result.customStudyDraft, this.modalValues.module);
                        }
                    });
                break;
            }
            case UiOperationType.OPEN_MODULE_INFO_MODAL: {
                this.studentPlanUIActionsImplementationService.openModuleInfoModalHandler(
                    operation.target as EntityWithRule,
                    this.modalValues.validatablePlan,
                    this.modalValues.validatablePlanStudyRight,
                    this.modalValues.planValidationResult,
                );
                break;
            }
            case UiOperationType.OPEN_COURSE_UNIT_INFO_MODAL: {
                this.studentPlanUIActionsImplementationService.openCourseUnitInfoModalHandler(
                    operation.target as CourseUnit,
                    this.modalValues.validatablePlan,
                );
                break;
            }
            case UiOperationType.OPEN_CUSTOM_STUDY_DRAFT_INFO_MODAL: {
                this.studentPlanUIActionsImplementationService.openCustomStudyDraftInfoModalHandler(
                    operation.target as CustomStudyDraft,
                    this.modalValues.validatablePlan.plan.userId,
                    // Don't allow moving to application wizards from edit modal
                    { customAttainmentApplicationsDisabled: true },
                );
                break;
            }
        }
    }

    createSubmitClickSubscription() {
        this.submitClick$
            .pipe(
                withLatestFrom(this.data$),
                exhaustMap(([voidValue, data]) =>
                    this.save(data.validatablePlan.plan)
                        // On success tap observer next will be called, on error tap is bypassed and the error is consumed by the error handler
                        .pipe(
                            switchMap((notification) => {
                                if (notification === 'SUCCESS') {
                                    this.dialogService.close();
                                }
                                if (notification === 'INVALID') {
                                    if (this.errorSummaryQuery$.length === 0) {
                                        return this.errorSummaryQuery$.changes.pipe(
                                            take(1),
                                            tap(([errorSummary]) => {
                                                if (errorSummary) {
                                                    this.focusElementRef(errorSummary);
                                                }
                                            }),
                                        );
                                    }
                                    this.focusElementRef(this.errorSummaryQuery$.first);
                                }
                                return of(null);
                            }),
                            this.appErrorHandler.defaultErrorHandler(),
                        ),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    focusElementRef(elRef: ElementRef) {
        elRef.nativeElement.focus();
        elRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    handleValidatablePlanUpdate(gradeScalesById: any, newValidatablePlan: ValidatablePlan): Partial<PlanStructureEditModalData> {
        const { validatablePlan, validatablePlanStudyRight, module, education } = this.modalValues;
        const planValidationResult = PlanValidationTs.validatePlan(newValidatablePlan);
        const educationOptions = this.planStudyRightService.getValidatedEducationOptions(
            validatablePlan,
            education,
            validatablePlanStudyRight,
        );
        const selectionPathInPlan = this.planStudyRightService.getSelectionPathInPlan(validatablePlan, education);
        const planStateAndData = this.planStateService.getPlanStateAndData(
            education,
            newValidatablePlan,
            planValidationResult,
            educationOptions,
            gradeScalesById,
            validatablePlanStudyRight,
        );
        return {
            planData: planStateAndData.planData,
            planStateObject: planStateAndData.planStateObject,
            planValidationResult,
            validatablePlan: newValidatablePlan,
            selectedModule: module,
        };
    }

    focusRule([ruleErrorState]: [RuleErrorState, RuleError]) {
        const el = document.getElementById(ruleErrorState.ruleFocusId);
        if (el) {
            el.focus();
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    dismiss() {
        this.dialogService.close();
    }

    save(newPlan: Plan): Observable<'SUCCESS' | 'INVALID'> {
        if (this.hasAnyErrors$()) {
            this.ruleErrorStateService.showCurrentErrorStates();
            return of('INVALID');
        }
        return this.planEntityService.updateMyPlan(newPlan).pipe(
            take(1),
            map(() => 'SUCCESS'),
        );
    }
}
