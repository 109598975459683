import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input,
    output,
    ViewEncapsulation,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ValidatablePlan } from 'common-typescript';
import { CustomStudyDraft, EntityWithRule } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ReadMoreModalService } from 'sis-components/read-more-ng/read-more-modal.service';
import { ReadMoreValues } from 'sis-components/read-more-ng/read-more-ng-modal/read-more-ng-modal.component';
import { StudyBoxType } from 'sis-components/study-boxes/study-box/study-box.component';

@Component({
    selector: 'app-free-edit-custom-study-draft-expandable',
    templateUrl: './free-edit-custom-study-draft-expandable.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeEditCustomStudyDraftExpandableComponent {
    readMoreModalService = inject(ReadMoreModalService);
    translocoService = inject(TranslocoService);

    module = input<EntityWithRule>();
    validatablePlan = input<ValidatablePlan>();
    allCustomStudyDrafts = input<CustomStudyDraft[]>();

    selectedCustomStudyDraftsById = computed(() => {
        const validatablePlan = this.validatablePlan();
        const parentModule = this.module();
        return _.keyBy(validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(parentModule.id), 'id');
    });

    openAddStudyDraftModal = output();
    toggleCustomStudyDraft = output< {
        customStudyDraft: CustomStudyDraft,
        isInPlan: boolean,
    }>();

    protected readonly StudyBoxType = StudyBoxType;

    openReadMoreAboutStudyDraftModal() {
        this.readMoreModalService.open(this.getReadMoreOptions());
    }

    getReadMoreOptions(): ReadMoreValues {
        return {
            options: {
                contentHtml: null,
                contentTranslateKey: 'PLAN.READ_MORE',
                contentTranslateParameters: null,
                title: this.translocoService.translate('PLAN.CUSTOM_STUDY_DRAFTS_TITLE'),
            },
        };
    }
}
