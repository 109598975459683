/**
 * @format
 */
import { ChangeDetectionStrategy, Component, Input, OnInit, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OtmId, Plan, PriorLearningInclusionWorkflowApplication, StudyRight } from 'common-typescript/types';
import { map, Observable, take } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { HeaderService } from 'sis-components/header/header.service';
import { STUDENT_APPLICATION_TYPE } from 'sis-components/model/student-application-constants';
import { UiRouterService } from 'sis-components/router/ui-router.service';
import { filterStudyRights, StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { ApplicationCreationService } from '../../../../common/service/application-creation.service';
import { FormData } from '../custom-attainment-basic-info-edit/custom-attainment-basic-info-edit.component';
import {
    createWizardStateService,
    PriorLearningWorkflowApplicationWizardData,
} from '../store/prior-learning-workflow-application-wizard-utils';

@Component({
    selector: 'app-prior-learning-inclusion-application-wizard',
    templateUrl: './prior-learning-inclusion-workflow-application-wizard.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorLearningInclusionWorkflowApplicationWizardComponent implements OnInit {
    @Input() plan: Plan;
    @Input() customStudyDraftId?: OtmId;

    readonly wizardStepKeys = [
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.PHASE_1',
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.PHASE_2',
        'PROFILE.APPLICATIONS.PRIOR_LEARNING.PHASE_3',
    ];

    currentStep: WritableSignal<number> = signal(0);

    service = createWizardStateService();
    uploading = false;

    constructor(
        private applicationCreationService: ApplicationCreationService,
        private studyRightEntityService: StudyRightEntityService,
        private uiRouterService: UiRouterService,
        private appErrorHandler: AppErrorHandler,
        private headerService: HeaderService,
        private translate: TranslateService,
    ) {}

    ngOnInit(): void {
        const workflowApplication = {
            planId: this.plan?.id,
            type: STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION,
            priorLearnings: [],
        } as PriorLearningInclusionWorkflowApplication;

        if (this.customStudyDraftId) {
            workflowApplication.customStudyDraft = this.plan?.customStudyDrafts?.find((draft) => draft?.id === this.customStudyDraftId);
        }

        this.service.store.update((_state) => ({
            priorLearningWorkflowApplication: workflowApplication,
            selectedExternalAttainedStudies: [],
            fetchedExternalAttainedStudies: [],
            fetchedExternalAttainedStudyAttachments: [],
            fileItems: [],
        }));

        this.getStudyRightForPlan(this.plan)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
            .subscribe({
                next: (studyRight) => {
                    this.service.store.update((state) => {
                        const priorLearningWorkflowApplication =
                            state.priorLearningWorkflowApplication as PriorLearningInclusionWorkflowApplication;
                        priorLearningWorkflowApplication.studyRightId = studyRight?.id;
                        return {
                            ...state,
                            priorLearningWorkflowApplication,
                        };
                    });
                },
            });
    }

    isFirstStep(): boolean {
        return this.currentStep() === 0;
    }

    isLastStep(): boolean {
        return this.currentStep() === this.wizardStepKeys.length - 1;
    }

    exit(): void {
        this.uiRouterService.goToPreviousState();
    }

    previous(): void {
        if (!this.isFirstStep()) {
            this.currentStep.set(this.currentStep() - 1);
        }
    }

    continue(): void {
        if (!this.isLastStep()) {
            if (this.isFirstStep()) {
                const applicationTypeName = this.translate.instant(
                    `STUDENT_APPLICATIONS.TYPE.${STUDENT_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION}`,
                );
                const application = this.service.query.getValue()
                    ?.priorLearningWorkflowApplication as PriorLearningInclusionWorkflowApplication;
                this.headerService.setCustomPageTitle(
                    `${applicationTypeName}: ${application?.name ?? application?.customStudyDraft?.name}`,
                );
            }
            this.currentStep.set(this.currentStep() + 1);
        }
    }

    submit() {
        if (!this.uploading) {
            const wizardData = this.service.query.getValue();

            this.uploading = true;
            this.applicationCreationService
                .createWorkflowApplication(
                    wizardData.priorLearningWorkflowApplication,
                    wizardData.fileItems,
                    'student.logged-in.profile.applications.prior-learning-application',
                )
                .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
                .subscribe({
                    complete: () => {
                        this.uploading = false;
                    },
                });
        }
    }

    getInclusionWorkflowApplication(wizardData: PriorLearningWorkflowApplicationWizardData): PriorLearningInclusionWorkflowApplication {
        return wizardData?.priorLearningWorkflowApplication as PriorLearningInclusionWorkflowApplication;
    }

    private getStudyRightForPlan(plan: Plan): Observable<StudyRight> {
        return this.studyRightEntityService.findStudyRightsForPlan(plan).pipe(
            filterStudyRights({
                onlyOngoing: true,
                states: ['ACTIVE', 'PASSIVE', 'ACTIVE_NONATTENDING'],
            }),
            // There should only be one active study right per education + learning opportunity combo
            map((studyRights) => (studyRights?.length > 0 ? studyRights[0] : null)),
        );
    }

    continueAfterCustomAttainmentBasicInfoEdit($event: FormData) {
        this.service.store.update((state) => {
            const updatedApplication = {
                ...state.priorLearningWorkflowApplication,
                degreeProgrammeId: $event.degreeProgrammeId,
                degreeProgrammeGroupId: $event.degreeProgrammeGroupId,
                name: $event.name,
                plannedParentModuleId: $event.plannedParentModuleId,
            } as PriorLearningInclusionWorkflowApplication;

            return {
                ...state,
                priorLearningWorkflowApplication: updatedApplication,
            };
        });
        this.continue();
    }

    getCustomStudyDraft(wizardData: PriorLearningWorkflowApplicationWizardData) {
        return (wizardData?.priorLearningWorkflowApplication as PriorLearningInclusionWorkflowApplication).customStudyDraft;
    }
}
