<ng-container *ngIf="data$ | async as freeEditPlanData">
  <ng-container *transloco="let t">
    <fudis-dialog class="free-edit-dialog">
      <fudis-heading sisAutofocus
                     [level]="2">{{ t('SIS_COMPONENTS.PLAN.FREE_EDIT.TITLE') + ': ' + (this.modalValues.module.name | localizedString) }}
      </fudis-heading>
      <fudis-dialog-content>
        <app-free-edit-course-unit-expandable
          [searchCurriculumPeriods]="freeEditPlanData.searchCurriculumPeriods"
          [allCourseUnits]="freeEditPlanData.allCourseUnits"
          [planData]="freeEditPlanData.planData"
          [planStateObject]="freeEditPlanData.planStateObject"
          [validatablePlan]="freeEditPlanData.validatablePlan"
          (selectedCourseUnit)="selectedCourseUnit($event)"
          (toggledCourseUnit)="handleCourseUnitToggle($event)">
        </app-free-edit-course-unit-expandable>
        <app-free-edit-module-expandable [allModules]="$any(freeEditPlanData.allModules)"
                                         [searchCurriculumPeriods]="freeEditPlanData.searchCurriculumPeriods"
                                         [planData]="freeEditPlanData.planData"
                                         [planStateObject]="freeEditPlanData.planStateObject"
                                         [validatablePlan]="freeEditPlanData.validatablePlan"
                                         (toggleModule)="handleModuleToggle($event)"
                                         (selectModule)="selectedModule($event)">
        </app-free-edit-module-expandable>
        <app-free-edit-custom-study-draft-expandable *ngIf="!isPlanRootModule"
                                                     [module]="this.modalValues.module"
                                                     [allCustomStudyDrafts]="freeEditPlanData.allCustomStudyDrafts"
                                                     (openAddStudyDraftModal)="this.openAddStudyDraftModal()"
                                                     (toggleCustomStudyDraft)="this.toggleCustomStudyDraft($event)"
                                                     [validatablePlan]="freeEditPlanData.validatablePlan">
        </app-free-edit-custom-study-draft-expandable>
      </fudis-dialog-content>
      <fudis-dialog-actions>
        <fudis-button fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')" (handleClick)="dismiss()"
                      [variant]="'secondary'"/>
        <fudis-button [label]="t('SIS_COMPONENTS.BUTTON.SAVE')" (handleClick)="submitClick$.next()"/>
      </fudis-dialog-actions>
    </fudis-dialog>
  </ng-container>
</ng-container>

