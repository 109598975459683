/**
 * @format
 */
import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { ExternalAttainedStudy, PriorLearning, PriorStudies } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { EmrexAttainmentDialogComponent } from '../../applications/emrex-attainment-dialog/emrex-attainment-dialog.component';
import { PRIOR_LEARNING_TYPE } from '../../model/student-application-constants';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-prior-learning-list',
    templateUrl: './prior-learning-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorLearningListComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.applications.priorLearningList',
        directiveName: 'sisPriorLearningList',
    };

    readonly PRIOR_LEARNING_TYPE = PRIOR_LEARNING_TYPE;

    /**
     * Determines the naming of the prior learning entries (depending on the type of the containing application).
     */
    @Input() applicationType: string;
    @Input() priorLearnings: PriorLearning[];
    @Input() externalAttainedStudies: ExternalAttainedStudy[] = [];
    @Input() showAsExpandable: boolean;
    /**
     * Determines heading's semantic aria-level for screen readers, default is equivalent for h3
     */
    @Input() level?: number = 3;

    private dialogService = inject(FudisDialogService);

    getExternalAttainedStudy(priorLearning: PriorLearning) {
        return this.externalAttainedStudies?.find((study) => study?.id === (<PriorStudies>priorLearning)?.externalAttainedStudyId);
    }

    openEmrexAttainmentDialog(externalAttainedStudy: ExternalAttainedStudy) {
        this.dialogService.open(EmrexAttainmentDialogComponent, {
            data: {
                externalAttainedStudy,
            },
        });
    }
}
