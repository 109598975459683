/**
 * @format
 */

import { Component, DestroyRef, inject, Inject, OnDestroy, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit, PersonWithModuleResponsibilityInfoType } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { mergeMap, Subscription, take, tap } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from 'sis-components/plan/plan-actions-service/plan-actions.service';
import { PlanManager, PlanOperation } from 'sis-components/plan/plan-manager/plan-manager.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { StudentPlanActionsService } from '../../../service/student-plan-actions.service';

export interface StudentCourseUnitInfoModalValues {
    courseUnit: CourseUnit;
    validatablePlan: ValidatablePlan;
}
@Component({
    selector: 'app-student-course-unit-info-modal',
    templateUrl: './student-course-unit-info-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        PlanManager,
        {
            provide: PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
            useClass: StudentPlanActionsService,
        },
    ],
})
export class StudentCourseUnitInfoModalComponent implements OnInit, OnDestroy {
    courseUnit: CourseUnit;
    currentCourseUnit: CourseUnit;
    validatablePlan: ValidatablePlan;
    currentSavedValidatablePlan: ValidatablePlan;
    filteredResponsibilityInfos: PersonWithModuleResponsibilityInfoType[];
    planManagerSubscription: Subscription;
    planOperationSubscription: Subscription;

    private planManager: PlanManager = inject(PlanManager);

    constructor(
        private planEntityService: PlanEntityService,
        private destroyRef: DestroyRef,
        private appErrorHandler: AppErrorHandler,
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
        @Inject(ModalService.injectionToken) public values: StudentCourseUnitInfoModalValues,
        public activeModal: NgbActiveModal,
    ) {
        this.courseUnit = values.courseUnit;
        this.validatablePlan = values.validatablePlan;
    }

    close() {
        this.activeModal.close();
    }

    ngOnInit() {
        this.planManager.setValidatablePlan(_.cloneDeep(this.validatablePlan));
        this.createPlanOperationSubjectSubscription();
        this.planManagerSubscription = this.planManager.validatablePlanSubject.subscribe((newValidatablePlan) => {
            this.validatablePlan = newValidatablePlan;
        });
        this.planOperationSubscription = this.planActionsService.planOperationSubject.subscribe((planOperation: PlanOperation) => {
            this.planManager.processPlanOperation(planOperation);
        });

        this.currentCourseUnit = _.cloneDeep(this.courseUnit);
        this.currentSavedValidatablePlan = _.cloneDeep(this.validatablePlan);
        this.filteredResponsibilityInfos = _.chain(this.courseUnit.responsibilityInfos)
            .filter((info) => _.get(info, 'roleUrn') !== 'urn:code:module-responsibility-info-type:administrative-person')
            .value();
    }

    ngOnDestroy() {
        this.planManagerSubscription.unsubscribe();
        this.planOperationSubscription.unsubscribe();
    }

    /**
     * Subscribes to planOperationSubject and processes the operations by passing them directly to planManager.
     */
    createPlanOperationSubjectSubscription(): void {
        this.planActionsService.planOperationSubject
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                mergeMap((operation) => this.planManager.processPlanOperation(operation)),
            )
            .subscribe();
    }

    setModalCourseUnitVersion(courseUnit: CourseUnit) {
        this.planActionsService?.changeCourseUnitVersion?.(this.courseUnit, courseUnit);
        this.courseUnit = courseUnit;

        this.filteredResponsibilityInfos = _.chain(this.courseUnit.responsibilityInfos)
            .filter((info) => _.get(info, 'roleUrn') !== 'urn:code:module-responsibility-info-type:administrative-person')
            .value();
    }

    saveCourseUnitVersionChange() {
        this.planEntityService
            .updateMyPlan(this.planManager.validatablePlan.plan)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
                tap(() => {
                    this.currentSavedValidatablePlan = this.planManager.validatablePlan;
                    this.currentCourseUnit = this.courseUnit;
                }),
            )
            .subscribe();
    }
}
