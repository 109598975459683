import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { PlanStructureModule } from 'sis-components/plan-structure/plan-structure.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { PlanCommonModule } from '../common/components/plan-common/plan-common.module';

import { CopyPlanModalComponent } from './copy-plan-modal/copy-plan-modal.component';
import { CreateFirstPlanComponent } from './create-first-plan/create-first-plan.component';
import { CreatePlanModalComponent } from './create-plan-modal/create-plan-modal.component';
import { EditPlanNgNameModalComponent } from './edit-plan-ng-name-modal/edit-plan-ng-name-modal.component';
import { PlanHeaderInfoListComponent } from './plan-header/plan-header-info-list/plan-header-info-list.component';
import { PlanHeaderComponent } from './plan-header/plan-header.component';
import { PlanNgComponent } from './plan-ng.component';
import { planStates } from './plan-ng.route';
import { PlanNotificationComponent } from './plan-notification/plan-notification.component';
import { PlanStructureComponent } from './plan-structure/plan-structure.component';

@NgModule({
    declarations: [
        PlanNgComponent,
        PlanHeaderComponent,
        PlanStructureComponent,
        PlanHeaderInfoListComponent,
        EditPlanNgNameModalComponent,
        CopyPlanModalComponent,
        CreatePlanModalComponent,
        CreateFirstPlanComponent,
        PlanNotificationComponent,
    ],
    imports: [
        PlanCommonModule,
        PlanStructureModule,
        UIRouterModule.forChild({ states: planStates }),
        CommonModule,
        SisComponentsModule,
        SisCommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgxFudisModule,
    ],
})
export class PlanNgModule { }
