<div *transloco="let t">
  <fudis-grid *ngIf="!!studentWorkflow">
    <fudis-dl [columns]="{ sm: 1, md: 2 }">
      <fudis-dl-item data-cy="srew-education-name" *ngIf="studentWorkflow.educationId | education | async as education">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.STUDY_RIGHT')" />
        <fudis-dd [contentText]="education.name | localizedString" />
      </fudis-dl-item>
      <fudis-dl-item data-cy="srew-creation-time">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.APPLICATION_ARRIVED')" />
        <fudis-dd [contentText]="studentWorkflow.creationTime | localDateFormat" />
      </fudis-dl-item>
      <fudis-dl-item data-cy="srew-study-right-validity">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.STUDY_RIGHT_VALIDITY')" />
        <fudis-dd [contentText]="studentWorkflow.studyRightValidity | localDateRange" />
      </fudis-dl-item>
      <fudis-dl-item data-cy="srew-extension-info" *ngIf="studentWorkflow.previousExtensions?.length > 0">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.PREVIOUSLY_GRANTED_EXTENSIONS')" />
        <fudis-dd>
          <sis-extension-info [extensions]="studentWorkflow.previousExtensions" />
        </fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item data-cy="srew-study-terms-attending">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.STUDY_TERMS_ATTENDING')" />
        <fudis-dd><sis-text-with-default-missing-value [value]="studentWorkflow.usedAttendanceTerms?.toString()" /></fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item data-cy="srew-study-terms-nonattending">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.STUDY_TERMS_NONATTENDING')" />
        <fudis-dd><sis-text-with-default-missing-value [value]="studentWorkflow.usedAbsenceTerms?.toString()" /></fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item data-cy="srew-study-terms-statutory-absences">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.STUDY_TERMS_STATUTORY_ABSENCES')" />
        <fudis-dd><sis-text-with-default-missing-value [value]="studentWorkflow.usedStatutoryAbsenceTerms?.toString()" /></fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item data-cy="srew-study-terms-no-registration">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.STUDY_TERMS_NO_REGISTRATION')" />
        <fudis-dd><sis-text-with-default-missing-value [value]="studentWorkflow.termsWithoutRegistration?.toString()" /></fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item data-cy="srew-attained-and-required-credits">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.ATTAINED_AND_REQUIRED_CREDITS')" />
        <fudis-dd>
          <sis-education-phase-progress [educationPhaseProgress]="studentWorkflow.phase1Progress" />
          <sis-education-phase-progress [educationPhaseProgress]="studentWorkflow.phase2Progress" />
        </fudis-dd>
      </fudis-dl-item>
      <fudis-dl-item data-cy="srew-credits-per-term" *ngIf="creditsPerTerm$ | async as creditsPerTerm">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.AVERAGE_CREDITS_PER_STUDY_TERM')" />
        <fudis-dd>{{ creditsPerTerm | decimalNumber }} {{ t('CREDITS') }}</fudis-dd>
      </fudis-dl-item>
    </fudis-dl>

    <div data-cy="srew-attained-credits-by-study-term">
      <fudis-heading [level]="3">{{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.SPENT_STUDY_TERMS') }}</fudis-heading>
      <sis-attained-credits-by-study-term *ngIf="attainedCreditsByStudyTerm$ | async as attainedCreditsByStudyTerm"
                                          [attainedCredits]="attainedCreditsByStudyTerm">
      </sis-attained-credits-by-study-term>
    </div>

    <div data-cy="srew-planned-studies">
      <fudis-heading [level]="3">{{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.PLANNED_STUDIES') }}</fudis-heading>
      <fudis-grid [columns]="1">
        <fudis-body-text [variant]="'lg-light'">{{ t('PROFILE.APPLICATIONS.EXTENSION_APPLICATION.PLANNED_STUDIES_DESCRIPTION') }}</fudis-body-text>
        <sis-planned-studies-by-study-term *ngIf="plannedStudies$ | async as plannedStudies"
                                           [applicationCreationTime]="studentWorkflow.creationTime"
                                           [plannedStudies]="plannedStudies" />
      </fudis-grid>
    </div>

    <ng-container>
      <fudis-heading [level]="3">{{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.APPLIED_EXTENSION') }}</fudis-heading>
      <fudis-dl [columns]="{ sm: 1, md: 2 }">
        <fudis-dl-item data-cy="srew-applied-extension-period">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.APPLIED_EXTENSION_PERIOD')" />
          <fudis-dd>{{ studentWorkflow.requestedTerms }} {{ t('PROFILE.EXTENSIONS.' + (studentWorkflow.requestedTerms === 1 ? 'SEMESTER' : 'SEMESTERS')) }}</fudis-dd>
        </fudis-dl-item>
        <fudis-dl-item data-cy="srew-delay-rationale">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.DELAY_RATIONALE')" />
          <fudis-dd [contentText]="studentWorkflow.delayRationale" />
        </fudis-dl-item>
        <fudis-dl-item data-cy="srew-application-rationale">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.APPLICATION_RATIONALE')" />
          <fudis-dd [contentText]="studentWorkflow.applicationRationale" />
        </fudis-dl-item>
      </fudis-dl>
    </ng-container>

    <fudis-body-text [variant]="'lg-light'">
      <transloco
        [key]="'PROFILE.APPLICATIONS.' + (studentWorkflow.mustPrintDecision ? 'DECISION_REQUESTED_BY_MAIL' : 'DECISION_NOT_REQUESTED_BY_MAIL')"/>
    </fudis-body-text>

  </fudis-grid>
</div>
