/**
 * @format
 */

import { inject, Injectable } from '@angular/core';
import { PlanValidationResult, ValidatablePlan } from 'common-typescript';
import {
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomStudyDraft,
    Education,
    EntityWithRule,
    OtmId,
    StudyRight,
} from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';
import { CustomAttainmentCreditInfoModalComponent } from 'sis-components/attainment/custom-attainment-credit-info-modal/custom-attainment-credit-info-modal.component';

import {
    StudentCustomStudyDraftModalComponent,
    StudentCustomStudyDraftOptions,
} from '../../plan/custom-study-draft-modal/custom-study-draft-modal.component';
import { StudentCourseUnitInfoModalComponent } from '../components/course-unit-info-modal/student-course-unit-info-modal/student-course-unit-info-modal.component';
import { StudentModuleInfoModalComponent } from '../components/module-info/student-module-info-modal/student-module-info-modal.component';
import { createCustomStudyDraftModalOpener } from '../components/plan-common/create-custom-study-draft-modal/create-custom-study-draft-modal.component';
import { planStructureEditModalOpener } from '../components/plan-common/plan-structure-edit-modal/plan-structure-edit-modal.component';

@Injectable({
    providedIn: 'root',
})
export class StudentPlanUiActionsServiceImplementation {
    modalService = inject(ModalService);

    private planStructureEditModalOpener = planStructureEditModalOpener();
    private createCustomStudyDraftModalOpener = createCustomStudyDraftModalOpener();

    openModuleHandler(
        module: EntityWithRule,
        validatablePlan: ValidatablePlan,
        matchingStudyRight: StudyRight,
        education: Education,
        planValidationResult: PlanValidationResult,
    ) {
        return this.planStructureEditModalOpener({
            module,
            validatablePlan,
            education,
            planValidationResult,
            validatablePlanStudyRight: matchingStudyRight,
        });
    }

    openCustomStudyDraftInfoModalHandler(customStudyDraft: CustomStudyDraft, studentId: OtmId, options: StudentCustomStudyDraftOptions) {
        const customStudyDraftModalValues = { studentId, customStudyDraft, options };
        return this.modalService.open(StudentCustomStudyDraftModalComponent, customStudyDraftModalValues, { size: 'sm' });
    }

    openCustomAttainmentInfoModalHandler(customCourseUnitAttainment: CustomCourseUnitAttainment) {
        return this.modalService.open(CustomAttainmentCreditInfoModalComponent, customCourseUnitAttainment, {
            closeWithOutsideClick: true,
            size: 'lg',
        });
    }

    openCourseUnitInfoModalHandler(courseUnit: CourseUnit, validatablePlan: ValidatablePlan) {
        return this.modalService.open(
            StudentCourseUnitInfoModalComponent,
            {
                courseUnit,
                validatablePlan,
            },
            { closeWithOutsideClick: true, size: 'xl', windowClass: 'brochure-modal' },
        );
    }

    openModuleInfoModalHandler(
        module: EntityWithRule,
        validatablePlan: ValidatablePlan,
        matchingStudyRight: StudyRight,
        planValidationResult: PlanValidationResult,
    ) {
        const isModuleInPlan = validatablePlan?.isModuleInPlan(module.id) || false;
        return this.modalService.open(
            StudentModuleInfoModalComponent,
            {
                module,
                validatablePlan: isModuleInPlan ? validatablePlan : null,
                studyRight: matchingStudyRight,
                planValidationResult: isModuleInPlan ? planValidationResult : null,
            },
            { closeWithOutsideClick: true, size: 'xl', windowClass: 'brochure-modal' },
        );
    }

    openCreateCustomStudyDraftModalHandler(selectedModuleId: OtmId) {
        return this.createCustomStudyDraftModalOpener({ selectedModuleId });
    }
}
