/**
 * @format
 */
import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PlanValidationResult, ValidatablePlan } from 'common-typescript';
import { CourseUnit, EntityWithRule } from 'common-typescript/types';
import _ from 'lodash-es';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from 'sis-components/plan/plan-actions-service/plan-actions.service';
import { PlanData, PlanStateObject } from 'sis-components/service/plan-state.service';

@Component({
    selector: 'app-plan-structure-edit-invalid-selections',
    templateUrl: './plan-structure-edit-invalid-selections.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class PlanStructureEditInvalidSelectionsComponent implements OnInit {
    planActionsService: PlanActionsService = inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN);

    hasInvalidSelections = false;

    @Input() parentModule: EntityWithRule;
    @Input() planData: PlanData;
    @Input() planStateObject: PlanStateObject;
    @Input() planValidationResult: PlanValidationResult;
    @Input() validatablePlan: ValidatablePlan;
    @Input() headingLevel: number;

    allCourseUnits: CourseUnit[];

    ngOnInit() {
        this.allCourseUnits = _.filter(
            this.validatablePlan.getSelectedCourseUnitsUnderModule(this.parentModule),
            (courseUnit) => _.get(this.planStateObject.courseUnits, [courseUnit.id, 'invalidSelection']) === true,
        );
        if (!_.isEmpty(this.allCourseUnits)) {
            this.hasInvalidSelections = true;
        }
    }

    handleCourseUnitToggle(courseUnit: CourseUnit) {
        if (this.validatablePlan.isCourseUnitInPlan(courseUnit)) {
            this.planActionsService?.removeCourseUnit(courseUnit, this.parentModule);
        } else {
            this.planActionsService?.forceSelectCourseUnitById(courseUnit.id, this.parentModule);
        }
    }
}
