/** @format */
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { numberUtils } from 'common-typescript';
import { ExternalAttainedStudyAttachments } from 'common-typescript/types';
import { FileItem } from 'sis-components/file-upload/file-upload.component';

/**
 * A simple presentational component for listing attachments added to an application. Similar to ApplicationFilesComponent
 * in sis-components, with the difference that this component is meant to be used while creating the application, before
 * anything has been saved to the database, while the latter component only works for files that exist in the database.
 */
@Component({
    selector: 'app-attachments-preview',
    templateUrl: './attachments-preview.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttachmentsPreviewComponent {
    @Input() attachments: FileItem[];
    @Input() externalAttainedStudyAttachments: ExternalAttainedStudyAttachments[] = [];

    currentExternalAttachments: ExternalAttainedStudyAttachments[] = [];

    readableFileSizeString = numberUtils.readableFileSizeString;
}
