/** @format */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { PlanStructureModule } from 'sis-components/plan-structure/plan-structure.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';
import { CreateCustomStudyDraftModalComponent } from './create-custom-study-draft-modal/create-custom-study-draft-modal.component';
import { PlanStructureEditInvalidSelectionsComponent } from './plan-structure-edit-modal/plan-structure-edit-invalid-selections/plan-structure-edit-invalid-selections.component';
import { PlanStructureEditModalComponent } from './plan-structure-edit-modal/plan-structure-edit-modal.component';
import { FreeEditCourseUnitExpandableComponent } from './plan-structure-edit-modal/plan-structure-free-edit/free-edit-course-unit-expandable/free-edit-course-unit-expandable.component';
import { FreeEditCustomStudyDraftExpandableComponent } from './plan-structure-edit-modal/plan-structure-free-edit/free-edit-custom-study-draft-expandable/free-edit-custom-study-draft-expandable.component';
import { FreeEditModuleExpandableComponent } from './plan-structure-edit-modal/plan-structure-free-edit/free-edit-module-expandable/free-edit-module-expandable.component';
import { PlanStructureFreeEditComponent } from './plan-structure-edit-modal/plan-structure-free-edit/plan-structure-free-edit.component';

@NgModule({
    declarations: [
        PlanStructureEditModalComponent,
        PlanStructureFreeEditComponent,
        FreeEditCourseUnitExpandableComponent,
        FreeEditCustomStudyDraftExpandableComponent,
        FreeEditModuleExpandableComponent,
        CreateCustomStudyDraftModalComponent,
        PlanStructureEditInvalidSelectionsComponent,
    ],
    imports: [SisCommonModule, SisComponentsModule, PlanStructureModule, NgxFudisModule, CommonModule],
})
export class PlanCommonModule {}
